import React, {useEffect, useState} from 'react'
import {Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {MyTextField} from "../../components/MyTextField";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {MySelect} from "../../components/MySelect";
import {MyUpload} from "../../components/MyUpload"
import {MySwitch} from "../../components/MySwitch";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MyTableAnexosWF15} from "../../components/MyTAbleAnexosWF15";
import {f} from "../../commons";
import {MyTableDepositoMuestrasWF15} from "../../components/MyTableDepositoMuestrasWF15";
import {MyTableNuevasEspeciesWF15} from "../../components/MyTableNuevasEspeciesWF15";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import API from "../App/API";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MySubtitle} from "../../components/MySubtitle";
import {MyTableCheck} from "../../components/MyTableCheck";
import {MyTypography} from "../../components/MyTypography";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

const P15InformeCumplimiento = ({id}) => {
  const dispatch = useDispatch()
  const section = 'InformeSeguimiento'
  const esSubsanacion = [
                         '55000022_Activity_ElaborarReporteMaate_WF15',
                         '55000022_Activity_ElaborarReporteSenadi_WF15',
                         '55000022_Activity_ElaborarReporteSenescyt_WF15'].includes(id)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud, payload:JSON.parse(instanciaProceso.solicitud.payload)}
  const payload = solicitud.payload
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:            today,
    tipo:             '', // parcial, final
    perioricidad:     'Anual',
    reporteNumero:    1,
    presupuestoReal:  0,
    anexo:            '',
    declaracion:      false,
    enlaceIPT:        '',
    resumen:          '',
    abstract:         '',
    resultadosCLPI:   '',
    depositoMuestras: [],
    nuevasEspecies:   [],
    resultados:       [],
    conclusiones:     '',
    recomendaciones:  '',
    infoAdicional:    '',
    anexos:           [],
    docx:             '',
    pdf:              '',
    obsMaate:         (id === '55000022_Activity_ElaborarReporteMaate_WF15')?payload?.InformeTecnicoMaate?.observaciones:[],
    obsSenadi:        (id === '55000022_Activity_ElaborarReporteSenadi_WF15')?payload?.InformeTecnicoSenadi?.observaciones:[],
    obsSenescyt:      (id === '55000022_Activity_ElaborarReporteSenescyt_WF15')?payload?.Monitoreo?.observaciones:[],
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChange = (event, setFormValues, formValues) => {
    if(canEdit) {
      const id = event.target.id
      const value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value
      setFormValues({...formValues, [id]: value})
    }
  }
  const subfolder = 'informe-cumplimiento-investigador'
  const filename = `informe-tecnico-${solicitud.numeroSolicitud}-investigador`
  const filenameDOCX = `${filename}.docx`
  const aConocimientoTradicional = payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.AccesoConocimiento.aConocimientoTradicional
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed' color='primary' elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Elaboración de reporte de cumplimiento' + (esSubsanacion?' (subsanación)':'')} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 2rem 0 2rem'}}>
            <Grid container>
              <Grid item xs={12} >
                <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                  <MyReadOnlyTextField id={'numeroSolicitud'}
                                       label={'Número de solicitud'}
                                       value={instanciaProceso.solicitud.numeroSolicitud} />
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'fecha'}
                                       value={formValues.fecha} />
                </Stack>
              </Grid>
              <Grid item xs={12} >
                <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                  <MySelect id={'tipo'}
                            label={'Tipo de informe *'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            data={['Parcial', 'Final', '']}
                            canEdit={canEdit} />
                  <MySelect id={'perioricidad'}
                            label={'Perioricidad'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            data={['Mensual', 'Bimensual', 'Trimestral', 'Semestral', 'Anual', '']}
                            canEdit={false} />
                  {/*<Typography>{`No. ${formValues.reporteNumero}`}</Typography>*/}
                  {formValues.tipo === 'Final' &&
                    <Box sx={{w:'20%'}}>
                      <MyTextField id={'presupuestoReal'}
                                   label={'Presupuesto Real'}
                                   isNumber={true}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   canEdit={canEdit}
                                   handleChange={(e) => handleChange(e, setFormValues, formValues)} />
                    </Box>
                  }
                </Stack>
              </Grid>
              {
                (id === '55000022_Activity_ElaborarReporteMaate_WF15') ?
                  <>
                    <Grid item xs={12} sx={{...dialog.titleContainer, p: '0'}}>
                      <MySubtitle subtitle={'Observaciones del Maate'}/>
                    </Grid>
                    <Grid item xs={12}>
                      <MyTableCheck id={'obsMaate'}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    habilitarRespuesta={true}
                                    columnName={'Observaciones'}
                                    canEdit={false}/>
                    </Grid>
                  </> : false
              }
              {(id === '55000022_Activity_ElaborarReporteSenadi_WF15') ?
                <>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p: '0'}}>
                    <MySubtitle subtitle={'Observaciones del Senadi'}/>
                  </Grid>
                  <Grid item xs={12}>
                    <MyTableCheck id={'obsSenadi'}
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  habilitarRespuesta={true}
                                  columnName={'Observaciones'}
                                  canEdit={false}/>
                  </Grid>
                </> : false
              }
              {
                (id === '55000022_Activity_ElaborarReporteSenescyt_WF15') ?
                  <>
                    <Grid item xs={12} sx={{...dialog.titleContainer, p: '0'}}>
                      <MySubtitle subtitle={'Observaciones del Senescyt'}/>
                    </Grid>
                    <Grid item xs={12}>
                      <MyTableCheck id={'obsSenescyt'}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    habilitarRespuesta={true}
                                    columnName={'Observaciones'}
                                    canEdit={false}/>
                    </Grid>
                  </> : false
              }

              <Grid item xs={12} >
                <MyTextField id={'enlaceIPT'}
                             label={'Enlace IPT *'}
                             formValues={formValues}
                             setFormValues={setFormValues}
                             canEdit={canEdit}
                             handleChange={(e) => handleChange(e, setFormValues, formValues)} />
              </Grid>
              {formValues.tipo === 'Final' ?
                <Grid item xs={12}>
                  <MyAreaTextField id={'resumen'}
                                   label={'Resumen *'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   canEdit={canEdit}
                                   handleChange={(e) => handleChange(e, setFormValues, formValues)}/>
                </Grid> : null
              }
              {formValues.tipo === 'Final' ?
                <Grid item xs={12}>
                  <MyAreaTextField id={'abstract'}
                                   label={'Abstract *'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   canEdit={canEdit}
                                   handleChange={(e) => handleChange(e, setFormValues, formValues)}/>
                </Grid> : null
              }
              {(aConocimientoTradicional === true) ?
                <Grid item xs={12}>
                  <MyAreaTextField id={'resultadosCLPI'}
                                   label={'En caso de contar con CLPI y contrato de acceso al CCTT indicar los resultados alcanzados en el presente periodo'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   canEdit={canEdit}
                                   handleChange={(e) => handleChange(e, setFormValues, formValues)}/>
                </Grid> : false
              }
              <Grid item xs={12} >
                <MyTableDepositoMuestrasWF15 id={'depositoMuestras'}
                                             canEdit={canEdit}
                                             formValues={formValues}
                                             setFormValues={setFormValues}
                                             addItem={() => {
                                               const id = f.newId(formValues.depositoMuestras)
                                               setFormValues(
                                                 {
                                                   ...formValues,
                                                   depositoMuestras: [
                                                     ...formValues.depositoMuestras,
                                                     {
                                                       id:            id,
                                                       inombreMedio:  '',
                                                       acronimo:      '',
                                                       nombreCurador: '',
                                                       telefono:      '',
                                                       eMail:         '',
                                                       repositorio:   '',
                                                     }
                                                   ]
                                                 }
                                               )
                                             }}
                                             canDeleteRow={canEdit} />
              </Grid>
              <Grid item xs={12} >
                <MyTableNuevasEspeciesWF15 id={'nuevasEspecies'}
                                           canEdit={canEdit}
                                           formValues={formValues}
                                           setFormValues={setFormValues}
                                           addItem={() => {
                                             const id = f.newId(formValues.nuevasEspecies)
                                             setFormValues(
                                               {
                                                 ...formValues,
                                                 nuevasEspecies: [
                                                   ...formValues.nuevasEspecies,
                                                   {
                                                     id:              id,
                                                     grupoTaxonomico: '',
                                                     nombre:          '',
                                                     articulo:        '',
                                                     doiUri:          '',
                                                     revista:         '',
                                                     impacto:         '',
                                                   }
                                                 ]
                                               }
                                             )
                                           }}
                                           canDeleteRow={canEdit} />
              </Grid>
              <Grid item xs={12} >
                <MyTableObservaciones id={'resultados'}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      columnName={'Resultados *'}
                                      canEdit={canEdit}
                                      addItem={() => {
                                        if(formValues['resultados'].filter(it => it === '').length === 0) {
                                          const field = 'resultados'
                                          const newSet = [ ...formValues[field], '']
                                          const newFormValues = {...formValues, [field]:newSet}
                                          setFormValues(newFormValues)
                                        }
                                      }} />
                {
                  formValues.resultados.length === 0 ?
                    <MyTypography>- La lista de resultados no puede quedar vacía .</MyTypography>:null
                }
                {
                  formValues.resultados.filter(it => it === '').length > 0 ?
                    <MyTypography>- No puede haber items vacíos en la lista de resultados.</MyTypography>:null
                }
              </Grid>
              <Grid item xs={12} >
                <MyAreaTextField id={'conclusiones'}
                                 label={'Conclusiones *'}
                                 formValues={formValues}
                                 setFormValues={setFormValues}
                                 canEdit={canEdit}
                                 handleChange={(e) => handleChange(e, setFormValues, formValues)} />
              </Grid>
              <Grid item xs={12} >
                <MyAreaTextField id={'recomendaciones'}
                                 label={'Recomendaciones *'}
                                 formValues={formValues}
                                 setFormValues={setFormValues}
                                 canEdit={canEdit}
                                 handleChange={(e) => handleChange(e, setFormValues, formValues)} />
              </Grid>
              <Grid item xs={12} >
                <MyAreaTextField id={'infoAdicional'}
                                 label={'Información adicional'}
                                 formValues={formValues}
                                 setFormValues={setFormValues}
                                 canEdit={canEdit}
                                 handleChange={(e) => handleChange(e, setFormValues, formValues)} />
              </Grid>
              <Grid item xs={12} >
                <MyTableAnexosWF15 id={'anexos'}
                                   canEdit={canEdit}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   addItem={() => {
                                     const id = f.newId(formValues.anexos)
                                     setFormValues(
                                       {
                                         ...formValues,
                                         anexos: [
                                           ...formValues.anexos,
                                           { id:             id,
                                             anxMaterial:    '',
                                             anxTitulo:      '',
                                             anxAutores:     '',
                                             anxPublicacion: new Date() ,
                                             anxUri:         '',
                                             anxNumero:      '', }
                                         ]
                                       }
                                     )
                                   }}
                                   canDeleteRow={canEdit} />
              </Grid>
              <Grid item xs={12}>
                <Stack  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                  <MyUpload id={'anexo'}
                            label={'Anexo *'}
                            dir={instanciaProceso?.solicitud?.id}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            canEdit={canEdit} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe Técnico'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        const doc = MyTagged2Docx(plantilla(payload), 'investigador', P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,
                                          autorizador: null,
                                          coordinador: null,
                                          perfilUsuario,
                                          nombreOrganizacion: 'investigador',
                                          tipo: instanciaProceso.tipoInstanciaProceso?.toLowerCase(),
                                          section: payload[section]
                                        }))
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion:'investigador',
                                        })
                                      }}
                                      toolTip={'Generar'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.docx,
                                      })}
                                      toolTip={'Descargar documento en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.pdf
                                      })}
                                      toolTip={'Descargar documento en formato pdf'}
                                      width={'5rem'} />
                    </>
                  }
                  { formValues.docx === "" ? <MyTypography>- Es obligatorio generar el informe.</MyTypography> : null }
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración de veracidad de la información'} />
              </Grid>
              <Grid item xs={12} >
                <Typography sx={{m:'1rem 0 1rem 0'}}>{`Yo, ${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos} portador del documento de identidad 1203107485, en calidad de solicitante, declaro bajo juramento que la información constante en el presente informe es verdadera y de mi absoluta responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o introducir cualquier información falsa en el presente documento, asumo toda la responsabilidad administrativa, civil o penal conforme lo establecido por ley.`}</Typography>
                <Typography sx={{m:'1rem 0 1rem 0'}}>Atención: Por favor revise la información del informe, si está seguro que los datos son correctos acepte y declare la veracidad de toda la información detallada en el documento y envíe la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                  <MySwitch id='declaracion'
                            label={'Aceptar y enviar'}
                            formValues={formValues}
                            fullWidth={false}
                            canEdit={canEdit}
                            handleChange={(e) => handleChange(e, setFormValues, formValues)} />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload.Solicitud.solicitudAprobada.payloadSolicitud} />
        </TabPanel>
      </TabContext>
      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 payload.solicitudId = instanciaProceso.solicitud.id
                                 payload.numeroSolicitud = instanciaProceso.solicitud.numeroSolicitud
                                 payload.nombreProyecto = instanciaProceso.solicitud.nombreProyecto
                                 payload.TYPE = 'WF15'
                                 if(id === '55000022_Activity_ElaborarReporteMaate_WF15') {
                                   payload.InformeTecnicoMaate.observaciones = formValues.obsMaate
                                 }
                                 if(id === '55000022_Activity_ElaborarReporteSenadi_WF15') {
                                   payload.InformeTecnicoSenadi.observaciones = formValues.obsSenadi
                                 }
                                 if(id === '55000022_Activity_ElaborarReporteSenescyt_WF15') {
                                   payload.Monitoreo.observaciones = formValues.obsSenescyt
                                 }
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                {
                  formValues.tipo === 'Parcial' ?
                    <MySendButton onSend={ () => {
                                    const metadata = JSON.stringify({
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    })
                                    dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }}
                                  label={'Enviar'}
                                  disabled={
                                    !canEdit ||
                                    counter > 0 ||
                                    formValues.declaracion === false ||
                                    formValues.docx === '' ||
                                    formValues.enlaceIPT === '' ||
                                    formValues.resultados.length === 0 ||
                                    formValues.resultados.filter(it => it === '').length > 0 ||
                                    formValues.conclusiones.trim() === '' ||
                                    formValues.recomendaciones.trim() === ''
                                  } />: null
                }
                {
                  formValues.tipo === 'Final' ?
                    <MySendButton onSend={ () => {
                                    const metadata = JSON.stringify({
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    })
                                    dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }}
                                  label={'Enviar'}
                                  disabled={
                                    !canEdit ||
                                    counter > 0 ||
                                    formValues.declaracion === false ||
                                    formValues.docx === '' ||
                                    formValues.resumen === '' ||
                                    formValues.abstract === '' ||
                                    formValues.enlaceIPT === '' ||
                                    formValues.resultados.length === 0 ||
                                    formValues.resultados.filter(it => it === '').length > 0 ||
                                    formValues.conclusiones.trim() === '' ||
                                    formValues.recomendaciones.trim() === ''
                                  } />: null
                }
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-useless-concat */
const plantilla = (payload) => {
  const aConocimientoTradicional = payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.AccesoConocimiento.aConocimientoTradicional
  return ""
    + '${TITULO1}INFORME DE EVALUACIÓN, SEGUIMIENTO Y MONITOREO'
    + '${SALTO}'
    + '${TITULO1}DATOS DEL INFORME'
    + '${SALTO}${VAR}Tipo de informe${:}' + `${payload.InformeSeguimiento.tipo}`
    + '${SALTO}${VAR}Periodicidad de informe${:}' + `${payload.InformeSeguimiento.perioricidad}`
    + '${SALTO}${VAR}Fecha de entrega del informe${:}' + `${payload.InformeSeguimiento.fecha}`
    + '${SALTO}'
    + '${TITULO1}DATOS DEL SOLICITANTE'
    + '${SALTO}'
    + '${SALTO}${VAR}Cédula/Pasaporte del solicitante${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.cedula
    + '${SALTO}${VAR}Nombre del solicitante${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos
    + '${SALTO}${VAR}Correo electrónico${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.email
    + '${SALTO}${VAR}Teléfono${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.telefono
    + '${SALTO}${TITULO1}DATOS DEL PROYECTO'
    + '${SALTO}${VAR}Nro. Permiso${:}' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Resolucion?.identificador}`
    + '${SALTO}${VAR}Plazo${:}' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.plazo} meses`
    + '${SALTO}${VAR}Título del proyecto${:}' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}`
    + ((payload.InformeSeguimiento.tipo === 'Final')?('${SALTO}${VAR}Presupuesto real del proyecto${:}' + payload?.InformeSeguimiento?.persupuestoFinal):'')
    + '${SALTO}${VAR}Objetivo general${:}' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.objetivo}`
    + '${SALTO}${VAR}Objetivos específicos${:}' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.objetivos?.join('. ')}`
    + '${SALTO}${VAR}Enlace de IPT${:}' + `${payload?.InformeSeguimiento?.enlaceIPT}`
    + ((payload.InformeSeguimiento.tipo === 'Final')?('${SALTO}${VAR}Resumen${:}'  + `${payload?.InformeSeguimiento?.resumen}`):'')
    + ((payload.InformeSeguimiento.tipo === 'Final')?('${SALTO}${VAR}Abstract ${:}'  + `${payload?.InformeSeguimiento?.abstract}`):'')
    + ((aConocimientoTradicional === true)?('${SALTO}${VAR}Resultados CLPI${:}'  + `${payload?.InformeSeguimiento?.resultadosCLPI}`):'')
    + '${SALTO}${TITULO2}Depósito de muestras'
    + '${SALTO}${WF15.DEPOSITO.MUESTRAS}'+ JSON.stringify(payload?.InformeSeguimiento?.depositoMuestras)
    + '${SALTO}${TITULO2}Nuevas especies'
    + '${SALTO}${WF15.NUEVAS.ESPECIES}'+ JSON.stringify(payload?.InformeSeguimiento?.nuevasEspecies)
    + '${SALTO}${TITULO1}ESTADO DEL DESARROLLO DE LA INVESTIGACIÓN CIENTÍFICA'
    + '${SALTO}${TITULO2}Resultados del proyecto hasta el presente periodo'
    + '${SALTO}${WF15.RESULTADOS.ESPERADOS}' + JSON.stringify(payload?.InformeSeguimiento?.resultados)
    + '${SALTO}${TITULO2}Conclusiones'
    + '${SALTO}${PARRAFO}' + `${payload?.InformeSeguimiento?.conclusiones}`
    + '${SALTO}${TITULO2}Recomendaciones'
    + '${SALTO}${PARRAFO}' + `${payload?.InformeSeguimiento?.recomendaciones}`
    + '${SALTO}${TITULO2}Información adicional'
    + '${SALTO}${PARRAFO}' + `${payload?.InformeSeguimiento?.infoAdicional}`
    + '${SALTO}${TITULO2}Anexos'
    + '${SALTO}${WF15.ANEXOS}' + JSON.stringify(payload?.InformeSeguimiento?.anexos)
    + '${SALTO}'
}
/* eslint-enable no-useless-concat */
/* eslint-enable no-template-curly-in-string */

export default P15InformeCumplimiento
