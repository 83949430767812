import {
  BorderStyle, convertInchesToTwip,
  Paragraph, ShadingType,
  TableCell, VerticalAlign,
  WidthType
} from "docx"
import {dxTextRun} from "./MyDocx"

export const MyTableCell = ({text,size, bold=false, alignment, colSpan=1, rowSpan=1, noBorder=false,shading={fill: "ffffff", type: ShadingType.SOLID, color: "ffffff",},}) => {
  const border = {style: (noBorder?BorderStyle.NONE:BorderStyle.SINGLE), size: (noBorder?0:2), color: "#444444"}
  const cellMargins = {
    top:    convertInchesToTwip(0.05),
    bottom: convertInchesToTwip(0.05),
    left:   convertInchesToTwip(0.05),
    right:  convertInchesToTwip(0.05),
  }
  return (
    new TableCell({
      borders: {top: border, bottom: border, left: border, right: border},
      width: {size, type: WidthType.PERCENTAGE},
      verticalAlign: VerticalAlign.CENTER,
      margins: cellMargins,
      columnSpan:colSpan,
      rowSpan,
      shading,
      // verticalAlign:
      children: [
        new Paragraph({
          children: [dxTextRun({text, bold}),],
          alignment,
        })
      ],
    })
  )
}

export const MyTableCell2 = ({text1, text2, size, bold=false, alignment, colSpan=1, rowSpan=1, noBorder=false,shading={fill: "ffffff", type: ShadingType.SOLID, color: "ffffff",},}) => {
  const border = {style: (noBorder?BorderStyle.NONE:BorderStyle.SINGLE), size: (noBorder?0:2), color: "#444444"}
  const cellMargins = {
    top:    convertInchesToTwip(0.05),
    bottom: convertInchesToTwip(0.05),
    left:   convertInchesToTwip(0.05),
    right:  convertInchesToTwip(0.05),
  }
  return (
    new TableCell({
      borders: {top: border, bottom: border, left: border, right: border},
      width: {size, type: WidthType.PERCENTAGE},
      verticalAlign: VerticalAlign.CENTER,
      margins: cellMargins,
      columnSpan:colSpan,
      rowSpan,
      shading,
      // verticalAlign:
      children: [
        new Paragraph({
          children: [dxTextRun({text:text1, bold}),],
          alignment,
        }),
        new Paragraph({
          children: [dxTextRun({text:text2?.toUpperCase(), bold}),],
          alignment,
        }),
      ],
    })
  )
}
