import React from 'react'
import {InputLabel,} from '@mui/material'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Box from "@mui/material/Box";
import RobotoCondensedRegular from "../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import {styled} from "@mui/material/styles";

export const MyReadOnlyAreaTextField = ({id, label, value, icon, rows=4}) => {
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Textarea = styled(BaseTextareaAutosize)({
    width: '100%',
    // minHeight: '12vw',
    // maxHeight: '24vw',
    boxSizing: 'border-box',
    fontFamily: RobotoCondensedRegular,
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: 1.5,
    borderRadius: '2px 2px 0',
    background: 'transparent',
    border: '1px dotted #ccc',
    boxShadow: `0 0px 0px ${grey[50]}`,
    color: '#878787',
    '&:hover': {
      borderColor: `${blue[400]}`,
    },
    '&:focus': {
      outline: 0,
      borderColor: `${blue[400]}`,
      boxShadow: `0 0 0 1px ${blue[200]}`,
    },
    /* firefox */
    '&:focus-visible': {
      outline: 0,
    }
  });

  return (
    <Box sx={{m:'1rem 0 1rem 0'}}>
      <InputLabel sx={{fontSize: '0.9rem'}} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Textarea id={id} value={value} minRows={4} maxRows={(rows>4)?rows:5}/>
    </Box>
  )
}
