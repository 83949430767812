import React, {useEffect, useState} from 'react'
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  Stack, Tab
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion";
import {MyTextField} from "../../components/MyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyTypography} from "../../components/MyTypography";

const P08AprobarImportacion = ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                  // atms=[],
                }) => {
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  const mp = slct.payload.Solicitud.solicitudAprobada
  const dispatch = useDispatch()
  const section = 'Validar'
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:            today,
    cumpleRequisitos: false,
    negar:       false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  useEffect(() => {
    if(formValues.cumpleRequisitos) {
      setFormValues({
        ...formValues,
        asunto:  'Certificado de importación AUTORIZADO',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la verificación de ` +
          `requisitos de importación con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} HA SIDO AUTORIZADA.\n\n`
      })
    } else {
      setFormValues({
        ...formValues,
        asunto:  'Certificado de importación NO AUTORIZADO',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la verificación de ` +
          `requisitos de importación con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} NO HA SIDO AUTORIZADA.\n\n`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.cumpleRequisitos])
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  // 55000008_Activity_AprobarImportacion
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin:0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Negar importación'} />
        </AppBar>
        <TabContext value={myTab} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={slct.payload.Solicitud['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false} />
              <Autorizacion solicitud={{numeroSolicitud: mp?.father?.aprobada?.numeroSolicitudPadre, payload: mp}} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Ubicación'}/>
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionPais'}
                                     label={'País'}
                                     value={slct.payload.Solicitud.origenPais} />
              </Grid>
              <Grid item xs={3}>
                <MyReadOnlyTextField id={'ubicacionInstitucion'}
                                     label={'Institución'}
                                     value={slct.payload.Solicitud.origenInstitucion} />
              </Grid>
              <Grid item xs={3}>
                <MyReadOnlyTextField id={'ubicacionOrigen'}
                                     label={'Autorización'}
                                     value={slct.payload.Solicitud.origenAutorizacion} />
              </Grid>

              <Grid item xs={12}>
                <MyUpload id={'permisoImportacion'}
                          label={'Permiso de importación:'}
                          dir={instanciaProceso?.solicitud?.id}
                          canEdit={false}
                          formValues={slct.payload.Solicitud} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={false}
                                  formValues={slct.payload.Solicitud}
                                  mode={'ATM-ES2'} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Requisitos'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito01'}
                          label={'¿La importación ha sido debidamente autorizada por la entidad competente, conforme la normativa ecuatoriana vigente.?'}
                          formValues={payload.Validar}
                          setFormValues={setFormValues}
                          handleChange={handleChange}
                          canEdit={false}/>
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito02'}
                          label={'¿La importación se enmarca en un permiso de investigación/contrato autorizado previamente por la Autoridad competente.?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito03'}
                          label={'¿La importación se encuentra respaldada en un Acuerdo de Transferencia de Material previamente validado por la SENESCYT?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito04'}
                          label={'¿La importación prevé el reporte de los resultados alcanzados.?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 2rem'}}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >
                  <MySwitch id={'cumpleRequisitos'}
                            label={'¿La solicitud cumple con los Requisitos?'}
                            fullWidth={false}
                            formValues={payload.Validar}
                            handleChange={handleChange}
                            canEdit={false} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto'}
                             formValues={payload.Validar}
                             handleChange={handleChange}
                             canEdit={false} />
              </Grid>
              {
                formValues.asunto === ''?
                  <MyTypography sx={common.warnig}>Asunto es obligatorio</MyTypography>
                  :null
              }
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido'}
                                 formValues={payload.Validar}
                                 handleChange={handleChange}
                                 canEdit={false} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Certificado'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 2rem'}}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >
                  <MySwitch id={'negar'}
                            label={'¿Negar la autorización?'}
                            fullWidth={false}
                            formValues={formValues}
                            handleChange={handleChange}
                            canEdit={canEdit} />
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={ATM} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'}
                                       onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={ counter <= 0 }
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={ counter > 0 || formValues.asunto === '' || formValues.detalle === '' }
                                      label={'enviar'}
                                      onSend={() => {
                                        const metadata = (!formValues.cumpleRequisitos)?JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "importacionAprobada": false,
                                          "asunto": formValues.asunto,
                                          "perfilUsuarioSolicitud": instanciaProceso?.perfilUsuario?.id,
                                          "detalle": formValues.detalle,
                                        }):JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "importacionAprobada": true,
                                          "asunto": formValues.asunto,
                                          "detalle": formValues.detalle,
                                        })
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

export default P08AprobarImportacion
