import React, {Fragment, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {accordeonBox} from 'src/styles/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material"
import Paper from '@mui/material/Paper'
import {dialog} from 'src/styles/styles'
import {
  fetchInstanciaTareaInstanciaProceso,
  handleRefreshTareas,
  handleSetBandeja, setPerfilUsuario
} from 'src/features/App/sliceApp'
import {useNavigate} from 'react-router-dom'
import API from 'src/features/App/API'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {f} from 'src/commons/f'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from  '@mui/material/ListItemIcon'
import {MyTableBandeja} from "../../../components/MyTableBandeja";

export const Bandejas = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const usuario = useSelector(state => state.app.usuario)
  const perfilesUsuario = useSelector(state => state.app.perfilesUsuario)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const instanciasTarea = useSelector(state => state.app.instanciasTarea)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const investigador = useSelector(state => state.app.investigador)
  React.useEffect(() => {
    if(f.isValid(instanciaTarea?.id)) {
      const route = API.getRoute(instanciaTarea)
      if(!!route) navigate(route)
    }
  },[instanciaTarea])
  React.useEffect(() => {
    setTimeout(() => dispatch(handleRefreshTareas(perfilUsuario.id)), 500)
  }, [perfilUsuario])
  useEffect(() => {window.scrollTo(0, 0)}, [])
  const [anchorEl2, setAnchorEl2] = React.useState(null)
  const handleClick = (e) => setAnchorEl2(e.currentTarget)
  const handleClose = () => {setAnchorEl2(null)}
  const open = Boolean(anchorEl2)
  const mid = open ? 'simple-popover' : undefined
  let rows = [...instanciasTarea]?.filter((t)=> t.estadoInstanciaTarea === 'Iniciada').
    sort( (t1, t2) => new Date(t1.fechaHoraInicia) < new Date(t2.fechaHoraInicia)?-1:1).
    map(tarea => {
      const metadato = f.isValid(tarea?.metadataOrigen)?JSON.parse(tarea?.metadataOrigen):{}
      const fecha = format(new Date(tarea.fechaHoraInicia), 'dd MMM yyyy  hh:mm', {locale: es}).toUpperCase()
      return {
        id: tarea.id,
        instanciaProcesoNombreProceso: tarea.instanciaProcesoNombreProceso,
        nombreUsuario: metadato.nombreUsuario,
        origen: metadato.nombreTarea,
        descripcionPerfil: metadato.descripcionPerfil,
        nombreTarea: tarea.nombreTarea,
        fecha: fecha,
        instanciaProcesoSolicitudNumeroSolicitud: tarea.instanciaProcesoSolicitudNumeroSolicitud,
        instanciaProcesoSolicitudNombreProyecto: tarea.instanciaProcesoSolicitudNombreProyecto,
      }
  })

  if(f.isValid(usuario?.id) && f.isValid(perfilUsuario.id) && !investigador) {
    return (
      <Fragment>
        <Box sx={{...accordeonBox.container, pt:0}}>
          <Box sx={accordeonBox.titleBox}>
            <Grid container>
              <Grid item xs={11}>
                <Typography sx={accordeonBox.titleTypography}>Bandeja de entrada | Tareas Recibidas</Typography>
              </Grid>
              <Grid item xs={1} sx={{position:'relative'}}>
                {
                  (perfilesUsuario.length > 1) &&
                    <Fragment>
                      <Tooltip title={'cambiar de rol'}>
                        <IconButton color="primary"
                                    aria-describedby={mid}
                                    component="span"
                                    sx={{padding:0, margin:0, position: 'absolute', top:'0px', right:'3rem'}}
                                    onClick={handleClick}>
                          <SwitchAccountIcon sx={{fontSize:'2rem', p:0, m:0, color:'white'}}/>
                        </IconButton>
                      </Tooltip>
                      <Popover id={mid}
                               open={open}
                               anchorEl={anchorEl2}
                               onClose={handleClose}
                               anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'left',
                               }} >
                        <Box sx={{p:0}}>
                          <Grid item xs={12} sx={{p:0, width:'16rem'}}>
                            <List dense={true}>
                            {
                              perfilesUsuario.map((it2, idx) => (
                                <ListItem key={idx}
                                          sx={{
                                            cursor:'pointer',
                                            background:(perfilUsuario.id === it2.id)?'rgb(155,209,113)':'transparent',
                                          }}
                                          onClick={() => {
                                            let selected = perfilesUsuario?.filter(it => it.perfil?.id === it2.perfil?.id)
                                            if(f.isValid(selected)) {
                                              dispatch(setPerfilUsuario(selected[0]))
                                              handleClose()
                                            }
                                          }}>
                                  <ListItemIcon>
                                  </ListItemIcon>
                                  <ListItemText primary={it2.perfil.descripcionPerfil} secondary={null} />
                                </ListItem>
                              ))
                            }
                            </List>
                          </Grid>
                        </Box>
                      </Popover>
                    </Fragment>
                }
                <Tooltip title={'refrescar'}>
                  <IconButton color="primary"
                              component="span"
                              sx={{padding:0, margin:0, position: 'absolute', top:'0px', right:'8px'}}
                              onClick={() => dispatch(handleRefreshTareas(perfilUsuario.id))}>
                    <RefreshIcon sx={{fontSize:'2rem', p:0, m:0, color:'white'}}/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={{mt:'1rem'}}>
              <MyTableBandeja rows={rows} />
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    )
  } else {
    return null
  }
}
