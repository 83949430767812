import React from 'react'
import {dialog} from 'src/styles/styles'
import {InputAdornment, TextField} from '@mui/material'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {f} from "../commons";

export const MyTextField = ({
                              id,
                              label,
                              value,
                              formValues,
                              icon,
                              handleChange,
                              error='',
                              isNumber=false,
                              placeholder='',
                              canEdit=true
                            }) => {
  const textFieldMargin = '1rem 0 1rem 0';
  const sx = {...dialog.textTypography, m: textFieldMargin};

  const type = isNumber ? 'number' : 'text';

  const compulsive = ((type === 'text') && canEdit && (label?.length > 0) && (label?.at(label.length-1) === '*'));

  const isBorderRed = (compulsive && (formValues[id] === '')) || f.isValidNotEmpty(error);
  const borderStyle = isBorderRed ? {border: '2px solid red'} : {border: '1px dotted #cccccc'};

  const isOtherError = compulsive && (formValues[id] === '') && (error === '');
  const error2 = isOtherError ? `Campo "${label}" es obligatorio.`:"";

  const isError1NotEmpty = f.isValidNotEmpty(error);
  const isOtherErrorNotEmpty = error2.length > 0;
  const error1 = isError1NotEmpty ? ` - ${error}` : (isOtherErrorNotEmpty ? ` - ${error2}` : "");

  const validValue = !!value ? value : (f.isValid(formValues[id]) ? formValues[id] : '');
  const readableChangeHandle = canEdit ? handleChange : null;
  const textAlignment = isNumber ? 'right' : 'left';

  const inputPropsStyle = {textAlign: textAlignment};
  const backgroundColor = canEdit === true ? 'white' : 'transparent';

  return (
    <TextField id={id}
               label={label}
               value={validValue}
               type={type}
               fullWidth
               variant='standard'
               aria-readonly={true}
               sx={sx}
               onChange={readableChangeHandle}
               placeholder={placeholder}
               InputProps={{
                 inputProps:{style: inputPropsStyle},
                 disableUnderline: true,
                 form: {autocomplete: 'off'},
                 startAdornment:(
                   <InputAdornment position="start">
                     {icon}
                   </InputAdornment>
                 ),
                 sx: {
                   fontSize: '0.875rem',
                   fontfamily: RobotoCondensedRegular,
                   color: '#888888',
                   backgroundColor: backgroundColor,
                   ...borderStyle,
                 }
               }}
               InputLabelProps={{ sx: {
                   fontSize: '1.2rem',
                   color: '#888888',
                   fontfamily: RobotoCondensedRegular,
                   padding: '-24px',
                   shrink: true
                 }}}
               FormHelperTextProps={{sx: {
                   fontSize: '0.7rem',
                   fontfamily: RobotoCondensedRegular,
                   lineHeight: '1.2rem',
                   color:'darkred',
                   fontWeight: 'bolder',
                   pl:'1rem'}}}
               helperText={error1} />
  )
}
