import React from 'react'
import {InputLabel, Typography,} from '@mui/material'
import {TextareaAutosize} from '@mui/base/TextareaAutosize'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import Box from "@mui/material/Box";
import {f} from "../commons";

export const MyAreaTextField = ({
                                  id,
                                  label,
                                  value,
                                  formValues,
                                  icon,
                                  handleChange,
                                  minHeight='10rem',
                                  canEdit = true,
                                  error
}) => {
  const compulsive = canEdit && (label?.length > 0) && (label?.at(label?.length-1) === '*')
  const borderStyle = ((compulsive && (formValues[id] === '')) || f.isValidNotEmpty(error))? {border: '2px solid red'} : {border: '1px dotted #cccccc'}
  const error2 = (compulsive && (formValues[id] === '')) ? `Campo "${label}" es obligatorio.`:""
  const error1 = f.isValidNotEmpty(error)?` - ${error}`:(error2.length>0)?(` - ${error2}`):""
  return (
    <Box sx={{m:'1rem 0 1rem 0'}}>
      <InputLabel sx={{fontSize: '0.9rem', fontfamily: RobotoCondensedRegular}} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <TextareaAutosize id={id}
                        style={{
                          width:'100%',
                          minHeight,
                          maxHeight:'40rem',
                          resize: "vertical",
                          overflow: 'auto',
                          fontFamily: RobotoCondensedRegular,
                          color: '#888888',
                          ...borderStyle
                        }}
                        value={!!value?value:formValues[id]}
                        floatinglabel={"true"}
                        fullwidth={"true"}
                        onChange={handleChange} />
      {
        (error1 !== "")?
          <Typography sx={{
            fontSize: '0.8rem',
            fontfamily: RobotoCondensedRegular,
            color:'darkred',
            fontWeight: 'bolder',
            pl:'1rem'}}>{error1}</Typography>
          :null
      }
    </Box>
  )
}
