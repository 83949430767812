import React from 'react'
import {Grid, TextField} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {AccountCircle, Badge, Email, PhoneInTalk, Smartphone} from "@mui/icons-material";
import {MyTextField} from "../../components/MyTextField";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {f} from "../../commons";

export const P08RO = ({payload}) => {
  let solicitud = payload.Solicitud
  const solicitudAprobada = (typeof solicitud.solicitudAprobada === "object") ? solicitud.solicitudAprobada : JSON.parse(solicitud.solicitudAprobada)
  const wf0102 = solicitudAprobada.wf0102
  return (
    <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Solicitud'} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Identificador'}
                             icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={f.isValid(payload.Solicitud?.numeroSolicitud)?payload.Solicitud.numeroSolicitud:solicitud.payload.numeroSolicitud} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Fecha'}
                             value={solicitud.ElaborarSolicitud['fecha']} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Solicitante'} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField id='nombres'
                             label={'Nombres completos'}
                             value={wf0102.Solicitante?.nombresCompletos}
                             icon={<AccountCircle sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField id='cedula'
                             label={'Cédula/Pasaporte'}
                             value={wf0102.Solicitante?.cedula}
                             icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Documento habilitante'} />
      </Grid>
      <Grid item xs={8}>
        <MyTextField id={'documentoIdentificador'}
                     label={'Número de permiso'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={4}>
          <MyTextField id={'documentoFecha'}
                       label={'Fecha'}
                       formValues={payload.Solicitud.ElaborarSolicitud}
                       canEdit={false} />
      </Grid>
      <Grid item xs={12}>
        <MyAreaTextField id='documentoObjetivo'
                         label={'Objetivo de la importación'}
                         formValues={payload.Solicitud.ElaborarSolicitud}
                         icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                         canEdit={false} />
      </Grid>
      <Grid item xs={12}>
        <MyUpload id={'permisoImportacion'}
                  label={'Permiso de importación:'}
                  dir={payload.solicitudId}
                  canEdit={false}
                  formValues={payload.Solicitud.ElaborarSolicitud} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Origen del recurso'}/>
      </Grid>
      <Grid item xs={6}>
        <MyTextField id={'origenAutorizacion'}
                     label={'Identificador de autorización de colecta/investigación'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'origenPais'}
                  width={'40rem'}
                  label={'Pais'}
                  formValues={payload.Solicitud.ElaborarSolicitud}
                  canEdit={false} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'origenInstitucion'}
                     label={'Institucion'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Responsable de la importación'} />
      </Grid>
      <Grid item xs={12} >
        <MyTextField id={'responsableNombres'}
                  label={'Nombres'}
                  formValues={payload.Solicitud.ElaborarSolicitud}
                  icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                  canEdit={false} />
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'responsableCedula'}
                     label={'Cédula'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'responsableContacto'}
                     label={'Número de celular'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'responsableCorreo'}
                     label={'Correo Electrónico'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Destino'} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'destinoInstitucion'}
                     width={'40rem'}
                     label={'Institución'}
                     formValues={payload.Solicitud.ElaborarSolicitud}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Recursos'}/>
      </Grid>
      <Grid item xs={12}>
        <MyTableRecursos2 id={'recursos'}
                          canEdit={false}
                          formValues={payload.Solicitud.ElaborarSolicitud}
                          mode={'WF08'} />
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Declaración de veracidad de la información'}/>
      </Grid>
      <Grid item xs={12}>
        <TextField id='texto'
                   multiline
                   rows={10}
                   value={payload.Solicitud.ElaborarSolicitud.texto}
                   fullWidth
                   variant='standard'
                   aria-readonly={true}
                   sx={dialog.textTypography}
                   InputProps={{
                     disableUnderline: true,
                     sx: {
                       fontSize: '12px',
                       backgroundColor: 'transparent',
                     }
                   }}
                   InputLabelProps={{
                     sx: {
                       fontSize: '14px',
                     }
                   }} />
      </Grid>
      <Grid item xs={12} sx={{pr:'2rem'}}>
        <MySwitch id={'si'}
                  label={''}
                  canEdit={false}
                  formValues={payload.Solicitud.ElaborarSolicitud} />
      </Grid>
    </Grid>
  )
}
