import React, {useEffect, useRef, useState} from 'react'
import {Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MySwitch} from "../../components/MySwitch";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {MyTable as MyTableObservaciones} from "../../components/MyTable"
import P15RO from "./P15RO";

// const validName = (names) => {
//   let result = ''
//   names.forEach(it => {
//     result = f.isValidNotEmpty(it)?it:result
//   })
//   return result
// }

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch()
  const section = 'ElaborarReporteSeguimiento'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                  today,
    identificador:          solicitud.numeroSolicitud,
    observaciones:          [''],
    observacionesReporte:   [''],
    seAprueba:              false,
    serial:                 '',
    docx:                   '',
    pdf:                    '',
    docxLded:               '',
    pdfLded:                '',
    pdfSigned:              '',
    subsanacion:            0,
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  // const handleChangeTab = (event, newTab) => {setMytab(newTab)}
  const inputRef = useRef()
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const codigoAutorizador = {
    'Maate':    1143,
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Maate':    1144, // m_vasquez2008@hotmail.es
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
          instanciaProceso?.id,
          payload,
          () => setCounter(0)
        )
      )
    }
  }

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Elaborar informe técnico de seguimiento'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 2rem 0 2rem'}}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Reporte de cumplimiento'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                <MyReadOnlyTextField id={'solicitudNumero'}
                                     value={solicitud?.numeroSolicitud}
                                     label={'Identificador'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyReadOnlyTextField id={'fecha'}
                                     value={payload.InformeSeguimiento?.fecha}
                                     label={'Fecha'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                <MyButtonBacan3 label={'Informe'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder:'informe-cumplimiento-investigador',
                                  filename: payload.InformeSeguimiento.pdf
                                })}
                                toolTip={'Descargar documento en formato pdf'}
                                width={'7rem'} />
                {
                  (payload.InformeSeguimiento.anexo !== '') ?
                    <MyButtonBacan label={'Anexo'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/anexo/${payload.InformeSeguimiento.anexo}`
                                     fetch(url)
                                       .then((res) => {
                                         return res.blob();
                                       })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], {type: 'application/pdf'})
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target = "_blank"
                                         a.click()
                                       })
                                   }}
                                   icon={FileDownloadOutlinedIcon}/> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Dictamen Técnico Maate'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                <MyReadOnlyTextField id={'solicitudNumero'}
                                     value={solicitud?.numeroSolicitud}
                                     label={'Identificador'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyReadOnlyTextField id={'fecha'}
                                     value={payload.InformeSeguimiento?.fecha}
                                     label={'Fecha'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                {f.isValidNotEmpty(payload?.DictamenTecnicoMaate?.pdf) ?
                  <MyButtonBacan label={'Dictamen'}
                                 onClick={() => {
                                   // const fileName = payload.InformeTecnicoMaate?.pdf
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Maate/${payload.DictamenTecnicoMaate?.pdf}`
                                   fetch(url)
                                     .then((res) => {
                                       return res.blob();
                                     })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], {type: 'application/pdf'})
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target = "_blank"
                                       a.click()
                                     })
                                 }}
                                 icon={FileDownloadOutlinedIcon}/> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Dictamen Técnico Senadi'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                <MyReadOnlyTextField id={'solicitudNumero'}
                                     value={solicitud?.numeroSolicitud}
                                     label={'Identificador'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyReadOnlyTextField id={'fecha'}
                                     value={payload.InformeSeguimiento?.fecha}
                                     label={'Fecha'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                {f.isValidNotEmpty(payload.InformeTecnicoSenadi?.pdf) ?
                  <MyButtonBacan label={'Dictamen'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Senadi/${payload.DictamenTecnicoSenadi?.pdf}`
                                   fetch(url)
                                     .then((res) => {
                                       return res.blob();
                                     })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], {type: 'application/pdf'})
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target = "_blank"
                                       a.click()
                                     })
                                 }}
                                 icon={FileDownloadOutlinedIcon}/> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1} style={{padding:'1rem'}}>
                {(f.isValid(payload.ReporteTecnicoSenadi?.pdf) || f.isValid(payload.ReporteTecnicoSenadi?.pdfLded)) ?
                  <MyButtonBacan label={'Informe'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Senadi/${(payload.ReporteTecnicoSenadi?.pdfLded !== '')?payload.ReporteTecnicoSenadi?.pdfLded:payload.ReporteTecnicoSenadi?.pdf}`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }}
                                 icon={FileDownloadOutlinedIcon} /> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
              <MySubtitle subtitle={'Observaciones para el informe'} />
            </Grid>
            <Grid item xs={12}>
              <MyTableObservaciones id={'observacionesReporte'}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    columnName={'Observaciones *'}
                                    canEdit={canEdit}
                                    addItem={() => {
                                      if(formValues['observacionesReporte'].filter(it => it === '').length === 0) {
                                        const field = 'observacionesReporte'
                                        const newSet = [ ...formValues[field], '']
                                        const newFormValues = {...formValues, [field]:newSet}
                                        setFormValues(newFormValues)
                                      }
                                    }} />
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <MySwitch id='seAprueba'
                        label={'¿Reporte de cumplimiento aprobado?'}
                        formValues={formValues}
                        fullWidth={false}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={8} >
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 0 0 1rem'}}>
                {
                  formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      const tipo = 'REPSEG'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave({...formValues, serial:serial})()
                                          setFormValues({...formValues, serial:serial})
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx: '',
                                        pdf: '',
                                        docxLded: '',
                                        pdfLded: '',
                                        pdfSigned: '',
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Informe'}
                                    width={'11rem'}
                                    onClick={() => {
                                      const doc = MyTagged2Docx(plantilla(payload), nombreOrganizacion, P0102API.mapping({
                                        solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                        payload,
                                        autorizador,
                                        coordinador,
                                        perfilUsuario,
                                        nombreOrganizacion,
                                        tipo: instanciaProceso.tipoInstanciaProceso?.toLowerCase(),
                                        section: payload[section]
                                      }))
                                      API.genDocxAnPdf({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        document: doc,
                                        formValues,
                                        setFormValues,
                                        subfolder,
                                        filename: filenameDOCX,
                                        nombreOrganizacion,
                                      })
                                    }}
                                    toolTip={'Generar'}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                    })}
                                    toolTip={'Descargar documento en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descargar documento en formato pdf'}
                                    width={'5rem'} />
                  </>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir documento modificado en formato docx'}
                                        width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        toolTip={'Subir documento firmado o para firmar en formato pdf'}
                                        width={'11rem'} />
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'firmar pdf'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        disabled={true}
                                        icon={() => <DoneAllIcon height={'1rem'} fill={'rgba(255, 255, 255, 0.6)'} />}
                                        toolTip={'Firmar documento electrónicamente'}
                                        width={'11rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     toolTip={'Descargar dictamen tecnico'}
                                                                     width={'2rem'}/>
                    }
                  </>
                }
              </Stack>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P15RO payload={payload} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  "reporteAprobado": formValues.seAprueba,
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={!canEdit || counter > 0 || formValues.asunto === '' || formValues.detalle === '' || formValues.pdfSigned === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = (payload) => {
  // const observacionesReporte = payload?.ElaborarReporteSeguimiento?.observacionesReporte?.join('{:}')
  // console.log(':: payload :: ', payload.nombreProyecto)
  /* eslint-disable no-useless-concat */
  /* eslint-disable no-template-curly-in-string */
  return (""
    + '${TITULO1}INFORME TÉCNICO'
    + '${SALTO}${CENTRAR}No. <<serial>>'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}<<sistema.fecha>>'
    + '${SALTO}${ENCABEZADO.REPORTE}' + payload.nombreProyecto
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${ENCABEZADO.DATOS}Datos generales{:}<<solicitud.identificador>>'
    + '${SALTO}'
    + '${SALTO}${ENCABEZADO.DETALLE}Funcionario responsable de informe{:}Nombre{:}Contacto{:}Teléfono{:}Correo electrónico{:}Cargo{:}<<usuario.nombre>>{:}<<usuario.rol>>{:}<<usuario.cargo>>{:}<<usuario.telefono>>{:}<<usuario.correoElectronico>>'
    + '${SALTO}${ENCABEZADO.DETALLE}Funcionario responsable de informe{:}Nombre{:}Contacto{:}Teléfono{:}Correo electrónico{:}Cargo{:}<<sistema.nombreAutorizadorPrincipalSenescyt>>{:}<<sistema.rolAutorizadorPrincipalSenescyt>>{:}<<sistema.cargoAutorizadorPrincipalSenescyt>>{:}<<sistema.telefonoAutorizadorPrincipalSenescyt>>{:}<<sistema.coreoElectronicoAutorizadorPrincipalSenescyt>>'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}1.  ANTECEDENTES'
    + '${SALTO}${PARRAFO}(ANTECEDENTES DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}2.  ALCANCE'
    + '${SALTO}${PARRAFO}DEFINIDO POR LA INSTITUCIÓN'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}3.  BASE LEGAL   '
    + '${SALTO}${PARRAFO}'
    + '${SALTO}${PARRAFO}(DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}4. OBJETIVOS  '
    + '${SALTO}${PARRAFO}DEFINIDO POR LA INSTITUCIÓN'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}5. ANÁLISIS DE LA  PERTINENCIA, VIABILIDAD Y FACTIBILIDAD DE LA INVESTIGACIÓN  '
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${PARRAFO}(El análisis será establecido por cada una de las instituciones, ya sea con una plantilla base o como un campo a completar para cada solicitud).'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}6. CONCLUSIONES Y RECOMENDACIONES'
    + '${SALTO}${PARRAFO}(DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}${FIRMA}Desarrollo del documento{:}<<usuario.nombre>>{:}<<sistema.fecha>>'
    + '${SALTO}${FIRMA}Revisión del documento{:}<<sistema.nombreCoordinadorCasosSenescyt>>{:}<<sistema.fecha>>'
    + '${SALTO}${FIRMA}Aprobación del documento{:}María José Ramirez{:}<<sistema.fecha>>')
  /* eslint-enable no-useless-concat */
  /* eslint-enable no-template-curly-in-string */
}
