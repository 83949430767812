import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux";
import {
  FormControlLabel,
  Grid, Radio, RadioGroup,
  Stack, Typography,
} from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {dialog} from 'src/styles/styles'
import ApartmentIcon from '@mui/icons-material/Apartment'
import {MyTextField} from 'src/components/MyTextField'
import {MyAutocompleteTextField} from 'src/components/MyAutocompleteTextField'
import API from 'src/features/App/API'
import {MyUpload} from 'src/components/MyUpload'
import {MyAreaTextField} from 'src/components/MyAreaTextField'
import {rulesFor} from 'src/features/P01Solicitud'
import {MySubtitle} from 'src/components/MySubtitle'
import {
  agricultura,
  ambienteBio,
  areaInvestigacion,
  cienciaTec,
  marino_costero,
  procesosInd
} from 'src/features/P01Solicitud/CONF'
import {MyTable as MyTableObjetivos} from 'src/components/MyTable'
import {MyTable as MyTableProductos} from 'src/components/MyTable'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {MySelect} from 'src/components/MySelect'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import {f} from 'src/commons'
import {MyMaskedTextField} from "../../../components/MyMaskedTextField";
import FormControl from "@mui/material/FormControl";
import {handleSetRecoleccionDeRecursos} from 'src/features/App/sliceApp'
import {MyReadOnlyAreaTextField} from "../../../components/MyReadOnlyAreaTextField";
import {MyTypography} from "../../../components/MyTypography";

const Propuesta = ({usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter, idTask, formErrors, setFormErrors}) => {
  const section = 'Propuesta'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const patrocinadores = useSelector(state => state.app.instituciones)

  const RULES = rulesFor(section)
  const emptyPayload = RULES.emptyPayload()
  const handlesChange= RULES.handlesChange()
  // const validators = RULES.validators()
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  useEffect(() => {
    setMainFormValues(prev => ({...prev, [section]:formValues}))
    incrementCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues])

  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada'

  const myData = (formValues['areaInvestigacion'] === 'Agricultura y ganadería')?agricultura:
    (formValues['areaInvestigacion'] === 'Espacio marino-costero y recursos bioacuáticos')?marino_costero:
      (formValues['areaInvestigacion'] === 'Ambiente, bioeconomía, bioconocimiento, cambio y variabilidad climática')?ambienteBio:
        (formValues['areaInvestigacion'] === 'Procesos industriales')?procesosInd:
          (formValues['areaInvestigacion'] === 'Ciencia, tecnología, sociedad y gobernabilidad')?cienciaTec:[]

  const [patrocinadoresAll, setPatrocinadoresAll] = useState([])

  useEffect(() => {
    if(canEdit) {
      API.fetchPatrocinadoresAll().then(patr => setPatrocinadoresAll(patr?.map(it => it.nombre)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(f.isValid(formValues.recoleccionDeRecursos)) {
      dispatch(handleSetRecoleccionDeRecursos(formValues.recoleccionDeRecursos))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues.recoleccionDeRecursos])

  const origMetCampo = payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.metodologia
  const origMetLab = payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.metodologiaLaboratorio

  useEffect(() => {
    if(idTask === '55000016_Activity_ElaborarSolicitudWF0405') {
      if((formValues.cartaPatrocinador === '' || !f.isValid(formValues.cartaPatrocinador)) && f.isValidNotEmpty(payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.cartaPatrocinador)) {
        API.copiarDirServer(payload?.solicitudOriginal?.idSolicitud, '/cartaPatrocinador', instanciaProceso?.solicitud?.id).then((res) => {
          setFormValues({...formValues, cartaPatrocinador: payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.cartaPatrocinador})
        })
      }
      if((formValues.cartaApoyo === '' || !f.isValid(formValues.cartaApoyo)) && f.isValidNotEmpty(payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.cartaApoyo)) {
        API.copiarDirServer(payload?.solicitudOriginal?.idSolicitud, '/cartaApoyo', instanciaProceso?.solicitud?.id).then((res) => {
          setFormValues({...formValues, cartaApoyo: payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.cartaApoyo})
        })
      }
      if((formValues.convenioApoyo === '' || !f.isValid(formValues.convenioApoyo)) && f.isValidNotEmpty(payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.convenioApoyo)) {
        API.copiarDirServer(payload?.solicitudOriginal?.idSolicitud, '/convenioApoyo', instanciaProceso?.solicitud?.id).then((res) => {
          setFormValues({...formValues, convenioApoyo: payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.convenioApoyo})
        })
      }
      if((formValues.repLegalApoyo === '' || !f.isValid(formValues.repLegalApoyo)) && f.isValidNotEmpty(payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.repLegalApoyo)) {
        API.copiarDirServer(payload?.solicitudOriginal?.idSolicitud, '/repLegalApoyo', instanciaProceso?.solicitud?.id).then((res) => {
          setFormValues({...formValues, repLegalApoyo: payload?.solicitudOriginal?.payloadSolicitud?.Propuesta?.repLegalApoyo})
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setMainFormValues(prev => ({...prev, [section]:formValues}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!f.isValid(formErrors[section])) return <Typography>loading ...</Typography>
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud'} />
        </Grid>
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405')?
          <Grid item xs={12}>
            <MyAreaTextField id='nombre'
                             label={'Nombre del Proyecto *'}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             error={formErrors[section]['nombre']}
                             canEdit={canEdit}
                             minHeight={'4rem'}
                             handleChange={(e) => handlesChange['nombre'](
                               e,
                               canEdit,
                               setFormValues,
                               formValues,
                               null,
                               null,
                               null,
                               canEdit
                             )} />
          </Grid>:
          <Grid item xs={12}>
            <MyAreaTextField id='nombre'
                             label={'Nombre del Proyecto *'}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             error={formErrors[section]['nombre']}
                             canEdit={false}
                             minHeight={'4rem'} />
          </Grid>
        }
        <Grid item xs={9}>
          <MyAutocompleteTextField id='patrocinador'
                                   options={patrocinadoresAll}
                                   label={'Identificación de la Institución Patrocinadora *'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<ApartmentIcon sx={dialog.textFieldIcon}/>}
                                   canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                                   error={formErrors[section]['patrocinador']}
                                   note={!patrocinadoresAll.includes(formValues['patrocinador'])?'- Fuera de lista de patrocinadores':''}
                                   selectedFile={formValues.patrocinador}
                                   handleChange={(e) => handlesChange['patrocinador'](
                                         e,
                                         canEdit,
                                         setFormValues,
                                         formValues,
                                         null,
                                         null,
                                         null,
                                       )} />
        </Grid>
        <Grid item xs={3}>
          <MyMaskedTextField id='montFinancimiento'
                             type={'dollarMaskCustom'}
                             label={'Monto de Financiamiento Aproximado *'}
                             formValues={formValues}
                             setFormValues={setFormValues}
                             isNumber={true}
                             icon={<AttachMoneyIcon sx={dialog.textFieldIcon}/>}
                             error={formErrors[section]['montFinancimiento']}
                             canEdit={canEdit}
                             handleChange={(e) => handlesChange['montFinancimiento'](
                               e,
                               canEdit,
                               setFormValues,
                               formValues,
                               null,
                               null,
                               null,
                               canEdit
                             )} />
        </Grid>
        <Grid item xs={9}>
          <MyTextField id='nombreRepLegalPatrocidador'
                       label={'Nombre del representante legal *'}
                       formValues={formValues}
                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                       error={formErrors[section]['nombreRepLegalPatrocidador']}
                       canEdit={canEdit && idTask !== '55000016_Activity_ElaborarSolicitudWF0405'}
                       handleChange={(e) => handlesChange['nombreRepLegalPatrocidador'](
                         e,
                         canEdit,
                         setFormValues,
                         formValues,
                         null,
                         null,
                         null,
                         canEdit
                       )} />
        </Grid>
        <Grid item xs={3}>
          <MyTextField id='cargoRepLegalPatrocidador'
                       label={'Cargo *'}
                       formValues={formValues}
                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                       error={formErrors[section]['cargoRepLegalPatrocidador']}
                       canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                       handleChange={(e) => handlesChange['cargoRepLegalPatrocidador'](
                         e,
                         canEdit,
                         setFormValues,
                         formValues,
                         null,
                         null,
                         null,
                         canEdit
                       )} />
        </Grid>
        <Grid item xs={12}>
            <MyUpload id={'cartaPatrocidador'}
                      dir={instanciaProceso?.solicitud?.id}
                      label={'Carta'}
                      formValues={formValues}
                      error={formErrors[section]['cartaPatrocidador']}
                      setFormValues={setFormValues}
                      canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                      info={'info...'} />
        </Grid>
        <Grid item xs={12}>
          <MyAutocompleteTextField id='apoyo'
                                   options={patrocinadores}
                                   label={'Identificación de la Institución Nacional de Apoyo *'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   icon={<ApartmentIcon sx={dialog.textFieldIcon}/>}
                                   error={formErrors[section]['apoyo']}
                                   note={
                                     !patrocinadores.includes(formValues['apoyo'])?'Fuera de lista de instituciones nacionales de apoyo':''
                                   }
                                   canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                                   handleChange={(e) => handlesChange['apoyo'](
                                     e,
                                     canEdit,
                                     setFormValues,
                                     formValues,
                                     null,
                                     null,
                                     null,
                                   )}

          />
        </Grid>
        <Grid item xs={9}>
          <MyTextField id='nombreRepLegalApoyo'
                       label={'Nombre del representante legal *'}
                       formValues={formValues}
                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                       error={formErrors[section]['nombreRepLegalApoyo']}
                       canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                       handleChange={(e) => handlesChange['nombreRepLegalApoyo'](
                         e,
                         canEdit,
                         setFormValues,
                         formValues,
                         null,
                         null,
                         null,
                         canEdit
                       )} />
        </Grid>
        <Grid item xs={3}>
          <MyTextField id='cargoRepLegalApoyo'
                       label={'Cargo *'}
                       formValues={formValues}
                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                       error={formErrors[section]['cargoRepLegalApoyo']}
                       canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                       handleChange={(e) => handlesChange['cargoRepLegalApoyo'](
                         e,
                         canEdit,
                         setFormValues,
                         formValues,
                         null,
                         null,
                         null,
                         canEdit
                       )} />
        </Grid>
        <Grid item xs={12} >
          <Stack direction={"row"} spacing={2} justifyContent="space-between" alignItems="center">
            <MyUpload id={'cartaApoyo'}
                      label={'Carta'}
                      dir={instanciaProceso?.solicitud?.id}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      error={formErrors[section]['cargoRepLegalApoyo']}
                      canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                      info={'info...'} />
            <MyUpload id={'convenioApoyo'}
                      dir={instanciaProceso?.solicitud?.id}
                      label={'Convenio'}
                      canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      info={'info...'}/>
          </Stack>
        </Grid>
        <Grid item xs={12} >
          <Stack direction={"row"} spacing={2} justifyContent="flex-end" alignItems="center">
            <MyUpload id={'repLegalApoyo'}
                      dir={instanciaProceso?.solicitud?.id}
                      label={'Nombramiento Representante Legal *'}
                      error={formErrors[section]['repLegalApoyo']}
                      formValues={formValues}
                      canEdit={canEdit}
                      setFormValues={setFormValues}
                      info={'info...'}/>
          </Stack>
        </Grid>
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
          <Grid item xs={12}>
            <MyAreaTextField id='resumen'
                             label={'Resumen Ejecutivo *'}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             error={formErrors[section]['resumen']}
                             handleChange={(e) => handlesChange['resumen'](
                               e,
                               canEdit,
                               setFormValues,
                               formValues,
                               null,
                               null, // setFormErrors,
                               null, // formErrors[section],
                               canEdit)}/>
          </Grid> :
          <Grid item xs={12}>
            <MyAreaTextField id='resumen'
                             label={'Resumen Ejecutivo *'}
                             formValues={formValues}
                             canEdit={false}
                             error={formErrors[section]['resumen']}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
          </Grid>
        }
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
          <Grid item xs={12}>
            <MyAreaTextField id='objetivo'
                             label={'Objetivo General *'}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             canEdit={canEdit}
                             error={formErrors[section]['objetivo']}
                             handleChange={(e) => handlesChange['objetivo'](
                               e,
                               canEdit,
                               setFormValues,
                               formValues,
                               null,
                               null,
                               null,
                               canEdit)}/>
          </Grid> :
          <Grid item xs={12}>
            <MyAreaTextField id='objetivo'
                             label={'Objetivo General *'}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             canEdit={false} />
          </Grid>
        }
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
          <>
            <Grid item xs={12}>
              <MyTableObjetivos id={'objetivos'}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                columnName={'Objetivos Específicos *'}
                                canEdit={canEdit}
                                addItem={() => {
                                  if (formValues['objetivos'].filter(it => it === '').length === 0) {
                                    const field = 'objetivos'
                                    const newSet = [...formValues[field], ...['']]
                                    const newFormValues = {...formValues, [field]: newSet}
                                    setFormValues(newFormValues)
                                  }
                                }}
              />
            </Grid>
            { (formErrors[section]['objetivos'] !== '')? <Grid item xs={12}><MyTypography>{formErrors[section]['objetivos']}</MyTypography></Grid>:null}
          </>
           :
          <Grid item xs={12}>
            <MyTableObjetivos id={'objetivos'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              columnName={'Objetivos Específicos *'}
                              canEdit={false} />
          </Grid>
        }
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={8}>
              <MyTextField id='plazo'
                           label={'Plazo de ejecución *'}
                           formValues={formValues}
                           icon={<AccessTimeIcon sx={dialog.textFieldIcon}/>}
                           error={formErrors[section]['plazo']}
                           canEdit={canEdit}
                           isNumber={true}
                           handleChange={(e) => {
                             if(e.target.value === '' || !isNaN(e.target.value))
                               handlesChange['plazo'](
                                 e,
                                 canEdit,
                                 setFormValues,
                                 formValues,
                                 null,
                                 null,
                                 null,
                                 canEdit
                               )}} />
            </Grid>
            <Grid item xs={4} sx={{p:'2rem 0 0 0.3rem'}}>
              <Typography sx={{color:'#575756',fontfamily: RobotoCondensedRegular,fontSize: '0.9rem',}}>(meses)</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <MySelect id='areaInvestigacion'
                    label={'Área de Investigación *'}
                    canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                    error={formErrors[section]['areaInvestigacion']}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    data={areaInvestigacion} />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <MySelect id='lineaInvestigacion'
                    label={'Línea de Investigación *'}
                    formValues={formValues}
                    error={formErrors[section]['lineaInvestigacion']}
                    canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                    setFormValues={setFormValues}
                    defaultValue={''}
                    data={myData} />
        </Grid>
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405')?
          <Grid item xs={12}>
            <MyAreaTextField id='definicionProblema'
                             label={'Definición del Problema *'}
                             formValues={formValues}
                             error={formErrors[section]['definicionProblema']}
                             canEdit={canEdit}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             handleChange={handleChange} />
          </Grid>:
          <Grid item xs={12}>
            <MyAreaTextField id='definicionProblema'
                             label={'Definición del Problema *'}
                             formValues={formValues}
                             canEdit={false}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
          </Grid>
        }
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
          <Grid item xs={12}>
            <MyAreaTextField id='justificacion'
                             label={'Justificación *'}
                             formValues={formValues}
                             error={formErrors[section]['justificacion']}
                             canEdit={canEdit}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             handleChange={handleChange}/>
          </Grid> :
          <Grid item xs={12}>
            <MyAreaTextField id='justificacion'
                             label={'Justificación *'}
                             formValues={formValues}
                             canEdit={false}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
          </Grid>
        }
        {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup row
                          aria-labelledby="recoleccion-recurso"
                          name="recoleccion-recurso-rg"
                          id='recoleccionDeRecursos'
                          value={formValues.recoleccionDeRecursos}
                          onChange={(e, v) => setFormValues({...formValues, recoleccionDeRecursos: v})}>
                <FormControlLabel value="in-situ" control={<Radio/>}
                                  label="¿Recurso a ser recolectado (in situ)?"/>
                <FormControlLabel value="ex-situ" control={<Radio/>}
                                  label="¿Recurso depositado previamente (ex situ)?"/>
                <FormControlLabel value="in-ex-situ" control={<Radio/>} label="Ambos, in situ y ex situ"/>
              </RadioGroup>
            </FormControl>
          </Grid> :
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup row
                          aria-labelledby="recoleccion-recurso"
                          name="recoleccion-recurso-rg"
                          id='recoleccionDeRecursos'
                          value={formValues.recoleccionDeRecursos} >
                <FormControlLabel value="in-situ" control={<Radio/>} label="¿Recurso a ser recolectado (in situ)?"/>
                <FormControlLabel value="ex-situ" control={<Radio/>}
                                  label="¿Recurso depositado previamente (ex situ)?"/>
                <FormControlLabel value="in-ex-situ" control={<Radio/>} label="Ambos, in situ y ex situ"/>
              </RadioGroup>
            </FormControl>
          </Grid>
        }
        {['in-ex-situ', 'in-situ'].includes(formValues['recoleccionDeRecursos']) &&
          <Grid item xs={12}>
            <MyAreaTextField id='metodologia'
                             label={'Metodología de campo *'}
                             error={formErrors[section]['metodologia']}
                             formValues={formValues}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                             handleChange={handleChange} />
          </Grid>
        }
        {
          f.isValid(origMetCampo) ?
          <Grid item xs={12}>
            <MyReadOnlyAreaTextField id={'origMetCampo'}
                                     value={origMetCampo}
                                     label={'Metodología de campo original'}
                                     icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
          </Grid>:null
        }
        <Grid item xs={12}>
          <MyAreaTextField id='metodologiaLaboratorio'
                           label={'Metodología de laboratorio *'}
                           error={formErrors[section]['metodologiaLaboratorio']}
                           formValues={formValues}
                           icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                           handleChange={handleChange}/>
        </Grid>
        {
          f.isValid(origMetLab) ?
            <Grid item xs={12}>
              <MyReadOnlyAreaTextField id={'origMetLab'}
                                       value={origMetCampo}
                                       label={'Metodología de laboratorio original'}
                                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
            </Grid>:null
        }
      </Grid>
      {(idTask !== '55000016_Activity_ElaborarSolicitudWF0405') ?
        <>
          <Grid item xs={12}>
            <MyTableProductos id={'resultadosEsperados'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              columnName={'Resultados Esperados *'}
                              canEdit={canEdit}
                              addItem={() => {
                                if (formValues['resultadosEsperados'].filter(it => it === '').length === 0) {
                                  const field = 'resultadosEsperados'
                                  const newSet = [...formValues[field], ...['']]
                                  const newFormValues = {...formValues, [field]: newSet}
                                  setFormValues(newFormValues)
                                }
                              }}/>
          </Grid>
          { (formErrors[section]['resultadosEsperados'] !== '')? <Grid item xs={12}><MyTypography>{formErrors[section]['resultadosEsperados']}</MyTypography></Grid>:null}
        </> :
        <Grid item xs={12}>
          <MyTableProductos id={'resultadosEsperados'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            columnName={'Resultados Esperados *'}
                            canEdit={false} />
        </Grid>
      }
    </Box>
  )
}

export default Propuesta
