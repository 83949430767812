import React from 'react'
import {Box, Grid, Stack, Typography} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MyTextField} from "../../components/MyTextField";
import {MySelect} from "../../components/MySelect";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MyTableDepositoMuestrasWF15} from "../../components/MyTableDepositoMuestrasWF15";
import {MyTableNuevasEspeciesWF15} from "../../components/MyTableNuevasEspeciesWF15";
import {MySubtitle} from "../../components/MySubtitle";
import {MySwitch} from "../../components/MySwitch";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import API from "../App/API";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

const P15RO = ({payload}) => {
  const section = 'InformeSeguimiento'
  const formValues = payload[section]
  const aConocimientoTradicional = payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.AccesoConocimiento.aConocimientoTradicional
  return (
    <Box sx={{p:'5rem 2rem 0 2rem'}}>
      <Grid container>
        <Grid item xs={12} >

          <Grid item xs={12} >
            <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
              <MyReadOnlyTextField id={'numeroSolicitud'}
                                   label={'Número de solicitud'}
                                   value={payload.numeroSolicitud} />
              <MyReadOnlyTextField id={'fecha'}
                                   label={'fecha'}
                                   value={formValues.fecha} />
            </Stack>
          </Grid>

          <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
            <MySelect id={'tipo'}
                      label={'Tipo de informe'}
                      formValues={formValues}
                      data={['Parcial', 'Final', '']}
                      canEdit={false} />
            <MySelect id={'perioricidad'}
                      label={'Perioricidad'}
                      formValues={formValues}
                      data={['Mensual', 'Bimensual', 'Trimestral', 'Semestral', 'Anual', '']}
                      canEdit={false} />
            {formValues.tipo === 'Final' &&
              <Box sx={{w:'20%'}}>
                <MyTextField id={'presupuestoReal'}
                             label={'Presupuesto Real'}
                             isNumber={true}
                             formValues={formValues}
                             canEdit={false} />
              </Box>
            }
          </Stack>
          <Grid item xs={12} >
            <MyTextField id={'enlaceIPT'}
                         label={'Enlace IPT'}
                         formValues={formValues}
                         canEdit={false} />
          </Grid>
          {formValues.tipo === 'Final' ?
            <Grid item xs={12}>
              <MyAreaTextField id={'resumen'}
                               label={'Resumen'}
                               formValues={formValues}
                               canEdit={false} />
            </Grid> : null
          }
          {formValues.tipo === 'Final' ?
            <Grid item xs={12}>
              <MyAreaTextField id={'abstract'}
                               label={'Abstract'}
                               formValues={formValues}
                               canEdit={false} />
            </Grid> : null
          }
          {(aConocimientoTradicional === true) ?
            <Grid item xs={12}>
              <MyAreaTextField id={'resultadosCLPI'}
                               label={'En caso de contar con CLPI y contrato de acceso al CCTT indicar los resultados alcanzados en el presente periodo'}
                               formValues={formValues}
                               canEdit={false} />
            </Grid> : false
          }
          <Grid item xs={12} >
            <MyTableDepositoMuestrasWF15 id={'depositoMuestras'}
                                         canEdit={false}
                                         formValues={formValues}
                                         canDeleteRow={false} />
          </Grid>
          <Grid item xs={12} >
            <MyTableNuevasEspeciesWF15 id={'nuevasEspecies'}
                                       canEdit={false}
                                       formValues={formValues}
                                       canDeleteRow={false} />
          </Grid>
          <Grid item xs={12} >
            <MyTableObservaciones id={'resultados'}
                                  canEdit={false}
                                  columnName={'Resultados'}
                                  formValues={formValues}
                                  canDeleteRow={false} />
          </Grid>
          <Grid item xs={12} >
            <MyAreaTextField id={'conclusiones'}
                             label={'Conclusiones'}
                             formValues={formValues}
                             canEdit={false} />
          </Grid>
          <Grid item xs={12} >
            <MyAreaTextField id={'recomendaciones'}
                             label={'Recomendaciones'}
                             formValues={formValues}
                             canEdit={false} />
          </Grid>
          <Grid item xs={12} >
            <MyAreaTextField id={'infoAdicional'}
                             label={'Información adicional'}
                             formValues={formValues}
                             canEdit={false} />
          </Grid>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MySubtitle subtitle={'Informe Técnico'} />
          </Grid>
          <Grid item xs={12}>
            <MyButtonBacan3 label={'PDF'}
                            icon={FileDownloadIcon}
                            onClick={() => API.fetchDownloadPDF({
                              solicitudId: payload.solicitudId,
                              subfolder: 'informe-cumplimiento-investigador',
                              filename: formValues.pdf
                            })}
                            toolTip={'Descagar documento en formato pdf'}
                            width={'5rem'} />
          </Grid>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MySubtitle subtitle={'Declaración de veracidad de la información'} />
          </Grid>
          <Grid item xs={12} >
            <Typography sx={{m:'1rem 0 1rem 0'}}>{`Yo, ${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos} portador del documento de identidad 1203107485, en calidad de solicitante, declaro bajo juramento que la información constante en el presente informe es verdadera y de mi absoluta responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o introducir cualquier información falsa en el presente documento, asumo toda la responsabilidad administrativa, civil o penal conforme lo establecido por ley.`}</Typography>
            <Typography sx={{m:'1rem 0 1rem 0'}}>Atención: Por favor revise la información del informe, si está seguro que los datos son correctos acepte y declare la veracidad de toda la información detallada en el documento y envíe la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Stack  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <MySwitch id='declaracion'
                          label={'Aceptar y enviar'}
                          formValues={formValues}
                          fullWidth={false}
                          canEdit={false} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default P15RO
