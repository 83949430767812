import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  InputLabel,
  Select,
  Stack,
  Tab,
  TextField,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {Email, PhoneInTalk, Smartphone} from "@mui/icons-material";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion";
import {MySelect} from "../../components/MySelect";
import {MyTextField} from "../../components/MyTextField";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {paises} from 'src/features/P01Solicitud/CONF'
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyUpload} from "../../components/MyUpload";
import {MySwitch} from "../../components/MySwitch";
import {MyButtonBacan2} from "../../components/MyButtonBacan2";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyAutocompleteTextField} from "../../components/MyAutocompleteTextField";
import {MyTypography} from "../../components/MyTypography";

const P11ElaborarSolicitud = ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                }) => {
  const dispatch = useDispatch()
  const section = 'ElaborarSolicitud'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const wf0102 = slct.payload.Solicitud.solicitudAprobada.wf0102
  const centrosDocumentacion = wf0102.RecursosExSitu.centrosDocumentacion.map(it => it.centroDocumentacion)
  const ATM      = slct.payload.Solicitud.solicitudAprobada
  const aprobada = slct.payload.Solicitud.solicitudAprobada
  let   rss      = slct.payload.Solicitud.solicitudAprobada.Solicitud.recursosIS
  const payload = slct.payload
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                       today,
    numeroSolicitud:             solicitud.numeroSolicitud,
    aprobadaIdentificador:       aprobada.Solicitud.identificador,
    documentoIdentificador:      '',
    documentoFecha:              today,
    documentoObjetivo:           '',
    permisoExportacion:          '',
    origenInstitucion:           '',
    destinoAutorizacion:         '',
    destinoPais:                 '',
    destinoInstitucion:          '',
    responsableNombres:          '',
    responsableCedula:           '',
    responsableContacto:         '',
    responsableCorreo:           '',
    recursos:                    [],
    fechaEstimadaReporte:        today,
    texto:
      `Yo, ${wf0102?.Solicitante?.nombresCompletos} portador del documento de identidad ${wf0102?.Solicitante?.cedula}, en calidad de solicitante, declaro bajo ` +
      'juramento que la información constante en la presente solicitud es verdadera y de mi absoluta ' +
      'responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o ' +
      'introducir cualquier información falsa o corregir el presente documento, asumo toda la responsabilidad ' +
      'administrativa, civil o penal conforme lo establecido por ley.\n\n'+
      'Atención: Por favor revise la información del registro de la solicitud, si está seguro que los datos son ' +
      'correctos acepte y declare la veracidad de toda la información detallada en la presente solicitud y envíe ' +
      'la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.',
    si: false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload.Solicitud[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  useEffect(() => {
    const items = wf0102?.Personal?.personal?.filter(it => it.nombre === formValues.responsableNombres)
    if(items?.length >= 1) {
      const item = items[0]
      setFormValues({...formValues,  responsableCedula: item.cedula, responsableContacto: item.celular, responsableCorreo: item.correo})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.responsableNombres])
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange(e, canEdit?'entrada':'*', setFormValues, formValues)
  }
  const [rowSelected, setRowSelected] = useState('')
  const [dataLine, setDataLine] = React.useState('')
  const handleChangeDataLine = (event) => { setDataLine(event.target.value) }
  const [evCantidades, setEvCantidades] = useState(true)
  useEffect(() => {
    let cantidades = formValues.recursos.filter(it4=> it4.exportacionSolicitada === 0)
    setEvCantidades(cantidades.length === 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.recursos])
  const [evMetodos, setEvMetodos] = useState(true)
  useEffect(() => {
    let metodos = formValues.recursos.filter(it4=> it4.metodo === '')
    setEvMetodos(metodos.length === 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.recursos])
  const [evTratamientos, setEvTratamientos] = useState(true)
  useEffect(() => {
    let tratamientos = formValues.recursos.filter(it4=> it4.tratamiento === '')
    setEvTratamientos(tratamientos.length === 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.recursos])
  const [myTab, setMytab] = useState('1')
  return (
    <Box sx={accordeonBox.container}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Solicitud de Permiso de Exportación'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="ATM" value="2" />
            <Tab label="Autorización" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Identificador'}
                                   icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={solicitud.numeroSolicitud} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Fecha'}
                                   icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={formValues['fecha']} />
            </Grid>
            <Solicitante solicitud={{solicitud: {payload: wf0102}}} displayContact={false} />
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Documento habilitante'} />
            </Grid>
            <Grid item xs={8}>
              <MyTextField id={'documentoIdentificador'}
                           label={'Número de permiso *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={canEdit}
                           handleChange={handleChange} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <Box sx={{m:'1rem 0 0 0'}}>
                <MyDatePicker id={'documentoFecha'}
                              label={'Fecha'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MyAreaTextField id='documentoObjetivo'
                               label={'Objetivo de la exportación *'}
                               formValues={formValues}
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                               canEdit={canEdit}
                               handleChange={(e) => {
                                 if(canEdit) {
                                   setFormValues({...formValues, documentoObjetivo: e.target.value})
                                 }
                               }} />
            </Grid>
            <Grid item xs={12}>
              <MyUpload id={'permisoExportacion'}
                        label={'Permiso de exportación: *'}
                        dir={instanciaProceso?.solicitud?.id}
                        canEdit={canEdit}
                        formValues={formValues}
                        setFormValues={setFormValues} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Origen'} />
            </Grid>
            <Grid item xs={12}>
              <MyAutocompleteTextField id={'origenInstitucion'}
                                       width={'40rem'}
                                       options={centrosDocumentacion}
                                       label={'Institución *'}
                                       formValues={formValues}
                                       setFormValues={setFormValues}
                                       canEdit={canEdit}
                                       handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Destino del recurso'}/>
            </Grid>
            <Grid item xs={6}>
              <MyTextField id={'destinoAutorizacion'}
                           label={'Identificador de autorización de colecta/investigación *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={canEdit}
                           handleChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <MySelect id={'destinoPais'}
                        width={'40rem'}
                        label={'Pais *'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        canEdit={true}
                        data={paises} />
            </Grid>
            <Grid item xs={12}>
              <MyTextField id={'destinoInstitucion'}
                           label={'Institucion *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={canEdit}
                           handleChange={handleChange} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Responsable de la exportación'} />
            </Grid>
            <Grid item xs={12} >
              <MySelect id={'responsableNombres'}
                        label={'Nombres *'}
                        data={wf0102?.Personal?.personal?.map(it => it.nombre)}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={4}>
              <MyTextField id={'responsableCedula'}
                           label={'Cédula *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={false}
                           handleChange={handleChangePhone} />
            </Grid>
            <Grid item xs={4}>
              <MyTextField id={'responsableContacto'}
                           label={'Número de celular *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={false}
                           handleChange={handleChangePhone} />
            </Grid>
            <Grid item xs={4}>
              <MyTextField id={'responsableCorreo'}
                           label={'Correo Electrónico *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                           canEdit={false}
                           handleChange={handleChange} />
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Recursos'}/>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant='standard' fullWidth>
                <InputLabel id="demo-simple-select-label">Recurso</InputLabel>
                <Select labelId="demo-simple-select-label"
                        id="dataLine-simple-select"
                        value={dataLine}
                        onChange={handleChangeDataLine} >
                  { rss?.filter(it => it.cantidadAutorizada > 0).map((it, idx) => <MenuItem key={idx} value={it.mid}>{`${it.scientificname} (${it.submuestra})`}</MenuItem>) }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} >
              <Box sx={{p:'0.8rem 0 0 0'}}>
                <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                  <MyButtonBacan2 onClick={() => {
                    if(dataLine !== '') {
                      const filtro = rss.filter(it => it.mid === dataLine)
                      if(filtro.length === 1) {
                        let line = rss.filter(it => it.mid === dataLine)[0]
                        const found = formValues.recursos.filter(it =>  it.mid === line.mid).length > 0
                        if(!found) {
                          let lineas = [...formValues.recursos]
                          line.cantidadAutorizada = line.cantidadSolicitada
                          line.exportacionSolicitada = 0
                          lineas.push(line)
                          setFormValues({...formValues, recursos: lineas})
                        }
                      }
                    }
                  }}
                                  icon={AddIcon}
                                  label={'Agregar recurso'} />
                  {(rowSelected !== '') &&
                    <MyButtonBacan2 onClick={() => {
                                      if(rowSelected !== '') {
                                        const filtro = formValues.recursos.filter(it => it.mid !== rowSelected)
                                        setFormValues({...formValues, recursos: filtro})
                                      }
                                    }}
                                    icon={RemoveIcon}
                                    label={'Eliminar recurso'} />
                  }
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MyTableRecursos2 id={'recursos'}
                                canEdit={true}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                setRowSelected={setRowSelected}
                                mode={'WF11'} />
              { !evTratamientos &&
                <MyTypography>Hay items sin definición de tratamiento</MyTypography>
              }
              { !evMetodos &&
                <MyTypography>Hay items sin definición de método</MyTypography>
              }
              { !evCantidades &&
                <MyTypography>Hay items con cantidades 0</MyTypography>
              }
              { formValues.recursos.length === 0 &&
                <MyTypography>No hay items en la tabla de recursos</MyTypography>
              }
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Fecha estimada de reporte'}/>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent='flex-end'  alignItems='center'>
                <Box sx={{width:'12rem'}}>
                  <MyDatePicker id={'fechaEstimadaReporte'}
                                label={'Fecha'}
                                formValues={formValues}
                                setFormValues={setFormValues} />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Declaración de veracidad de la información'}/>
            </Grid>
            <Grid item xs={12}>
              <TextField id='nombres'
                         multiline
                         rows={10}
                         value={formValues.texto}
                         fullWidth
                         variant='standard'
                         aria-readonly={true}
                         sx={dialog.textTypography}
                         InputProps={{
                           disableUnderline: true,
                           sx: {
                             fontSize: '12px',
                             backgroundColor: 'transparent',
                           }
                         }}
                         InputLabelProps={{
                           sx: {
                             fontSize: '14px',
                           }
                         }} />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <MySwitch id='si'
                          label={'Aceptar y enviar'}
                          formValues={formValues}
                          handleChange={handleChange}
                          fullWidth={false}
                          canEdit={canEdit} />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P10ATMRO payload={ATM} />
        </TabPanel>
        <TabPanel value="3">
          <Autorizacion solicitud={{numeroSolicitud: wf0102?.InformeTecnicoSenescyt?.identificador, payload: wf0102}} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                {
                  (canEdit)?
                    <>
                      <MyButtonBacan label={'Regresar'}
                                     icon={ArrowBackIcon}
                                     onClick={() => {
                                       if(counter <= 2) {
                                         dispatch(handleClear())
                                       } else
                                         alert('Debe GUARDAR los cambios realizados')
                                     }} />
                      <MyButtonBacan label={'Guardar'}
                                     onClick={() => {
                                       const newPayload = {
                                         Solicitud: {
                                           ...slct.payload.Solicitud,
                                           numeroSolicitud: solicitud.numeroSolicitud,
                                           solicitudAprobada: JSON.stringify(slct.payload.Solicitud.solicitudAprobada),
                                           [section] : {...formValues}
                                         }
                                       }
                                       dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                       setCounter(0)
                                     }}
                                     disabled={counter <= 0}
                                     icon={SaveOutlinedIcon} />
                      <MySendButton disabled={
                                      counter > 0 || formValues.si === false  ||
                                      formValues.aprobadaIdentificador === '' ||
                                      formValues.documentoIdentificador === '' |
                                      formValues.documentoObjetivo === '' ||
                                      formValues.permisoExportacion === ''  ||
                                      formValues.origenInstitucion === '' ||
                                      formValues.destinoAutorizacion === '' ||
                                      formValues.destinoPais === '' ||
                                      formValues.destinoInstitucion === '' ||
                                      formValues.responsableNombres === '' ||
                                      formValues.responsableCedula === '' ||
                                      formValues.responsableContacto === '' ||
                                      formValues.responsableCorreo === '' ||
                                      formValues.recursos.length === 0 ||
                                      formValues.si === false ||
                                      !evCantidades
                                    }
                                    label={'enviar'}
                                    onSend={ () => {
                                      const metadata = JSON.stringify({"solicitudId": `${instanciaProceso?.solicitud?.id}`,})
                                      dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                    }} />
                    </>
                    :
                    <MyButtonBacan label={'Regresar'} icon={ArrowBackIcon} onClick={() => dispatch(handleClear())} />
                }
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default P11ElaborarSolicitud
