import React from 'react'
import {
  Box,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {Email, PhoneInTalk, Smartphone} from "@mui/icons-material";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyUpload} from "../../components/MyUpload";
import {MySwitch} from "../../components/MySwitch";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";

const P11RO = ({payload}) => {
  let solicitud = payload.Solicitud
  const solicitudAprobada = (typeof solicitud.solicitudAprobada === "object") ? solicitud.solicitudAprobada : JSON.parse(solicitud.solicitudAprobada)
  const wf0102 = solicitudAprobada.wf0102
  const arr = solicitud.numeroSolicitud
  const solicitudId = arr[arr.length-1]
  return (
    <Box sx={accordeonBox.container}>
      <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud'} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Identificador'}
                               icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={solicitud.numeroSolicitud} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Fecha'}
                               icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={solicitud.ElaborarSolicitud['fecha']} />
        </Grid>
        <Solicitante solicitud={{solicitud: {payload: wf0102}}} displayContact={false} />
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Documento habilitante'} />
        </Grid>
        <Grid item xs={8}>
          <MyReadOnlyTextField id={'documentoIdentificador'}
                       label={'Número de permiso'}
                       value={solicitud.ElaborarSolicitud.documentoIdentificador}
                       icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                       canEdit={false} />
        </Grid>
        <Grid item xs={2}>
            <MyReadOnlyTextField id={'documentoFecha'}
                                 label={'fecha'}
                                 value={solicitud.ElaborarSolicitud.documentoFecha}
                                 icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyTextField id='documentoObjetivo'
                           label={'Objetivo de la exportación *'}
                           value={solicitud.ElaborarSolicitud.documentoObjetivo}
                           icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12}>
          <MyUpload id={'permisoExportacion'}
                    label={'Permiso de exportación: *'}
                    dir={solicitudId}
                    canEdit={false}
                    formValues={solicitud.ElaborarSolicitud} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Origen'} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyTextField id={'origenInstitucion'}
                                   width={'40rem'}
                                   label={'Institución *'}
                                   value={solicitud.ElaborarSolicitud.origenInstitucion} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino del recurso'}/>
        </Grid>
        <Grid item xs={6}>
          <MyReadOnlyTextField id={'destinoAutorizacion'}
                               label={'Identificador de autorización de colecta/investigación *'}
                               value={solicitud.ElaborarSolicitud.destinoAutorizacion} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyTextField id={'destinoPais'}
                    width={'40rem'}
                    label={'Pais *'}
                    value={solicitud.ElaborarSolicitud.destinoPais} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyTextField id={'destinoInstitucion'}
                       label={'Institucion *'}
                       value={solicitud.ElaborarSolicitud.destinoInstitucion}
                       icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Responsable de la exportación'} />
        </Grid>
        <Grid item xs={12} >
          <MyReadOnlyTextField id={'responsableNombres'}
                               label={'Nombres *'}
                               value={solicitud.ElaborarSolicitud.responsableNombres}
                               icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
          />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField id={'responsableCedula'}
                               label={'Cédula *'}
                               value={solicitud.ElaborarSolicitud.responsableCedula}
                               icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
          />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField id={'responsableContacto'}
                               label={'Número de celular *'}
                               value={solicitud.ElaborarSolicitud.responsableContacto}
                               icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
          />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField id={'responsableCorreo'}
                               label={'Correo Electrónico *'}
                               value={solicitud.ElaborarSolicitud.responsableCorreo}
                               icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
          />
        </Grid>

        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Recursos'}/>
        </Grid>
        <Grid item xs={12}>
          <MyTableRecursos2 id={'recursos'}
                            canEdit={false}
                            formValues={solicitud.ElaborarSolicitud}
                            mode={'WF11'} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Fecha estimada de reporte'}/>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent='flex-end'  alignItems='center'>
            <MyReadOnlyTextField id={'fechaEstimadaReporte'}
                                 label={'Fecha'}
                                 value={solicitud.ElaborarSolicitud.fechaEstimadaReporte} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Declaración de veracidad de la información'}/>
        </Grid>
        <Grid item xs={12}>
          <TextField id='nombres'
                     multiline
                     rows={10}
                     value={solicitud.ElaborarSolicitud.texto}
                     fullWidth
                     variant='standard'
                     aria-readonly={true}
                     sx={dialog.textTypography}
                     InputProps={{
                       disableUnderline: true,
                       sx: {
                         fontSize: '12px',
                         backgroundColor: 'transparent',
                       }
                     }}
                     InputLabelProps={{
                       sx: {
                         fontSize: '14px',
                       }
                     }} />
        </Grid>
        <Grid item xs={12}>
            <MySwitch id='si'
                      label={''}
                      formValues={solicitud.ElaborarSolicitud}
                      canEdit={false} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default P11RO
