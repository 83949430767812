import React, {useEffect, useRef, useState} from 'react'
import {accordeonBox, common, dialog} from "../../styles/styles";
import {AppBar, ButtonGroup, CssBaseline, Grid, Stack, Tab} from "@mui/material";
import {SectionTitle} from "../../components/SectionTitle";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {Solicitante} from "../P01Solicitud/subcomponents/Solicitante";
import {MySubtitle} from "../../components/MySubtitle";
import {MySwitch} from "../../components/MySwitch";
import API from "../App/API";
import {MyUpload} from "../../components/MyUpload";
import {MyTextField} from "../../components/MyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "../App/sliceApp";
import {MySendButton} from "../../components/MySendButton";
import {f} from "../../commons";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GLOBALS from "../App/globals";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {PageOrientation} from "docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import P11RO from "./P11RO";
import {MyTypography} from "../../components/MyTypography";

const P11Aprobacion = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario}) => {
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const mp = slct.payload.Solicitud.solicitudAprobada
  const section = 'Aprobacion'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  console.log('payload.Validacion :::. ', payload.Validacion)
  const emptyPayload = {
    requisito01: payload.Validacion.requisito01,
    requisito02: payload.Validacion.requisito02,
    requisito03: payload.Validacion.requisito03,
    requisito04: payload.Validacion.requisito04,
    seAprueba:   payload.Validacion.seAprueba,
    certificado: 'Se certifica que el documento que respalda el permiso de exportación se encuentra registrado en la base de datos de la Ventanilla Única Virtual para la Investigación de la Biodiversidad',
    asunto:      '',
    detalle:     '',
    serial:      '',
    docx:        '',
    pdf:         '',
    pdfSigned:   '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  useEffect(() => {
    if(payload.Validacion.seAprueba) {
      setFormValues({
        ...formValues,
        asunto:  'Solicitud de validación de permiso de exportación de recursos ha sido APROBADA',
        detalle: `Estimado/a ${mp.wf0102.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Permiso de Exportación registrada con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} ha sido APROBADA.\n\n`
      })
    } else {
      setFormValues({
        ...formValues,
        asunto:  'Solicitud de validación de permiso de exportación de recursos ha sido NEGADA',
        detalle: `Estimado/a ${mp.wf0102.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Permiso de Exportación registrada con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} ha sido NEGADA.\n\n`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.Validacion.seAprueba])
  const inputRef = useRef()
  const subfolder = 'aprobacion'
  const filename = `aprobacion-${payload.solicitudId}`
  const filenameDOCX = `${filename}.docx`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const funcionarios = useSelector(state => state.app.funcionarios)
  const aprueba = funcionarios.filter(it => it.idPerfil === 1141)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 164)[0]
  return(
    <Box sx={accordeonBox.container}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Emitir certificado de registro'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={{...accordeonBox.container2, pt:'2rem'}}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent='space-between' alignItems='center' >
                <MyReadOnlyTextField id={'numeroSolicitud'}
                                     label={'Número solicitud'}
                                     value={slct.numeroSolicitud}
                                     icon={<LocalShippingIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={slct.payload.Solicitud.ElaborarSolicitud.fecha}
                                     icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Permiso'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent='space-between' alignItems='center' >
                <MyReadOnlyTextField id={'numeroSolicitud'}
                                     label={'Número de permiso'}
                                     value={slct.payload.Solicitud.ElaborarSolicitud.documentoIdentificador}
                                     icon={<LocalShippingIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={slct.payload.Solicitud.ElaborarSolicitud.documentoFecha}
                                     icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <MyReadOnlyTextField id={'documento'}
                                   label={'Objeto'}
                                   value={slct.payload.Solicitud.ElaborarSolicitud.documentoObjetivo}
                                   icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
            </Grid>
            <Grid item xs={12}>
              <MyUpload id={'permisoExportacion'}
                        label={'Permiso de exportación: *'}
                        dir={instanciaProceso?.solicitud?.id}
                        canEdit={false}
                        formValues={slct.payload.Solicitud.ElaborarSolicitud} />
            </Grid>
            <Solicitante solicitud={{solicitud: {payload: mp.wf0102}}} displayContact={false}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Recursos'}/>
            </Grid>
            <Grid item xs={12}>
              <MyTableRecursos2 id={'recursos'}
                                canEdit={false}
                                formValues={slct.payload.Solicitud.ElaborarSolicitud}
                                mode={'WF11'} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Fecha estimada de reporte'}/>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent='flex-end'  alignItems='center'>
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={slct.payload.Solicitud.ElaborarSolicitud.fechaEstimadaReporte}
                                     icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Requisitos'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0.5rem 2rem 0.5rem 0.6rem'}}>
              <MySwitch id={'requisito01'}
                        label={'¿La exportación ha sido debidamente autorizada por la entidad competente, conforme la normativa ecuatoriana vigente.?'}
                        formValues={formValues}
                        canEdit={false}/>
            </Grid>
            <Grid item xs={12} style={{padding:'0.5rem 2rem 0.5rem 0.6rem'}}>
              <MySwitch id={'requisito02'}
                        label={'¿La exportación se enmarca en un permiso de investigación/contrato autorizado previamente por la Autoridad competente?'}
                        formValues={formValues}
                        canEdit={false} />
            </Grid>
            <Grid item xs={12} style={{padding:'0.5rem 2rem 0.5rem 0.6rem'}}>
              <MySwitch id={'requisito03'}
                        label={'¿La exportación se encuentra respaldada en un Acuerdo de Transferencia de Material previamente validado por la SENESCYT?'}
                        formValues={formValues}
                        canEdit={false} />
            </Grid>
            <Grid item xs={12} style={{padding:'0.5rem 2rem 0.5rem 0.6rem'}}>
              <MySwitch id={'requisito04'}
                        label={'¿La exportación prevé el reporte de los resultados alcanzados.?'}
                        formValues={formValues}
                        canEdit={false} />
            </Grid>
            <Grid item xs={12} style={{padding:'1.2rem 3rem 0 24px'}}>
              <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >
                <MySwitch id='seAprueba'
                          label={'¿La solicitud cumple con los Requisitos?'}
                          formValues={formValues}
                          fullWidth={false}
                          canEdit={false} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Certificado'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
              <MyAreaTextField id={'certificado'}
                               label={'Contenido *'}
                               formValues={formValues}
                               handleChange={handleChange}
                               canEdit={true} />
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                {formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      const tipo = 'PEXP'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          setFormValues({...formValues, serial:serial})
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx: '',
                                        pdf: '',
                                        pdfSigned: '',
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar CERTIFICADO'}
                                    width={'11rem'}
                                    onClick={() => {
                                      const taggeDoc = {contenidoPlantilla:plantilla}
                                      const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, 'Senescyt', mapping(formValues, payload, slct, aprueba, coordinador, perfilUsuario), PageOrientation.LANDSCAPE, instanciaProceso?.solicitud?.nombreProyecto)
                                      API.genDocxAnPdf({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        document: doc,
                                        formValues,
                                        setFormValues,
                                        subfolder,
                                        filename: filenameDOCX,
                                      })
                                    }}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: formValues.docx,
                                    })}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: formValues.pdf
                                    })}
                                    width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({
                                          ...formValues,
                                          pdfSigned: filenamePDFsigned
                                        })}
                                        toolTip={'Subir pdf firmado'}
                                        width={'9rem'}/>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'Firmar PDF'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        disabled={true}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({
                                          ...formValues,
                                          pdfSigned: filenamePDFsigned
                                        })}
                                        toolTip={'Firmar el documento electrónicamete'}
                                        width={'9rem'}/>
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     toolTip={'Descargar el documento firmado'}
                                                                     width={'3rem'}/>
                    }
                  </ButtonGroup>
                }
              </Stack>
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Notificación'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0 8 0 0.6rem'}}>
              <MyTextField id={'asunto'}
                           label={'Asunto *'}
                           formValues={formValues}
                           handleChange={handleChange}
                           canEdit={true} />
            </Grid>
            {
              formValues.asunto === ''?
                <MyTypography sx={common.warnig}>Asunto es obligatorio</MyTypography>
                :null
            }
            <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
              <MyAreaTextField id={'detalle'}
                               label={'Contenido *'}
                               formValues={formValues}
                               handleChange={handleChange}
                               canEdit={true} />
            </Grid>
            {
              formValues.detalle === ''?
                <MyTypography sx={common.warnig}>Contenido es obligatorio</MyTypography>
                :null
            }
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P11RO payload={slct.payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'}
                     spacing={1}
                     justifyContent="space-between"
                     alignItems='center'
                     sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton  onSave={() => {
                                  const newPayload = {
                                    ...payload,
                                    [section] : {...formValues},
                                  }
                                  setCounter(0)
                                  dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                }}
                               disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId":Number(instanciaProceso?.solicitud?.id),
                                  "cumpleRequisitos": payload.Validacion.seAprueba,
                                  "asunto": formValues.asunto,
                                  "detalle": formValues.detalle,
                                })
                  console.log(metadata)
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              myTip={'Enviar y continuar el proceso'}
                              disabled={formValues.asunto === '' ||
                                formValues.detalle === '' ||
                                formValues.certificado === '' ||
                                formValues.pdfSigned === '' ||
                                counter > 0} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

/* eslint-disable */
const plantilla = '' +
  '${TITULO1}CERTIFICADO DE PERMISO DE EXPORTACIÓN' +
  '${SALTO}${TITULO1}<<serial>>' +
  '${SALTO}' +
  '${SALTO}${QR}SOLICITANTE:<<solicitante.nombre>>,CEDULA:<<solicitante.identificador>>,SERIAL:<<serial>>,ITEMS:<<items>>' +
  '${SALTO}${PARRAFO}<<certificado>>' +
  '${SALTO}' +
  '${SALTO}{PARRAFO}Se certifica que el documento que respalda el permiso de exportación se encuentra registrado en la base de datos de la Ventanilla Única Virtual para la Investigación de la Biodiversidad.' +
  '${SALTO}${TITULO2}Permiso de exportación' +
  '${SALTO}${VAR}Número de permiso${:}<<permisoExportacion.numero>>' +
  '${SALTO}${VAR}Fecha de emisión${:}<<permisoExportacion.fecha>>' +
  '${SALTO}${VAR}Objetivo${:}<<permisoExportacion.objeto>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Solicitante' +
  '${SALTO}${VAR}Identificación${:}<<solicitante.identificador>>' +
  '${SALTO}${VAR}Nombre${:}<<solicitante.nombre>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Autorización' +
  '${SALTO}${VAR}Identificador${:}<<autorizacion.identificador>>' +
  '${SALTO}${VAR}Proyecto${:}<<proyecto.nombre>>' +
  '${SALTO}${VAR}Vigencia${:}<<autorizacion.vigencia>>' +
  '${SALTO}${VAR}Institución Nacional de Apoyo${:}<<solicitud.institucionNacionalApoyo>>' +
  '${SALTO3}' +
  '${SALTO}${VAR}Fecha de emisión${:}<<sistema.fecha>>' +
  '${SALTO3}' +
  '${SALTO}${FIRMAN}<<firman>>'
/* eslint-enable */

const mapping = (fv, py, slct, aprueba, coordinador, perfilUsuario) => {
  const items = slct.payload.Solicitud.ElaborarSolicitud.recursos?.map(it => JSON.stringify({nombreCientífico: it.scientificname, cantidadAutorizada: it.cantidadAutorizada}))?.join(',')
  return {
    '<<serial>>' :                             fv.serial,
    '<<items>>' :                              items,
    '<<permisoExportacion.numero>>' :          slct.numeroSolicitud,
    '<<autorizacion.identificador>>' :         py.Solicitud.aprobadaIdentificador,
    '<<permisoExportacion.fecha>>' :           py.Solicitud.ElaborarSolicitud.fecha,
    '<<permisoExportacion.objeto>>' :          py.Solicitud.ElaborarSolicitud.documentoObjetivo,
    '<<proyecto.nombre>>' :                    slct.nombreProyecto,
    '<<solicitud.institucionNacionalApoyo>>' : slct.payload.Solicitud.solicitudAprobada.wf0102.Propuesta.apoyo,
    '<<solicitante.identificador>>' :          slct.payload.Solicitud.solicitudAprobada.wf0102.Solicitante.cedula,
    '<<solicitante.nombre>>' :                 slct.payload.Solicitud.solicitudAprobada.wf0102.Solicitante.nombresCompletos,
    '<<autorizacion.vigencia>>' :              `${slct.payload.Solicitud.solicitudAprobada.wf0102.Propuesta.plazo} meses`,
    '<<sistema.fecha>>' :                      format(new Date(), 'dd-MMMM-yyyy', {locale: es}),
    '<<firman>>': JSON.stringify([
      {accion: 'Aprobado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:'Director/a de investigación cientídica'},
      {accion: 'Revisado por', nombre:coordinador?.nombreUsuario ?? '', cargo:'Analista de investigación científica/3'},
      {accion: 'Elaborado por', nombre:aprueba?.nombreUsuario  ?? '', cargo:'Analista de investigación científica/2'},
    ]),
    '<<certificado>>' :                        fv.certificado,
  }
}

export default P11Aprobacion
