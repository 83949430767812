import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {Email, LocationOn, PhoneInTalk, Smartphone} from '@mui/icons-material'
import {MyTextField} from 'src/components/MyTextField'
import {MyButtonBacan3} from 'src/components/MyButtonBacan3'
import SearchIcon from '@mui/icons-material/Search'
import {MyMaskedTextField} from 'src/components/MyMaskedTextField'
import {MyUpload} from "../../../components/MyUpload";

export const ProveedorRecursos = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  useEffect(() => {
    // set form values
    setFormValues({...formValues, [section]: {...sectionValues}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionValues])

  // handlers
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Proveedor de Recursos Genéticos'} />
      </Grid>
      <Grid item xs={3}>
        <MyTextField id={'prRuc'}
                     label={'RUC *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     error={formErrors[section]['prRuc']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={2} >
        <Box sx={{p:'1.9rem 0 0 0', width:'100%'}} display={'flex'} justifyContent={'center'}>
          <MyButtonBacan3 label={'BUSCAR RUC'}
                          onClick={() => {
                            API.buscarRuc(sectionValues.prRuc).then((res) =>{
                              if(f.isValid(res.id)) {
                                setSectionValues({
                                  ...sectionValues,
                                  prRuc:                    res.id,
                                  prRazonSocial:            res.razonSocial ?? '',
                                  prDomicilioLegal:         res.direccionRuc ?? '',
                                  prRepresentanteLegal:     res.representanteLegal ?? '',
                                  prTelefonoTrabajo:        res.telefonoRuc ?? '',
                                  prCelular:                res.celularRepresentante ?? '',
                                  prCorreoElectronico:      res.correoRuc ?? '',
                                  rucInfo:                  res,
                                })
                              }
                            })
                          }}
                          icon={SearchIcon} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'prRazonSocial'}
                     label={'Razón Social'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['prRazonSocial']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'prDomicilioLegal'}
                     label={'Domicilio legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['prDomicilioLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'prRepresentanteLegal'}
                     label={'Representante legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['prRepresentanteLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'prTelefonoTrabajo'}
                           label={'Teléfono del trabajo'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['prTelefonoTrabajo']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'prCelular'}
                           label={'Celular'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['prCelular']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'prCorreoElectronico'}
                     label={'Correo Electrónico'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={formErrors[section]['prCorreoElectronico']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        <MyUpload id={'prProveedorRecursosLegal'}
                  label={'Nombramiento Representante Legal'}
                  dir={formValues.INFO.solicitudId}
                  setFormValues={setSectionValues}
                  error={formErrors[section]['prProveedorRecursosLegal']}
                  formValues={sectionValues}
                  canEdit={canEdit} />
      </Grid>
    </Grid>
  )
}
