import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Box} from '@mui/system'
import {accordeonBox} from '../../styles/styles'
import AppBar from '@mui/material/AppBar'
import {SectionTitle} from 'src/components/SectionTitle'
import {
  API
} from 'src/features/P03'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  CssBaseline,
  Grid,
  Stack
} from '@mui/material'
import {f} from 'src/commons'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {MyTypography} from "../../components/MyTypography"

export const WF03Solicitud = ({usuarioSenescyt, instanciaTarea, instanciaProceso, solicitud, perfilUsuario,}) => {
  const dispatch = useDispatch()
  let payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const [counter, setCounter] = useState(-1)
  const bandeja = useSelector(state => state.app.bandeja)
  const canEdit = bandeja === 'entrada' &&  ["En Progreso", "Iniciada"].includes(instanciaTarea.estadoInstanciaProceso)
  const incrementCounter = () => setCounter(counter + 1)
  const sections = API.sections
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '')
  }

  const emptyFormValues = {
    INFO: {solicitudId: solicitud.id, numeroSolicitud: solicitud.numeroSolicitud, nombre:''},
    Propuesta: {...API.esqueletos.Propuesta},
    Solicitante: {
      ...API.esqueletos.Solicitante,
      cedula: f.defaultString(usuarioSenescyt?.cedula,''),
      celular: f.defaultString(usuarioSenescyt?.celular,''),
      direccion: f.defaultString(usuarioSenescyt?.direccion,''),
      email: f.defaultString(usuarioSenescyt?.email,''),
      etnia: f.defaultString(usuarioSenescyt?.etnia,''),
      genero: f.defaultString(usuarioSenescyt?.genero,''),
      nacionalidad: f.defaultString(usuarioSenescyt?.nacionalidad,''),
      nombresCompletos: f.defaultString(usuarioSenescyt?.nombresCompletos,''),
      registro: f.defaultString(usuarioSenescyt?.registro,''),
      telefono: f.defaultString(usuarioSenescyt?.telefono,''),
    },
    EmpresaSolicitante: {...API.esqueletos.EmpresaSolicitante},
    ResponsableSolicitud: {...API.esqueletos.ResponsableSolicitud},
    ResponsableTecnico: {...API.esqueletos.ResponsableTecnico},
    ProveedorRecursos:  {...API.esqueletos.ProveedorRecursos},
    DeApoyo:  {...API.esqueletos.DeApoyo},
    RecursosInSitu: {...API.esqueletos.RecursosInSitu},
    RecursosExSitu: {...API.esqueletos.RecursosExSitu},
    AccesoConocimiento: {...API.esqueletos.AccesoConocimiento},
    Personal: {...API.esqueletos.Personal},
    Declaracion: {...API.esqueletos.Declaracion}
  }

  const [formValues, setFormValues] = useState({
    ...emptyFormValues,
    Propuesta:{...emptyFormValues.Propuesta, ...payload?.Propuesta},
    Solicitante:{...emptyFormValues.Solicitante, ...payload?.Solicitante},
    EmpresaSolicitante:{...emptyFormValues.EmpresaSolicitante, ...payload?.EmpresaSolicitante},
    ResponsableSolicitud:{...emptyFormValues.ResponsableSolicitud, ...payload?.ResponsableSolicitud},
    ResponsableTecnico: {...emptyFormValues.ResponsableTecnico, ...payload?.ResponsableTecnico},
    ProveedorRecursos: {...emptyFormValues.ProveedorRecursos, ...payload?.ProveedorRecursos},
    DeApoyo:  {...emptyFormValues.DeApoyo, ...payload?.DeApoyo},
    RecursosInSitu: {...emptyFormValues.RecursosInSitu, ...payload?.RecursosInSitu},
    RecursosExSitu: {...emptyFormValues.RecursosExSitu, ...payload?.RecursosExSitu},
    AccesoConocimiento: {...emptyFormValues.AccesoConocimiento, ...payload?.AccesoConocimiento},
    Personal: {...emptyFormValues.Personal, ...payload?.Personal},
    Declaracion: {...emptyFormValues.Declaracion, ...payload?.Declaracion}
  })

  const [formErrors, setFormErrors] =
    useState({
      Solicitante: {...API.aString(API.esqueletos.Solicitante)},
      Propuesta: {...API.aString(API.esqueletos.Propuesta)},
      EmpresaSolicitante: {...API.aString(API.esqueletos.EmpresaSolicitante)},
      ResponsableSolicitud: {...API.aString(API.esqueletos.ResponsableSolicitud)},
      ResponsableTecnico: {...API.aString(API.esqueletos.ResponsableTecnico)},
      ProveedorRecursos:  {...API.aString(API.esqueletos.ProveedorRecursos)},
      DeApoyo:  {...API.aString(API.esqueletos.DeApoyo)},
      RecursosInSitu: {...API.aString(API.esqueletos.RecursosInSitu)},
      RecursosExSitu: {...API.aString(API.esqueletos.RecursosExSitu)},
      AccesoConocimiento: {...API.aString(API.esqueletos.AccesoConocimiento)},
      Personal: {...API.aString(API.esqueletos.Personal)},
      Declaracion: {...API.aString(API.esqueletos.Declaracion)},
    })

  const buildErrors = (formValues) => {
    setFormErrors({
      ...formErrors,
      Solicitante: {
        // ...formErrors.Solicitante,
        celular: f.isValidNotEmpty(formValues.Solicitante.celular) ? '' : 'Celular no puede tener un valor vacío',
        telefono: f.isValidNotEmpty(formValues.Solicitante.telefono) ? '' : 'Teléfono no puede tener un valor vacío',
        direccion: f.isValidNotEmpty(formValues.Solicitante.direccion) ? '' : 'Dirección no puede tener un valor vacío',
        email: !f.isValidNotEmpty(formValues.Solicitante.email) ? 'Correo electrónico no puede tener un valor vacío.' : !f.isMail(formValues.Solicitante.email)?'No es un correo electrónico válido':''
      },
      EmpresaSolicitante: {
        // ...formErrors.EmpresaSolicitante,
        esPais: (formValues.EmpresaSolicitante.esExtranjera === true && formValues.EmpresaSolicitante.esPais === 'ECUADOR') ? 'ECUADOR no es valor correcto' : (formValues.EmpresaSolicitante.esPais === '') ? 'Valor de [Nacionalidad | País *] no puede quedar vacío':'',
        esRuc: ((formValues.EmpresaSolicitante.esExtranjera === false && formValues.EmpresaSolicitante.esRuc === '') || (formValues.EmpresaSolicitante.esExtranjera === true && formValues.EmpresaSolicitante.esRuc !== '')) ? 'RUC NO VÁLIDO;' : '',
        esRazonSocial: (formValues.EmpresaSolicitante.esRazonSocial === '') ? '"Razón social *" es un campo obligatorio.' : '',
        esDomicilioLegal: (formValues.EmpresaSolicitante.esDomicilioLegal === '') ? '"Domicilio *" es un campo obligatorio.' : '',
        esRepresentanteLegal: (formValues.EmpresaSolicitante.esRepresentanteLegal === '') ? '"Representante legal *" es un campo obligatorio.' : '',
        esTelefonoTrabajo: (formValues.EmpresaSolicitante.esTelefonoTrabajo === '') ? '"Teléfono del trabajo *" es un campo obligatorio.' : '',
        esCelular: (formValues.EmpresaSolicitante.esCelular === '') ? '"Celular *" es un campo obligatorio.' : '',
        esCorreoElectronico: (formValues.EmpresaSolicitante.esCorreoElectronico === '') ? '"Correo electrónico *" es un campo obligatorio.' : '',
        esEmpresaSolicitanteLegal: (formValues.EmpresaSolicitante.esEmpresaSolicitanteLegal === '') ? 'Nombramiento del represente legal no ha sido cargado;' : ''
      },
      Propuesta: {
        // ...formErrors.Propuesta,
        nombre : (formValues.Propuesta.nombre === '') ?'Campo "Nombre *" es obligatorio':'',
        resumen : (formValues.Propuesta.resumen === '') ?'Campo "Resumen de la investigación *" es obligatorio':'',
        plazo: ((formValues.Propuesta.tipo === 'meses' && (formValues.Propuesta.plazo <1 || formValues.Propuesta.plazo > 44))
          || (formValues.Propuesta.tipo === 'años' && (formValues.Propuesta.plazo <1 || formValues.Propuesta.plazo > 3.7)))
          ?'"Plazo" debe ser un valor entre 1 y 44 meses':'',
        modeloDeNegocios : (formValues.Propuesta.modeloDeNegocios === '') ?'"Modelo de nogocios *" aún no ha sido subido.':'',
        adjunto : (formValues.Propuesta.adjunto === '') ?'"Adjunto " aún no ha sido subido.':''
      },
      ResponsableSolicitud: {
        // ...formErrors.ResponsableSolicitud,
        rlNombresApellidos: (formValues.ResponsableSolicitud.rlNombresApellidos === '')?'campo "Nombres y apellidos *" es obligatorio':'',
        rlDireccionDomicilio: (formValues.ResponsableSolicitud.rlDireccionDomicilio === '')?'campo "Dirección de domicilio *" es obligatorio':'',
        rlTelefonoFijo: (formValues.ResponsableSolicitud.rlTelefonoFijo === '')?'campo "Teléfono fijo *" es obligatorio':'',
        rlCelular: (formValues.ResponsableSolicitud.rlCelular === '')?'campo "Celular *" es obligatorio':'',
        rlCorreoElectronico: (formValues.ResponsableSolicitud.rlCorreoElectronico === '')?'campo "Correo electrónico *" es obligatorio':'',
      },
      ResponsableTecnico: {
        // ...formErrors.ResponsableTecnico,
        rtNumeroRegistro: (formValues.ResponsableTecnico.rtNumeroRegistro === '')?'Campo "Número de registro *" es obligatorio':'',
        rtCedula: (formValues.ResponsableTecnico.rtCedula === '')?'Campo "Cédula|Pasaporte *" es obligatorio':'',
        rtNombresApellidos: (formValues.ResponsableTecnico.rtNombresApellidos === '')?'Campo "Nombres y apellidos" es obligatorio':'',
        rtEstudiosSuperiores: (formValues.ResponsableTecnico.rtEstudiosSuperiores === '')?'Campo "Estudios superiores *" es obligatorio':'',
        rtNacionalidad: (formValues.ResponsableTecnico.rtNacionalidad === '')?'Campo "Nacionalidad" es obligatorio':'',
        rtPublicaciones: API.evalPublicaciones(formValues.ResponsableTecnico.rtPublicaciones),
        rtActividades: API.evalActividades(formValues.ResponsableTecnico.rtActividades),
        rtTipoDocumento: (formValues.ResponsableTecnico.rtTipoDocumento === '')?'Tipo de documento no válido':'',
      },
      ProveedorRecursos: {
        // ...formErrors.ProveedorRecursos,
        prRuc: (formValues.ProveedorRecursos.prRuc === '') ? 'RUC* es obligatorio' : '',
        prRazonSocial: (formValues.ProveedorRecursos.prRazonSocial === '') ? 'Campo "RAZON SOCIAL" es obligatorio' : '',
        prDomicilioLegal: (formValues.ProveedorRecursos.prDomicilioLegal === '') ? 'Campo "Domicilio legal" es obligatorio' : '',
        prRepresentanteLegal: (formValues.ProveedorRecursos.prRepresentanteLegal === '') ? 'Campo "Representante legal" es obligatorio':'',
        prTelefonoTrabajo: (formValues.ProveedorRecursos.prTelefonoTrabajo === '') ? 'Campo "Teléfono del trabajo" es obligatorio' : '',
        prCelular: (formValues.ProveedorRecursos.prCelular === '') ? 'Campo "Celular" es obligatorio' : '',
        prCorreoElectronico: (formValues.ProveedorRecursos.prCorreoElectronico === '') ? 'Campo "Correo Electrónico" es obligatorio' : '',
        prProveedorRecursosLegal: (formValues.ProveedorRecursos.prProveedorRecursosLegal === '') ? 'Doocumento "Nombramiento Representante Legal" es obligatorio' : '',
      },
      DeApoyo: {
        // ...formErrors.DeApoyo,
        deRuc:  (formValues.DeApoyo.deRuc === '') ? '"RUC *" es un cambio obligatorio' : '',
        deRazonSocial: (formValues.DeApoyo.deRazonSocial === '') ? '"Razón Social *" es un cambio obligatorio' : '',
        deDomicilioLegal: (formValues.DeApoyo.deDomicilioLegal === '') ? '"Domicilio legal *" es un cambio obligatorio' : '',
        deRepresentanteLegal: (formValues.DeApoyo.deRepresentanteLegal === '') ? '"Representante legal *" es un cambio obligatorio' : '',
        deTelefonoTrabajo: (formValues.DeApoyo.deTelefonoTrabajo === '') ? '"Teléfono *" es un cambio obligatorio' : '',
        deCelular: (formValues.DeApoyo.deCelular === '') ? '"Celular *" es un cambio obligatorio' : '',
        deCorreoElectronico:(formValues.DeApoyo.deCorreoElectronico=== '') ? '"Correo electrónico *" es un cambio obligatorio' : '',
        deApoyoLegal: (formValues.DeApoyo.deApoyoLegal=== '') ? '"Apoyo legal *" es un cambio obligatorio' : '',
      },
      RecursosInSitu: {
        // ...formErrors.RecursosInSitu,
        muestras: API.evalMuestras(formValues.RecursosInSitu.recursos, formValues.RecursosInSitu.muestras),
        provincias: ((formValues.RecursosInSitu.recursos?.length > 0) && (formValues.RecursosInSitu.provincias?.length === 0)) ?
          'Lista de "Provincias *:" no puede quedar vacía':'',
        areasProtegidas: (formValues.RecursosInSitu.areasProtegidas?.length === 0 && formValues.RecursosInSitu.bosquesProtectores?.length === 0)?
          'Debe ingresar al meno un "Área protegida" o un "Bosque protector"':'',
        laboratorios: ((formValues.RecursosInSitu.recursos?.length > 0) && (formValues.RecursosInSitu.laboratorios?.length === 0))?
          'Debe ingresar al menos un "Laboratorio"':'',
      },
      RecursosExSitu: {
        // ...formErrors.RecursosExSitu,
        ...(formValues.RecursosExSitu.centrosDocumentacion.map(it => API.evalCentroDocumentacion(it)).reduce((acc, it) => {return {...acc, ...it}},{} )),
        laboratorios:(formValues.RecursosExSitu.laboratorios?.length === 0) ? 'Debe definir al menos un laboratorio':'',
      },
      AccesoConocimiento: {
        clpi: (formValues.AccesoConocimiento.accesoConocimiento === true && formValues.AccesoConocimiento.clpi === '')?'"CLPI" no puede quedar vacío':'',
        contratoAccesoConocimiento: (formValues.AccesoConocimiento.accesoConocimiento === true && formValues.AccesoConocimiento.contratoAccesoConocimiento === '')?'"Contrato de conocimiento" no puede quedar vacío':'',
      },
      Personal: {
        ...(API.evalPersonal(formValues.Personal.personal, formValues.Personal.experiencia)),
        ...(API.evalExperiencias(formValues.Personal.experiencia)),
      }
    })
  }

  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      incrementCounter()
    }
    buildErrors(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const sectionErrors = Object.keys(formErrors)
  const [countErrorsBySection, setCountErrorsBySection]= useState({})
  const [countErrors, setCountErrors]= useState(0)
  useEffect(() => {
    let errs = 0
    sectionErrors.forEach(it => {
      let errors = []
      const subSections = Object.keys(formErrors[it])
      subSections.forEach(it2 => {
        if(formErrors[it][it2] !== '') {
          errors.push(formErrors[it][it2])
        }
      })
      setCountErrorsBySection({...countErrorsBySection,[it]:errors.length})
      errs += errors.length
      countErrorsBySection[it]=errors.length
    })
    setCountErrors(errs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  if(f.isValid(formValues)) {
    return (
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
        <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de contrato de acceso con fines comerciales'} />
        </AppBar>
        {
          sections.map((section, idx) => (
            <Accordion disableGutters
                       elevation={0}
                       key={`panel${idx+1}`}
                       expanded={expanded === `panel${idx+1}`}
                       onChange={handleChange(`panel${idx+1}`)}
                       sx={{backgroundColor:'#F4FFF4'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${idx + 1}bh-content`}
                                id={`panel${idx + 1}bh-header`}
                                sx={accordeonBox.titleBox2}>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'98%'}} spacing={2}>
                  <Typography sx={{...accordeonBox.titleTypography2, p:0, m:0}} align="left">
                    {`${section.sectionTitle} `}
                  </Typography>
                  <MyTypography sx={{...accordeonBox.titleTypography2, fontWeight:'bold', fontSize:'0.8rem', p:0, m:0, color:'darkred'}} align="left">
                    { f.isValid(countErrorsBySection[section.code]) && countErrorsBySection[section.code] > 0 ?
                      `${countErrorsBySection[section.code]} ${countErrorsBySection[section.code] === 1?"error":"errores"}`:''
                    }
                  </MyTypography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{pl:'52px'}}>{section.f(payload,formValues,setFormValues,canEdit,formErrors,setFormErrors)}</AccordionDetails>
            </Accordion>
          ))
        }
        <CssBaseline />
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            { (bandeja === 'entrada')?
              <Grid container sx={{p:0, mt:'-1rem'}}>
                <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
                  <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                    <MyButtonBacan label={'Regresar'}
                                   // myTip={'Regresar a las tareas'}
                                   icon={ArrowBackIcon}
                                   onClick={() => {
                                     if(counter <= 2) {
                                       dispatch(handleClear())
                                     } else
                                       alert('Debe GUARDAR los cambios realizados')
                                   }} />
                    <MyButtonBacan label={'Guardar'}
                                   // myTip={'Guarda el formulario, y permite continuar editando'}
                                   onClick={() => {
                                     const newPayload= {
                                       ...payload ,
                                       ...formValues,
                                     }

                                     const cdocs = newPayload?.RecursosExSitu?.centrosDocumentacion
                                       .map((it, id) => (
                                           { 'idx':id+5,
                                             'archivo':it.adjunto,
                                             'solicitudId': newPayload.INFO.solicitudId,
                                             'desc':`Centro de documentación - Adjunto ${it.acronimo}`,
                                             'subFolder': `adjunto.${it.acronimo}`
                                           }
                                         )
                                       )

                                     const personal = newPayload.Personal.personal

                                     const pasaportes = personal.filter(it => it.pasaporte !== '')
                                       .map((it, id) => (
                                         {
                                           'idx':id+5 + cdocs.length,
                                           'archivo':it.pasaporte,
                                           'solicitudId': newPayload.INFO.solicitudId,
                                           'desc':`Pasaporte ${it.nombre}, ID: ${it.cedula} `,
                                           'subFolder': `${it.cedula}`
                                         }
                                       ))

                                     const docs = {
                                       'd01Solicitud':[   // EmpresaSolicitante
                                         {idx:0, 'archivo':newPayload.EmpresaSolicitante.esEmpresaSolicitanteLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'esEmpresaSolicitanteLegal', desc:'Empresa solicitante - Nombramiento Representante Legal'},
                                         {idx:1, 'archivo':newPayload.Propuesta.modeloDeNegocios, solicitudId: newPayload.INFO.solicitudId, subFolder: 'modeloDeNegocios', desc:'Propuesta - Modelo de negocios y beneficiarios'},
                                         {idx:2, 'archivo':newPayload.Propuesta.adjunto, solicitudId: newPayload.INFO.solicitudId, subFolder: 'adjunto', desc:'Propuesta - Adjunto'},
                                         {idx:3, 'archivo':newPayload.ProveedorRecursos.prProveedorRecursosLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'prProveedorRecursosLegal', desc:'Proveedor de Recursos - Nombramiento Representante Legal'},
                                         {idx:4, 'archivo':newPayload.DeApoyo.deApoyoLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'deApoyoLegal', desc:'Proveedor de Recursos - Nombramiento Representante Legal'},
                                         ...cdocs,
                                         ...pasaportes,
                                       ]
                                     }

                                     newPayload.INFO.docs = docs
                                     newPayload.INFO.formErrors=formErrors

                                     API.evalErrors(formErrors)

                                     dispatch(handleSaveSolicitud(
                                       instanciaProceso?.id,
                                       newPayload,
                                       () => setCounter(0))
                                     )
                                   }}
                                   disabled={counter <= 0}
                                   icon={SaveOutlinedIcon} />
                    <MySendButton disabled={counter > 0 || !f.isValid(payload.Declaracion?.si) || countErrors > 0}
                                  label={'Enviar'}
                                  onSend={ () => {
                                    const myPayload = JSON.parse(instanciaProceso.solicitud?.payload)
                                    const metadata = JSON.stringify(
                                      {
                                        "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                        "nombreProyecto": myPayload?.Propuesta?.nombre
                                      }
                                    )
                                    dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }} />
                  </Stack>
                </Grid>
              </Grid>
              :
              <Grid container sx={{p:0, mt:'-1rem'}}>
                <MyButtonBacan label={'Regresar'}
                               // myTip={'Regresa a la lista de tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => dispatch(handleClear())} />
              </Grid>
            }
          </Toolbar>
        </AppBar>
      </Box>
    )
  } else {
    return null
  }
}
