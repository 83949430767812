import React from 'react'
import {Grid} from '@mui/material'
import {SolicitanteTitle} from 'src/features/App/consts'
import {
  Badge,
  SelfImprovement,
  Public,
  CoPresent,
  Smartphone,
  PhoneInTalk,
  Email,
  LocationOn,
} from '@mui/icons-material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import {ReactComponent as Png21Icon } from 'src/styles/MySvgIcons/png-21-min.svg'

export const SolicitanteRO = ({payload}) => {
  const section = 'Solicitante'
  const solicitante = payload[section]
  return(
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Identificación'} />
      </Grid>
      <Grid item xs={6}  >
        <MyReadOnlyTextField value={solicitante?.nombresCompletos}
                             label={'Nombres Completos'}
                             icon={<Png21Icon style={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={solicitante?.nombresCompletos}
                             label={SolicitanteTitle.cedula}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={solicitante?.nombresCompletos}
                             label={SolicitanteTitle.genero}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={solicitante.nombresCompletos}
                             label={'Etnia'}
                             icon={<SelfImprovement sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={solicitante?.nacionalidad}
                             label={SolicitanteTitle.nacionalidad}
                             icon={<Public sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={solicitante?.registro}
                             label={'Número de Registro / Acreditación'}
                             icon={<CoPresent sx={dialog.readOnlyIcon}/>} />
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Contacto'} />
      </Grid>
      <Grid item xs={4}>
        <MyReadOnlyTextField value={solicitante.celular}
                             label={'Celular'}
                             icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>} />
      </Grid>
      <Grid item xs={4}>
        <MyReadOnlyTextField value={solicitante.telefono}
                             label={'Teléfono del Domicilio'}
                             icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>} />
      </Grid>
      <Grid item xs={4}>
        <MyReadOnlyTextField value={solicitante.email}
                             label={'Correo Electrónico'}
                             icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
      </Grid>
      <Grid item xs={12}>
        <MyReadOnlyTextField value={solicitante.direccion}
                             label={'Dirección del Domicilio--'}
                             icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />} />
      </Grid>
    </Grid>
  )
}
