import React from 'react'
import {MyTypography} from "../../components/MyTypography";
import {accordeonBox, dialog} from "../../styles/styles";
import {AppBar, Box, CssBaseline, Grid, Stack, Tab, Typography} from "@mui/material";
import {SectionTitle} from "../../components/SectionTitle";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {MySubtitle} from "../../components/MySubtitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {AccountCircle, Badge} from "@mui/icons-material";
import ScienceIcon from "@mui/icons-material/Science";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {MySelect} from "../../components/MySelect";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyUpload} from "../../components/MyUpload";
import {MySwitch} from "../../components/MySwitch";
import {MyDatePicker} from "../../components/MyDatePicker";
import {P09RO} from "./P09RO";
import Toolbar from "@mui/material/Toolbar";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "../App/sliceApp";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {MySendButton} from "../../components/MySendButton";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";
import {MyTable as MyTableObservaciones, MyTable as MyTableResultados} from "../../components/MyTable";

const P12RO = ({payload}) => {
  console.log('::: payload :: ', payload)
  const solicitud = payload.Solicitud.WF12SolicitudRetorno
  const wf0102 = payload.Solicitud.wf0102
  return (
    <Box sx={accordeonBox.container}>
      <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud'} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Identificador'}
                               icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={solicitud.numeroSolicitud} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Fecha'}
                               icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={solicitud.fecha} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitante'} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField id='nombres'
                               label={'Nombres completos'}
                               value={wf0102.payload.solicitud.Solicitante?.nombresCompletos}
                               icon={<AccountCircle sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField id='cedula'
                               label={'Cédula/Pasaporte'}
                               value={wf0102.payload.solicitud.Solicitante?.cedula}
                               icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Origen'} />
        </Grid>
        <Grid item xs={6}>
          <MyReadOnlyTextField id={'ubicacionPais'}
                               width={'40rem'}
                               label={'Pais'}
                               value={solicitud.ubicacionPais} />
        </Grid>
        <Grid item xs={6}>
          <MyReadOnlyTextField id={'ubicacionInstitucion'}
                               label={'Institución'}
                               value={solicitud.ubicacionInstitucion}
                               icon={<ScienceIcon sx={{fontSize: '14px', color:'silver'}}/>} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Recursos'}/>
        </Grid>
        <Grid item xs={12}>
          <MyTableRecursos2 id={'recursos'}
                            canEdit={false}
                            formValues={solicitud}
                            mode={'WF12'} />
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Justificación del retorno del holotipo'} />
        </Grid>
        <Grid item xs={12} >
          <MyReadOnlyTextField id={'responsableRetorno'}
                               label={'Responsable del returno *'}
                               value={solicitud.responsableRetorno}
                               icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>} />
        </Grid>
        <Grid item xs={4} >
          <MyReadOnlyTextField id={'cedulaResponsable'}
                               label={'Cédula responsable'}
                               value={solicitud.responsableCedula} />
        </Grid>
        <Grid item xs={4} >
          <MyReadOnlyTextField id={'celularResponsable'}
                               label={'Celular responsable'}
                               value={solicitud.responsableCelular} />
        </Grid>
        <Grid item xs={4} >
          <MyReadOnlyTextField id={'correoResponsable'}
                               label={'Correo responsable'}
                               value={solicitud.responsableCorreo} />
        </Grid>
        <Grid item xs={12} >
          <MyReadOnlyAreaTextField id={'justificacion'}
                                   label={'Justificación'}
                                   canEdit={false}
                                   value={solicitud.justificacion}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon} />} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 0'}}>
            <MyUpload id={'certificadoDeposito'}
                      label={'Certificado de depósito'}
                      dir={solicitud.solicitudId}
                      canEdit={false}
                      formValues={solicitud} />
            <Box sx={{p:'3rem 0 0 0'}}>
              <MySwitch id={'integridadHolotipo'}
                        label={'¿Se garantizó la integridad del holotipo en cuestión y su retorno en óptimas condiciones después del tiempo de salida establecido?'}
                        formValues={solicitud}
                        canEdit={false} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Informe de retorno de holotipos'} />
        </Grid>
        <Grid item xs={4}>
          <Box sx={{p:'0.9rem 1rem 0 0'}}>
            <MyReadOnlyTextField id={'fechaRetorno'}
                                 value={solicitud.fechaRetorno}
                                 label={'Fecha de retorno programada'} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{p:'0.9rem 1rem 0 0'}}>
            <MyReadOnlyTextField id={'fechaRetorno2'}
                                 value={solicitud.fechaRetorno}
                                 label={'Fecha real de retorno programada'} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {`El retorno ${solicitud.retornoEnPlazo?'SI':'NO'} se ` +
              'realizó dentro del plazo establecido en la autorización de salida'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MyTableResultados id={'resultados'}
                             formValues={solicitud}
                             columnName={'Resultados'}
                             canEdit={false} />
        </Grid>
        <Grid item xs={12}>
          <MyUpload id={'certificadoDeposito2'}
                    label={'Certificado de ingreso al centro autorizado'}
                    dir={solicitud.solicitudId}
                    canEdit={false}
                    formValues={solicitud} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default P12RO
