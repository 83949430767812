import React, {useEffect, useState} from 'react'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid, Stack} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MySwitch} from "../../../components/MySwitch";
import {Email, LocationOn, PhoneInTalk, Smartphone} from "@mui/icons-material";
import {MyTextField} from "../../../components/MyTextField";
import {MyMaskedTextField} from "../../../components/MyMaskedTextField";

export const ResponsableSolicitud = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})

  useEffect(() => {
    // set form values
    setFormValues({...formValues, [section]: {...sectionValues}})
    // set error mesage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionValues])

  // handlers
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Responsable solicitud'} />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'flex-start'}>
          <MySwitch id={'rlEsRepresentanteLegal'}
                    canEdit={canEdit}
                    label={'¿Es el representante legal?'}
                    formValues={sectionValues}
                    setFormValues={setSectionValues}
                    handleChange={handleChange}
                    fullWidth={false} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'rlNombresApellidos'}
                     label={'Nombres y apellidos *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['rlNombresApellidos']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'rlDireccionDomicilio'}
                     label={'Dirección de domicilio *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['rlDireccionDomicilio']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'rlTelefonoFijo'}
                           label={'Teléfono fijo *'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['rlTelefonoFijo']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'rlCelular'}
                           label={'Celular *'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['rlCelular']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'rlCorreoElectronico'}
                     label={'Correo Electrónico *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={formErrors[section]['rlCorreoElectronico']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
    </Grid>
  )
}
