import { TipoUsuario } from './TipoUsuario'
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { FormDatos } from './FormDatos';
import { useSelector } from 'react-redux';
import { FormFuncionarios } from './FormFuncionarios';
import { SelectMetodo } from './SelectMetodo';

const drawerWidth = 330;

export const Usuarios = () => {

  const { tipoSeleccionado, investigador } = useSelector(state => state.usuarios);

  return (
    <div>

      <Box sx={tipoSeleccionado ? { display: 'flex' } : { textAlign: 'center' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={tipoSeleccionado ? { textAlign: 'center', width: { sm: 200 }, flexShrink: { sm: 0 } } : { width: { sm: 420 }, flexShrink: { sm: 100 } }}
          aria-label="mailbox folders"
        >
          <TipoUsuario />
        </Box>
        {tipoSeleccionado === 'INVESTIGADOR' &&
          (
            <>
              <Box
                component="main"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                <SelectMetodo />
              </Box>

              {
                investigador &&
                <Box
                  component="main"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                  <FormDatos />
                </Box>
              }
            </>
          )
        }

        {
          tipoSeleccionado === 'FUNCIONARIO' &&
          <>
            <Box
              component="main"
              sx={{ width: { sm: 380 }, flexShrink: { sm: 0 } }}
            >
              <FormFuncionarios />
            </Box>
          </>
        }

      </Box>
    </div>
  )
}
