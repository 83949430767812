import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { Avatar, Divider, ListItem, ListItemIcon, ListItemText, Popover, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import { green } from '@mui/material/colors'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import { handleCrearProceso, handleRefreshTareas, logout } from 'src/features/App/sliceApp'
import List from "@mui/material/List"
import { MyConfirm } from 'src/components/MyConfirm'
import { useNavigate } from 'react-router-dom'
import { ModalPreferencias } from 'src/features/Preferencias/ModalPreferencias'
import { nuevaPreferencia } from 'src/features/Preferencias/slicePreferencias'
import { retornaUsuario } from 'src/features/Preferencias/API'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import {ModalPreferencias} from '../../Preferencias/ModalPreferencias'

export const MenuUsuario = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const usuario = useSelector(state => state.app.usuario)
  const investigador = useSelector(state => state.app.investigador)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => { setAnchorEl(null) }
  const open = Boolean(anchorEl)
  const mid = open ? 'simple-popover-3' : undefined

  const user = usuario.nombreUsuario ? usuario.nombreUsuario : ''

  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {
      // dispatch(handleCrearProceso(55000014, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario))*/}
    },
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode: 'OK_CANCEL_AFTEROK'
  })

  const handlePreferencias = () => {
    dispatch(retornaUsuario(usuario.correoUsuario))
      .then(setTimeout(() => {
        dispatch(nuevaPreferencia());
      }, 300))
  }

  return (
    <>
      <Box aria-describedby={mid}
        onClick={handleClick}
        sx={{ height: '100%', cursor: 'pointer', flexShrink: 0 }}>
        <Stack direction={'row'} spacing={2}>
          <Box sx={{ height: '100%' }}>
            <Avatar sx={{ bgcolor: green[500], mt: '0.5rem' }}>
              <HowToRegIcon />
            </Avatar>
          </Box>
          <Stack direction={'column'}>
            <Box sx={{ p: '0.5rem 0 0 0' }}>
              <Typography sx={{ lineHeight: '1rem', color: 'white', fontFamily: RobotoCondensedRegular, fontSize: '0.9rem' }}>
                {user}
              </Typography>
              <Typography sx={{ color: 'white', fontFamily: RobotoCondensedRegular, fontWeight: 'lighter', fontSize: '0.9rem' }}>
                {perfilUsuario?.perfil?.descripcionPerfil}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Popover id={mid}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'right',
               }} >
        <Box sx={{ p: '0.8rem' }}>
          <>
            <List>
              {(perfilUsuario?.perfil?.id === 1139) ?
                <>
                  <ListItem key={'DICTAMEN'} sx={{ p: 0, cursor: 'pointer' }}
                            onClick={() => {
                              dispatch(handleCrearProceso(55000019, perfilUsuario?.id, perfilUsuario?.idPerfilUsuario, {}))
                              setTimeout(function () {
                                dispatch(handleRefreshTareas(perfilUsuario.id))
                              }, 1500);
                              handleClose()
                            }} >
                    <ListItemIcon sx={{ minWidth: '1rem', m: 0 }}>
                      <ManageHistoryIcon edge="start"
                                         checked={false}
                                         icon={<ManageHistoryIcon fontSize="small" />}
                                         tabIndex={-1} />
                    </ListItemIcon>
                    <ListItemText sx={{ ml: '1rem' }}>{'Administrar autorizaciones'}</ListItemText>
                  </ListItem>
                  <Divider />
                </> : null
              }
              {
                !investigador &&
                <>
                  <ListItem key={'EXIT'} sx={{ p: 0, cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/administracion')
                    }} >
                    <ListItemIcon sx={{ minWidth: '1rem', m: 0 }}>
                      <ListAltIcon edge="start"
                        checked={false}
                        icon={<ListAltIcon fontSize="small" />}
                        tabIndex={-1} />
                    </ListItemIcon>
                    <ListItemText sx={{ ml: '1rem' }}>{'Administrar catálogos'}</ListItemText>
                  </ListItem>
                  <Divider />
                </>
              }
              <>
                <ListItem key={'EXIT'} sx={{ p: 0, cursor: 'pointer' }}
                  // onClick={()=> {
                  // navigate('/preferencias')
                  // }}
                  onClick={handlePreferencias}
                >

                  <ListItemIcon sx={{ minWidth: '1rem', m: 0 }}>
                    <ManageAccountsIcon edge="start"
                      checked={false}
                      icon={<CropSquareIcon fontSize="small" />}
                      tabIndex={-1} />
                  </ListItemIcon>
                  <ListItemText sx={{ ml: '1rem' }}>{'Preferencias'}</ListItemText>
                </ListItem>
                <Divider />
              </>
              <ListItem key={'EXIT'} sx={{ p: 0, cursor: 'pointer' }}
                onClick={() => {
                  handleClose()
                  navigate('/')
                  dispatch(logout())
                }} >
                <ListItemIcon sx={{ minWidth: '1rem', m: 0 }}>
                  <PowerSettingsNewIcon edge="start"
                    checked={false}
                    icon={<PowerSettingsNewIcon fontSize="small" />}
                    tabIndex={-1} />
                </ListItemIcon>
                <ListItemText sx={{ ml: '1rem' }}>{'Cerrar sesión'}</ListItemText>
              </ListItem>
            </List>
          </>
        </Box>
      </Popover>
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
      <ModalPreferencias />
    </>
  )
}
