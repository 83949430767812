import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid, Stack, Tab, Typography,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {MySelect} from "../../components/MySelect";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ScienceIcon from '@mui/icons-material/Science';
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {P09RO} from "./P09RO";
import {AccountCircle, Badge} from "@mui/icons-material";
import {MyTypography} from "../../components/MyTypography";
import {MyTable as MyTableObservaciones, MyTable as MyTableResultados} from "../../components/MyTable";

const P12Solicitud = ({
                        instanciaTarea,
                        perfilUsuario,
                        instanciaProceso,
                        solicitud,
                        solicitudesAprobadas,
                        canEdit=true,
                      }) => {
  const dispatch = useDispatch()
  const section = 'WF12SolicitudRetorno'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const wf0102 = slct.payload.Solicitud.wf0102
  const payload = slct.payload.Solicitud
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                       today,
    ubicacionPais:               slct.payload.Solicitud.solicitudAprobada.payload.Solicitud.ubicacionPais,
    ubicacionInstitucion:        slct.payload.Solicitud.solicitudAprobada.payload.Solicitud.ubicacionInstitucion,
    recursos:                    slct.payload.Solicitud.solicitudAprobada.payload.Solicitud.recursos.map(it => ({...it, holotiposRetornados: 0})),
    responsableRetorno:          '',
    responsableCedula:           '',
    responsableCelular:          '',
    responsableCorreo:           '',
    fechaRetorno:                slct.payload.Solicitud.solicitudAprobada.payload.Solicitud.fecha,
    justificacion:               '',
    integridadHolotipo:          slct.payload.Solicitud.solicitudAprobada.payload.Solicitud.integridadHolotipo,
    certificadoDeposito:         '',
    retornoEnPlazo:              false,
    fechaRetorno2:               today,
    resultados:                  [''],
    certificadoDeposito2:        '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues])
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  const [evCantidades, setEvCantidades] = useState(true)
  useEffect(() => {
    let cantidades = formValues.recursos.filter(it4=> it4.holotiposRetornados > 0)
    setEvCantidades(cantidades.length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.recursos])
  useEffect(() => {
    // const fecha = f.parseDate(formValues['fecha'])
    const fechaRetorno = f.parseDate(formValues.fechaRetorno)
    const fechaRetorno2 = f.parseDate(formValues.fechaRetorno2)
    const aTiempo = fechaRetorno2 <= fechaRetorno
    setFormValues({...formValues, retornoEnPlazo: aTiempo})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.fechaRetorno2])

  useEffect(() => {
    if(canEdit) {
      const targets = wf0102.payload.solicitud.Personal.personal.filter(it => it.nombre === formValues.responsableRetorno)
      if(targets.length > 0) {
        const target = targets[0]
        setFormValues({...formValues, responsableCedula:target.cedula, responsableCelular: target.celular, responsableCorreo: target.correo})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.responsableRetorno])

  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de retorno de holotipos'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="SALIDA HOLOTIPO" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={formValues['fecha']} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitante'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField id='nombres'
                                     label={'Nombres completos'}
                                     value={wf0102.payload.solicitud.Solicitante?.nombresCompletos}
                                     icon={<AccountCircle sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField id='cedula'
                                     label={'Cédula/Pasaporte'}
                                     value={wf0102.payload.solicitud.Solicitante?.cedula}
                                     icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Origen'} />
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionPais'}
                                     width={'40rem'}
                                     label={'Pais'}
                                     value={formValues.ubicacionPais} />
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionInstitucion'}
                                     label={'Institución'}
                                     value={formValues.ubicacionInstitucion}
                                     icon={<ScienceIcon sx={{fontSize: '14px', color:'silver'}}/>} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={true}
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  setRowSelected={null}
                                  mode={'WF12'} />
                {!evCantidades ?
                  <MyTypography>Al menos un item en la tabla de recursos debe tener un <strong>Holotipos retornados</strong> mayor a 0</MyTypography>:null
                }
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Justificación del retorno del holotipo'} />
              </Grid>
              <Grid item xs={12} >
                <MySelect id={'responsableRetorno'}
                          label={'Responsable del returno *'}
                          data={wf0102.payload.solicitud.Personal.personal.map(it => it.nombre)}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={4} >
                <MyReadOnlyTextField id={'cedulaResponsable'}
                                     label={'Cédula responsable'}
                                     value={formValues.responsableCedula} />
              </Grid>
              <Grid item xs={4} >
                <MyReadOnlyTextField id={'celularResponsable'}
                                     label={'Celular responsable'}
                                     value={formValues.responsableCelular} />
              </Grid>
              <Grid item xs={4} >
                <MyReadOnlyTextField id={'correoResponsable'}
                                     label={'Correo responsable'}
                                     value={formValues.responsableCorreo} />
              </Grid>
              <Grid item xs={12} >
                <MyAreaTextField id={'justificacion'}
                                 label={'Justificación'}
                                 canEdit={canEdit}
                                 formValues={formValues}
                                 setFormValues={setFormValues}
                                 icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon} />}
                                 handleChange={(e) => {
                                   setFormValues({...formValues, justificacion:e.target.value})
                                 }} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 0'}}>
                  <MyUpload id={'certificadoDeposito'}
                            label={'Certificado de depósito'}
                            dir={instanciaProceso?.solicitud?.id}
                            canEdit={canEdit}
                            formValues={formValues}
                            setFormValues={setFormValues} />
                  <Box sx={{p:'3rem 0 0 0'}}>
                    <MySwitch id={'integridadHolotipo'}
                              label={'¿Se garantizó la integridad del holotipo en cuestión y su retorno en óptimas condiciones después del tiempo de salida establecido?'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              canEdit={false}
                              handleChange={handleChange} />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe de retorno de holotipos'} />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{p:'0.9rem 1rem 0 0'}}>
                  <MyReadOnlyTextField id={'fechaRetorno'}
                                       value={formValues.fechaRetorno}
                                       label={'Fecha de retorno programada'} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{p:'0.9rem 1rem 0 0'}}>
                  <MyDatePicker id={'fechaRetorno2'}
                                setFormValues={setFormValues}
                                formValues={formValues}
                                canEdit={canEdit}
                                label={'Fecha real de retorno'} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {`El retorno ${formValues.retornoEnPlazo?'SI':'NO'} se ` +
                  'realizó dentro del plazo establecido en la autorización de salida'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MyTableResultados id={'resultados'}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      columnName={'Resultados *'}
                                      canEdit={canEdit}
                                      addItem={() => {
                                        if(formValues['resultados'].filter(it => it === '').length === 0) {
                                          const field = 'resultados'
                                          const newSet = [ ...formValues[field], '']
                                          const newFormValues = {...formValues, [field]:newSet}
                                          setFormValues(newFormValues)
                                        }
                                      }} />
              </Grid>
              <Grid item xs={12}>
                <MyUpload id={'certificadoDeposito2'}
                          label={'Certificado de ingreso al centro autorizado'}
                          dir={instanciaProceso?.solicitud?.id}
                          canEdit={canEdit}
                          formValues={formValues}
                          setFormValues={setFormValues} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P09RO solicitud={slct.payload.Solicitud} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'}
                                       onClick={() => {
                                         const Solicitud = {
                                           ...payload,
                                           [section]: {
                                             ...formValues,
                                             numeroSolicitud: solicitud.numeroSolicitud,
                                             solicitudId: instanciaProceso?.solicitud?.id,
                                           }
                                         }
                                         const newPayload = {Solicitud}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={counter <= 0}
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={ counter > 0 || !evCantidades ||
                                                 formValues.responsableRetorno === '' ||
                                                 formValues.justificacion === '' ||
                                                 formValues.certificadoDeposito === '' ||
                                                 (formValues.resultados.filter(it => it !== '').length === 0) ||
                                                 formValues.certificadoDeposito2 === '' }
                                      label={'enviar'}
                                      onSend={ () => {
                                        const metadata = JSON.stringify({"solicitudId": Number(instanciaProceso?.solicitud?.id)})
                                        console.log(metadata)
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

export default P12Solicitud
