import React from 'react'
import {Box, Grid, Stack, TextField} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {AccountCircle, Badge} from "@mui/icons-material";
import { MyReadOnlyAreaTextField } from 'src/components/MyReadOnlyAreaTextField'

export const P09RO = ({solicitud}) => {
  const solicitudId = solicitud.solicitudAprobada.id
  let payload = solicitud.solicitudAprobada.payload
  const abuelo = solicitud.wf0102.payload.solicitud
  return (
    <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Solicitud'} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Identificador'}
                             icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={payload.Solicitud.numeroSolicitud} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Fecha'}
                             value={payload.Solicitud.fecha} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Solicitante'} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField id='nombres'
                             label={'Nombres completos'}
                             value={abuelo.Solicitante?.nombresCompletos}
                             icon={<AccountCircle sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField id='cedula'
                             label={'Cédula/Pasaporte'}
                             value={abuelo.Solicitante?.cedula}
                             icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Recursos'}/>
      </Grid>
      <Grid item xs={12}>
        <MyReadOnlyTextField id={'centroDocumentacion'}
                             label={'Centro de documentacion'}
                             value={payload.Solicitud.centroDocumentacion} />
      </Grid>
      <Grid item xs={12}>
        <MyTableRecursos2 id={'recursos'}
                          canEdit={false}
                          formValues={payload.Solicitud}
                          mode={'WF09'} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Destino'} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField id={'ubicacionPais'}
                             label={'País'}
                             value={payload.Solicitud.ubicacionPais} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField id={'ubicacionInstitucion'}
                             label={'Institución'}
                             value={payload.Solicitud.ubicacionInstitucion} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Responsable del envío'} />
      </Grid>
      <Grid item xs={12} >
        <MyReadOnlyTextField id={'responsableEnvio'}
                             label={'Responsable envío'}
                             value={payload.Solicitud.responsableEnvio} />
      </Grid>
      <Grid item xs={4} >
        <MyReadOnlyTextField id={'responsableCedula'}
                             label={'Cedula'}
                             value={payload.Solicitud.responsableCedula} />
      </Grid>
      <Grid item xs={4} >
        <MyReadOnlyTextField id={'responsableContacto'}
                             label={'Número de teléfono'}
                             value={payload.Solicitud.responsableContacto} />
      </Grid>
      <Grid item xs={4} >
        <MyReadOnlyTextField id={'responsableCorreo'}
                             label={'Correo electrónico'}
                             value={payload.Solicitud.responsableCorreo} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Justificación de la salida del holotipo'} />
      </Grid>
      <Grid item xs={4} >
        <MyReadOnlyTextField id={'fechaRetorno'}
                             label={'Fecha de retormo'}
                             value={payload.Solicitud.fechaRetorno} />
      </Grid>
      <Grid item xs={12}>
        <MyReadOnlyAreaTextField id='justificacion'
                                 label={'Justificación de la salida'}
                                 value={payload.Solicitud.justificacion} />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 0'}}>
          <MyUpload id={'certificadoDeposito'}
                    label={'Certificado de depósito'}
                    dir={solicitudId}
                    canEdit={false}
                    formValues={payload.Solicitud} />
          <Box sx={{p:'3rem 0 0 0'}}>
            <MySwitch id={'integridadHolotipo'}
                      label={'¿Se garantiza la integridad del holotipo en cuestión y su retorno en óptimas condiciones después del tiempo de salida establecido?'}
                      canEdit={false}
                      formValues={payload.Solicitud} />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Declaración de veracidad de la información'}/>
      </Grid>
      <Grid item xs={12}>
        <TextField id='texto'
                   multiline
                   rows={10}
                   value={payload.Solicitud.texto}
                   fullWidth
                   variant='standard'
                   aria-readonly={true}
                   sx={dialog.textTypography}
                   InputProps={{
                     disableUnderline: true,
                     sx: {
                       fontSize: '12px',
                       backgroundColor: 'transparent',
                     }
                   }}
                   InputLabelProps={{
                     sx: {
                       fontSize: '14px',
                     }
                   }} />
      </Grid>
      <Grid item xs={12} sx={{pr:'2rem'}}>
        <MySwitch id={'si'}
                  label={''}
                  canEdit={false}
                  formValues={payload.Solicitud} />
      </Grid>
    </Grid>
  )
}
