import React from 'react'
import {useDispatch} from "react-redux"
import {Box} from '@mui/system'
import {accordeonBox} from '../../styles/styles'
import AppBar from '@mui/material/AppBar'
import {SectionTitle} from 'src/components/SectionTitle'
import {
  API
} from 'src/features/P03'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  CssBaseline,
  Grid,
  Stack
} from '@mui/material'
import {f} from 'src/commons'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  handleClear,
} from 'src/features/App/sliceApp'

export const WF03SolicitudRO = ({payload}) => {
  const dispatch = useDispatch()
  const sections = API.sections
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '')
  }

  if(f.isValid(payload)) {
    return (
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
        <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de contrato de acceso con fines comerciales'} />
        </AppBar>
        {
          sections.map((section, idx) => (
            <Accordion disableGutters
                       elevation={0}
                       key={`panel${idx+1}`}
                       expanded={expanded === `panel${idx+1}`}
                       onChange={handleChange(`panel${idx+1}`)}
                       sx={{backgroundColor:'#F4FFF4'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${idx + 1}bh-content`}
                                id={`panel${idx + 1}bh-header`}
                                sx={accordeonBox.titleBox2}>
                <Typography sx={accordeonBox.titleTypography2}>
                  {section.sectionTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{pl:'52px'}}>{section.f(payload)}</AccordionDetails>
            </Accordion>
          ))
        }
        <CssBaseline />
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyButtonBacan label={'Regresar'}
                                 icon={ArrowBackIcon}
                                 onClick={() => {
                                   dispatch(handleClear())
                                 }} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  } else {
    return null
  }
}
