import React from 'react'
import Box from '@mui/material/Box'
import {
  FormControlLabel,
  Grid, Radio, RadioGroup,
  Stack, Typography,
} from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {dialog} from 'src/styles/styles'
import ApartmentIcon from '@mui/icons-material/Apartment'
import {MyUpload} from 'src/components/MyUpload'
import {MySubtitle} from 'src/components/MySubtitle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {MyReadOnlyTextField} from '../../../components/MyReadOnlyTextField'
import {MyReadOnlyAreaTextField} from '../../../components/MyReadOnlyAreaTextField'
import ScienceIcon from '@mui/icons-material/Science'
import BiotechIcon from '@mui/icons-material/Biotech'
import FormControl from "@mui/material/FormControl";

export const PropuestaRO = ({payload}) => {
  const section = 'Propuesta'
  const propuesta = payload[section]
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud'} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyAreaTextField value={propuesta.nombre}
                                   label={'Nombre del Proyecto *'}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={8}>
          <MyReadOnlyAreaTextField value={propuesta.patrocinador}
                                   label={'Identificación de la Institución Patrocinadora *'}
                                   icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField value={`US$ ${propuesta.montFinancimiento}`}
                                   label={'Monto de Financiamiento Aproximado'} />
        </Grid>

        <Grid item xs={8}>
          <MyReadOnlyAreaTextField label={'Nombre del representante legal'}
                       value={propuesta.nombreRepLegalPatrocidador}
                       icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField value={propuesta.cargoRepLegalPatrocidador}
                       label={'Cargo'}
                       icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12}>
            <MyUpload id={'cartaPatrocidador'}
                      dir={payload.solicitudId}
                      label={'Carta'}
                      formValues={propuesta}
                      canEdit={false}
                      info={'info...'} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyTextField value={propuesta.apoyo}
                               label={'Identificación de la Institución Nacional de Apoyo'}
                               icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={8}>
          <MyReadOnlyTextField value={propuesta.nombreRepLegalApoyo}
                               label={'Nombre del representante legal'}
                               icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={4}>
          <MyReadOnlyTextField value={propuesta.cargoRepLegalApoyo}
                       label={'Cargo'}
                       icon={<ApartmentIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12} >
          <Stack direction={"row"} spacing={2} justifyContent="space-between" alignItems="center">
            <MyUpload id={'cartaApoyo'}
                      label={'Carta'}
                      dir={payload.solicitudId}
                      formValues={propuesta}
                      canEdit={false}
                      info={'info...'} />
            <MyUpload id={'convenioApoyo'}
                      dir={payload.solicitudId}
                      label={'Convenio'}
                      canEdit={false}
                      formValues={propuesta}
                      info={'info...'}/>
          </Stack>
        </Grid>
        <Grid item xs={12} >
          <Stack direction={"row"} spacing={2} justifyContent="flex-end" alignItems="center">
            <MyUpload id={'repLegalApoyo'}
                      dir={payload.solicitudId}
                      label={'Nombramiento Representante Legal'}
                      canEdit={false}
                      formValues={propuesta}
                      info={'info...'}/>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyAreaTextField value={propuesta.resumen}
                                   label={'Resumen Ejecutivo *'}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyAreaTextField id='objetivo'
                                   label={'Objetivo General *'}
                                   value={propuesta.objetivo}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>Objetivos Específicos</Typography>
          {propuesta.objetivos.map((it, idx) => {
            return <Typography key={`oe${idx}`} sx={{fontSize:'0.8rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>
          })}
        </Grid>
        <Grid item xs={3}>
          <MyReadOnlyTextField id='plazo'
                               label={'Plazo de ejecución'}
                               value={propuesta.plazo + ' meses'}
                               icon={<AccessTimeIcon sx={dialog.textFieldIcon}/>}
                               isNumber={true} />
        </Grid>
        <Grid item xs={9}>
          <MyReadOnlyTextField id='areaInvestigacion'
                               label={'Área de Investigación'}
                               icon={<BiotechIcon sx={dialog.textFieldIcon}/>}
                               value={propuesta.areaInvestigacion} />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          <MyReadOnlyTextField id='lineaInvestigacion'
                               label={'Línea de Investigación'}
                               icon={<ScienceIcon sx={dialog.textFieldIcon}/>}
                               value={propuesta.lineaInvestigacion} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyAreaTextField id='definicionProblema'
                                   label={'Definición del Problema'}
                                   value={propuesta.definicionProblema}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        <Grid item xs={12}>
          <MyReadOnlyAreaTextField id='justificacion'
                                   label={'Justificación'}
                                   value={propuesta.justificacion}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <RadioGroup row
                        aria-labelledby="recoleccion-recurso"
                        name="recoleccion-recurso-rg"
                        id='recoleccionDeRecursos'
                        value={propuesta.recoleccionDeRecursos}
            >
              <FormControlLabel value="in-situ" control={<Radio />} label="Recurso a ser recolectado (in situ)?" />
              <FormControlLabel value="ex-situ" control={<Radio />} label="Recurso depositado previamente (ex situ)" />
              <FormControlLabel value="in-ex-situ" control={<Radio />} label="Ambos, in situ y ex situ" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {['in-situ', 'in-ex-situ'].includes(propuesta['recoleccionDeRecursos']) &&
          <Grid item xs={12}>
            <MyReadOnlyAreaTextField id='metodologia'
                                     label={'Metodología de campo'}
                                     value={propuesta.metodologia}
                                     icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}/>
          </Grid>
        }

        {['ex-situ', 'in-ex-situ'].includes(propuesta['recoleccionDeRecursos']) &&
          <Grid item xs={12}>
            <MyReadOnlyAreaTextField id='metodologiaLaboratorio'
                                     label={'Metodología de laboratorio'}
                                     value={propuesta.metodologiaLaboratorio}
                                     icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}/>
          </Grid>
        }

        <Grid item xs={12}>
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>Resultados Esperados</Typography>
          {propuesta.resultadosEsperados.map((it, idx) => {
            return <Typography key={`re${idx}`} sx={{fontSize:'0.875rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>
          })}
        </Grid>
      </Grid>
    </Box>
  )
}
