import React, {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import {useSelector} from 'react-redux'
import {SolicitanteTitle} from 'src/features/App/consts'
import {
  Badge,
  SelfImprovement,
  Public,
  CoPresent,
  Smartphone,
  PhoneInTalk,
  Email,
  LocationOn,
} from '@mui/icons-material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyTextField} from 'src/components/MyTextField'
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import {ReactComponent as Png21Icon } from 'src/styles/MySvgIcons/png-21-min.svg'
import {rulesFor} from 'src/features/P01Solicitud/CONF'
import {f} from 'src/commons/f'
import {MyMaskedTextField} from 'src/components/MyMaskedTextField'

const Solicitante = ({usuarioSenescyt, payload, mainFormValues, setMainFormValues, incrementCounter, idTask, formErrors}) => {
  const section = 'Solicitante'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const bandeja = useSelector(state => state.app.bandeja)
  const emptyPayload = rulesFor(section)?.emptyPayload()
  const initPayload = {
    nombresCompletos: usuarioSenescyt?.nombresCompletos,
    cedula:           usuarioSenescyt?.cedula,
    genero:           usuarioSenescyt?.genero,
    etnia:            usuarioSenescyt?.etnia,
    nacionalidad:     usuarioSenescyt?.nacionalidad,
    registro:         usuarioSenescyt?.registro,
    celular:          usuarioSenescyt?.celular,
    telefono:         usuarioSenescyt?.telefono,
    email:            usuarioSenescyt?.email,
    direccion:        usuarioSenescyt?.direccion,
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...initPayload, ...payload[section]})
  useEffect(() => {
    setMainFormValues(prev => ({...prev, [section]:formValues}))
    incrementCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formValues])
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange(e, bandeja, setFormValues, formValues)
  }
  return(
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Identificación'} />
      </Grid>
      <Grid item xs={6}  >
        <MyReadOnlyTextField value={formValues?.nombresCompletos}
                             label={'Nombres Completos'}
                             icon={<Png21Icon style={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues?.cedula}
                             label={SolicitanteTitle.cedula}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues?.genero}
                             label={SolicitanteTitle.genero}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues?.etnia}
                             label={'Etnia'}
                             icon={<SelfImprovement sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues?.nacionalidad}
                             label={SolicitanteTitle.nacionalidad}
                             icon={<Public sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues?.registro}
                             label={'Número de Registro / Acreditación'}
                             icon={<CoPresent sx={dialog.readOnlyIcon}/>} />
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Contacto'} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'celular'}
                           label={'Celular'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['celular']}
                           canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'telefono'}
                           label={'Teléfono del Domicilio'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={formErrors[section]['telefono']}
                           canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'email'}
                     label={'Correo Electrónico'}
                     formValues={formValues}
                     setFormValues={setFormValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={formErrors[section]['email']}
                     canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'direccion'}
                     label={'Dirección del Domicilio'}
                     formValues={formValues}
                     setFormValues={setFormValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={formErrors[section]['direccion']}
                     canEdit={canEdit && (idTask !== '55000016_Activity_ElaborarSolicitudWF0405')}
                     handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default Solicitante
