import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid, Stack,
} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyCentroDocumentacion} from "../../../components/MyCentroDocumentacion";
import AddIcon from "@mui/icons-material/Add";
import {MyButtonBacan2} from "../../../components/MyButtonBacan2";
import {f} from "../../../commons";
import API from "../../App/API";
import {MyMultipleSelect} from "../../../components/MyMultipleSelect";

export const RecursosExSitu = ({section, formValues, setFormValues, canEdit, formErrors, mode}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const laboratorios = useSelector(state => state.app.laboratorios)
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionValues])
  return(
    <Box >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Centros de documentación'} />
        </Grid>
        {
          sectionValues.centrosDocumentacion?.map((cd, idx) => {
            return (
              <Grid key={cd.id} item xs={12}>
                <MyCentroDocumentacion id={cd.id}
                                       key={`cd${cd.id}`}
                                       canEdit={canEdit}
                                       formValues={sectionValues}
                                       numeroSolicitud={formValues.INFO.solicitudId}
                                       setFormValues={(id, centro) => {// temporal
                                         const idx = sectionValues.centrosDocumentacion.findIndex(it => it.id === id)
                                         sectionValues.centrosDocumentacion[idx] = {...centro}
                                         setSectionValues({...sectionValues })
                                       }}
                                       mode={mode}
                                       handleDelete={(id) => {
                                         setSectionValues({...sectionValues, centrosDocumentacion: sectionValues.centrosDocumentacion.filter(it => it.id !== id && it.id !== -99)})
                                       }} />
              </Grid>
            )
          })
        }
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent='flex-end' alignItems='center' spacing={2} sx={{p:'0 1rem 0 0 '}}>
            <MyButtonBacan2 onClick={() => {
                              const noCentros = sectionValues.centrosDocumentacion.length === 0
                              const myMax = noCentros?1:Math.max(...sectionValues.centrosDocumentacion.map(it => it.id))
                              const cds = [...sectionValues.centrosDocumentacion, {id:myMax+1}]
                              setSectionValues({...sectionValues, centrosDocumentacion: cds})
                            }}
                            icon={AddIcon}
                            label={'Agregar Centro de Documentación'} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino de los recursos'} />
        </Grid>
        <Grid item xs={12} >
          <MyMultipleSelect id={'laboratorios'}
                            label={'Institución en la que se desarrollará la fase de laboratorio *'}
                            data={[...laboratorios].sort()}
                            setFormValues={setSectionValues}
                            formValues={sectionValues}
                            canEdit={canEdit}
                            handleChange={handleChange} />
        </Grid>
      </Grid>
    </Box>
  )
}
