import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import {InputAdornment, TextField} from '@mui/material'
import {dialog} from 'src/styles/styles'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {f} from 'src/commons/f'

export const MySelect = ({
                           id,
                           label,
                           icon,
                           value=null,
                           error='',
                           formValues,
                           setFormValues,
                           data,
                           handleChange,
                           canEdit=true,
                           onChange=null,
                           defaultValue=''}
) => {
  let options = [];
  data?.forEach((value, idx) => {
      options.push(
        <MenuItem
          key={idx}
          value={value}>
          {value}
        </MenuItem>);
  })

  const onChangeEvt = (e)=> {
    if(canEdit)
      setFormValues({...formValues, [id]: e.target.value})
  }

  const type = 'text'
  const compulsive = (type === 'text') && canEdit && (label?.length > 0) && (label?.at(label.length-1) === '*')
  const borderStyle = ((compulsive && (formValues[id] === '')) || f.isValidNotEmpty(error))? {border: '2px solid red'} : {border: '1px dotted #cccccc'}
  const error2 = (compulsive && (formValues[id] === '') && error !== '') ? `Campo "${label}" es obligatorio.`:""
  const error1 = f.isValidNotEmpty(error)?` - ${error}`:(error2.length>0)?(` - ${error2}`):""
  return (
    <Box>
      <TextField id={id}
                 select
                 label={label}
                 value={
                  f.isValid(value)?value:
                    (data.indexOf(formValues[id]) >= 0)?formValues[id]:
                      f.isValid(defaultValue)?defaultValue:data[0]
                 }
                 fullWidth
                 variant='standard'
                 aria-readonly={true}
                 sx={{...dialog.textTypography, m:'1rem 0 1rem 0', minWidth:'10rem'}}
                 onChange={(onChange===null)?onChangeEvt:onChange}
                 // onChange={handleChange}
                 InputProps={{
                   readOnly:!canEdit,
                   disableUnderline: true,
                   form: {autocomplete: 'off'},
                   startAdornment:(
                     <InputAdornment position="start">
                       {icon}
                     </InputAdornment>
                   ),
                   sx: {
                     fontSize: '0.9rem',
                     fontfamily: RobotoCondensedRegular,
                     color: '#888888',
                     backgroundColor: (canEdit===true)?'white':'transparent',
                     ...borderStyle
                   }
                 }}
                 InputLabelProps={{ sx: {
                     fontSize: '1.2rem',
                     color: '#888888',
                     fontfamily: RobotoCondensedRegular,
                     padding: '-24px',
                     shrink: true,
                   }}}
                 FormHelperTextProps={{sx: {
                     fontSize: '0.7rem',
                     fontWeight: 'bold',
                     fontfamily: RobotoCondensedRegular,
                     lineHeight: '1.2rem',
                     color:'darkred',
                     pl:'1rem'}}}
                 helperText={error1} >
        {options}
      </TextField>
    </Box>
  )
}
