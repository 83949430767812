import GLOBALS from 'src/features/App/globals'
import Swal from 'sweetalert2';
import "../chat/swalestilo.css"
import { abrirModalRestaurar, cargarInvestigador, cargarRoles, cerrarModalUsuario, seleccionarInstitucion } from './sliceUsuarios';

const API = { sliceName: 'usuarios' }

export const verificarInvestigadorRegistro = (info) => {
    return async (dispatch) => {

        const resp = await fetchInvestigadorRegistro(info);

        if (resp.status !== 204) {
           dispatch(dialogoConfirmacion('Investigador verificado correctamente'));
             const body = await resp.json();
            dispatch(cargarInvestigador(body));
        } else {
            console.log("no existe investigador")
            dispatch(dialogoError("El Investigador con registro: " + info + " no se encuentra registrado"))
            dispatch(cargarInvestigador(null));
        }
    }
}

export const verificarInvestigadorCedula = (info) => {
    return async (dispatch) => {

        const resp = await fetchInvestigadorCedula(info);

        if (resp.status !== 204) {
            dispatch(dialogoConfirmacion('Investigador verificado correctamente'));
             const body = await resp.json();
            dispatch(cargarInvestigador(body));
        } else {
            console.log("no existe investigador")
            dispatch(dialogoError("El Investigador con cédula: " + info + " no se encuentra registrado"))
            dispatch(cargarInvestigador(null));
        }
    }
}

export const verificarInvestigadorCorreo = (info) => {
    return async (dispatch) => {

        const resp = await fetchInvestigadorCorreo(info);

        if (resp.status !== 204) {
            dispatch(dialogoConfirmacion('Investigador verificado correctamente'));
             const body = await resp.json();
            dispatch(cargarInvestigador(body));
        } else {
            console.log("no existe investigador")
            dispatch(dialogoError("El Investigador con correo: " + info + " no se encuentra registrado"))
            dispatch(cargarInvestigador(null));
        }
    }
}


export const verificarInvestigador = (info) => {
    return async (dispatch) => {

        const resp = await fetchInvestigador(info);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Investigador verificado correctamente',
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    container: 'my-swal'
                }
            })
            dispatch(cargarInvestigador(body));
        } else {
            console.log("no existe investigador")
            Swal.fire({
                position: "center",
                icon: 'error',
                title: "Error",
                html: "No se puede verificar al Investigador",
                showConfirmButton: true,
                customClass: {
                    container: 'my-swal'
                }
            })
        }
    }
}

export const retornaRoles = (organizacion) => {
    return async (dispatch) => {

        const resp = await fetchRoles(organizacion);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarRoles(body));
        } else {
            dispatch(cargarRoles([]));
            console.log("no existe roles")
        }
    }
}

export const guardarCuenta = (valores) => {

    return async (dispatch) => {

        try {
            const resp = await fetchSetCuenta(valores);
            const body = await resp.json();

            // console.log("body 1", body)

            if (Object.keys(body).length !== 0) {

                // const resp2 = await fetchSetPassword(body.id);
                // const body2 = await resp2.json();

                // console.log("body 2", body2)

                // if (Object.keys(body2).length !== 0) {

                const resp3 = await fetchSetPerfil(body.id, valores.rolId);
                const body3 = await resp3.json();

                // console.log("body 3", body3)

                if (Object.keys(body3).length !== 0) {
                    dispatch(cerrarModalUsuario());
                    Swal.fire({
                        position: "center",
                        icon: 'success',
                        title: `Se ha creado el usuario: ${valores.username}`,
                        // html: "Su cuenta se ha creado con el password: 123.abc.",
                        showConfirmButton: true,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                } else {
                    Swal.fire({
                        position: "center",
                        icon: 'error',
                        title: "Error",
                        html: "Error al asignar el perfil a la cuenta",
                        showConfirmButton: true,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                }

                // } else {
                //     Swal.fire({
                //         position: "center",
                //         icon: 'error',
                //         title: "Error",
                //         html: "Error al crear el password de la cuenta",
                //         showConfirmButton: true,
                //         customClass: {
                //             container: 'my-swal'
                //         }
                //     })
                // }
            } else {
                Swal.fire({
                    position: "center",
                    icon: 'error',
                    title: "Error",
                    html: "Error al crear la cuenta",
                    showConfirmButton: true,
                    customClass: {
                        container: 'my-swal'
                    }
                })
            }

        } catch (error) {
            console.log("error al guardar la cuenta", error)
        }

    }
}

export const cargarOrganizacion = (organizacion) => {
    return async (dispatch) => {

        const resp = await fetchOrganizacion(organizacion);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(seleccionarInstitucion(body));
        } else {
            console.log("Error al cargar la institucion")
        }
    }
}

export const enviarCorreoRecuperacion = (correo) => {
    return async (dispatch) => {

        const resp = await sendCorreo(correo);

        if (resp.status === 200) {
            const body = await resp.json();

            if (body.resultado) {
                dispatch(dialogoConfirmacion("Correo enviado correctamente"))
                dispatch(abrirModalRestaurar(false))
            } else {
                dispatch(dialogoError("Error al enviar el correo"))
            }
        } else {
            dispatch(dialogoError("Error al enviar el correo"))
        }
    }
}

const dialogoConfirmacion = (texto) => {
    return () => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: texto || '',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const dialogoError = (texto) => {
    return () => {
        Swal.fire({
            position: "center",
            icon: 'error',
            title: "Error",
            html: texto || '',
            showConfirmButton: true,
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const fetchInvestigadorRegistro = (registro) => {
    const url = `${GLOBALS.investigadoresWS}/registro/${registro}`
    return fetch(url)
}

const fetchInvestigadorCedula = (cedula) => {
    const url = `${GLOBALS.investigadoresWS}/cedula/${cedula}`
    return fetch(url)
}

const fetchInvestigadorCorreo = (correo) => {
    const url = `${GLOBALS.investigadoresWS}/email/${correo}`
    return fetch(url)
}

const fetchInvestigador = (cedulaOcorreo) => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getemp?cedulaOcorreo=${cedulaOcorreo}`
    return fetch(url)
}

const fetchRoles = (organizacion) => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfil/get?idorg=${organizacion}`
    return fetch(url)
}

const fetchSetCuenta = (valores, method = 'POST') => {

    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/setemp`

    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "codigoUsuario": `${valores.codigo}`,
            "nombreUsuario": `${valores.nombre}`,
            "correoUsuario": `${valores.mail}`,
            "cedulaUsuario": `${valores.cedula}`,
            "pasaporteUsuario": `${valores.pasaporte}`,
            "usernameUsuario": `${valores.username}`,
            "cargoUsuario": `${valores.cargo}`,
            "telefonoUsuario": '',
            "tipo": `${valores.tipo}`,
            "estadoUsuario" : "Inactivo",
            "organizacion": {
                "id": `${valores.institucion}`,
                "codigoOrganizacion": `${valores.codigoOrganizacion}`,
                "nombreOrganizacion": `${valores.nombreOrganizacion}`,
                "descripcionOrganizacion": `${valores.descripcionOrganizacion}`,
                "ctlRefs": `${0}`
            },
            "ctlRefs": `${0}`
        })
    })
}

// const fetchSetPassword = (id, method = 'POST') => {
//     const url = `${GLOBALS.mainUrl}/api/v1/usuarios/setpsw?empId=${id}&passwd1=${'123.abc.'}&passwd2=${'123.abc.'}`
//     return fetch(url, {
//         method,
//         headers: {
//             'Content-type': 'application/json'
//         },
//         // body: JSON.stringify({
//
//         // })
//     })
// }

const fetchSetPerfil = (id, perfil, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfilusuario/set?idusuario=${id}&idperfil=${perfil}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchOrganizacion = (id, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getorg?idOrganizacion=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const sendCorreo = (correo, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/reset?correo=${correo}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}


export default API
