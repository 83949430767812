import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import {accordeonBox} from 'src/styles/styles'
import {MyTablePersonal} from 'src/components/MyTablePersonal'
import {MyTableExperiencia} from 'src/components/MyTableExperiencia'
import Typography from '@mui/material/Typography'
import {f} from "../../../commons";
import {MyTypography} from "../../../components/MyTypography";

const emptyItem = {
  cedula:         '',
  nombre:         '',
  pasaporte:      '',
  gradoAcademigo: '',
  institucion:    '',
  celular:        '',
  correo:         '',
  notificar:      false
}

const emptySubItem = {
  proyecto:       '',
  funcion:        '',
  inicio:         '',
  fin:            '',
  experiencia:    '',
}

export const Personal = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionValues])

  useEffect(() => {
    const personal = sectionValues.personal
    if(Array.isArray(personal)) {
      if(personal.filter(it => it.cedula === formValues.Solicitante?.cedula).length === 0) {
        const row = {
          cedula:         formValues?.Solicitante?.cedula,
          celular:        formValues?.Solicitante?.celular,
          correo:         formValues?.Solicitante?.email,
          nombre:         formValues?.Solicitante?.nombresCompletos,
          pais:           '',
          notificar:      true,
          gradoAcademigo: '',
          cargoProyecto:  '',
          pasaporte:      '',
          institucion:    '',
        }
        setSectionValues({...sectionValues, personal: [...sectionValues.personal, row]})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [errPersonal, setErrPersonal] = useState('')
  const [errExperiencia, setErrExperiencia] = useState('')
  useEffect(() => {
    setErrPersonal(f.extractValues(formErrors[section]).filter(it => it.startsWith('Personal,')).map(it => it.substring(10)).join(' - '))
    setErrExperiencia(f.extractValues(formErrors[section]).filter(it => it.startsWith('Experiencias,')).map(it => it.substring(13)).join(' - '))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors[section]])

  const [selected, setSelected] = useState({})
  return(
    <>
      <Box sx={accordeonBox.container}>
        <MyTablePersonal id={'personal'}
                         canEdit={canEdit}
                         formValues={sectionValues}
                         setFormValues={setSectionValues}
                         addItem={() => {
                           setSectionValues({...sectionValues,personal: [...sectionValues.personal, {...emptyItem}]})
                         }}
                         canDeleteRow={canEdit}
                         selected={selected}
                         idSolicitud={formValues.INFO.solicitudId}
                         setSelected={setSelected} />
      </Box>
      {
        (errPersonal !== '') ? <MyTypography sx={{color:'red'}}>{errPersonal}</MyTypography> : false
      }
      {
        (selected.id !== undefined && selected.cedula !== '') ?
          <Box sx={accordeonBox.container}>
            <Typography>{`Experiencia de ${selected.nombre}, relevante al proyecto que se está solicitando`}</Typography>
            <MyTableExperiencia id={'experiencia'}
                                canEdit={canEdit}
                                formValues={sectionValues}
                                setFormValues={setSectionValues}
                                addItem={() => {
                                  setSectionValues({...sectionValues,experiencia: [...sectionValues.experiencia, {...emptySubItem, cedula: selected.cedula}]})
                                }}
                                canDeleteRow={canEdit}
                                selected={selected}/>
            {
              (errExperiencia !== '')?<MyTypography sx={{color:'red'}}>{errExperiencia}</MyTypography> : false
            }
          </Box>:null
      }
    </>
  )
}
