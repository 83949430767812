import React from 'react'
import {dialog} from 'src/styles/styles'
import {Autocomplete, InputAdornment, TextField} from '@mui/material'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {f} from "../commons";

export const MyAutocompleteTextField = ({
                                          options,
                                          id,
                                          label,
                                          formValues,
                                          setFormValues,
                                          icon,
                                          error='',
                                          note='',
                                          canEdit,
                                          onChangehandleEvent=null
                                       }) => {
  const compulsive = canEdit && (label?.length > 0) && (label?.at(label.length-1) === '*')
  const borderStyle = ((compulsive && (formValues[id] === '')) || f.isValidNotEmpty(error))? {border: '2px solid red'} : {border: '1px dotted #cccccc'}
  const error2 = (compulsive && (formValues[id] === '') && (error === '')) ? `Campo "${label}" es obligatorio.`:""
  const error1 = f.isValidNotEmpty(error)?` - ${error}`:(error2.length>0)?(` - ${error2}`):""
  return (
    <Autocomplete freeSolo
                  readOnly={!canEdit}
                  value={formValues[id]}
                  id={id}
                  disableClearable
                  options={options}
                  onChange={f.isValid(onChangehandleEvent)?onChangehandleEvent:(e,v) => {
                    if(canEdit) {
                      setFormValues({...formValues, [id]: v})
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                               id={id}
                               label={label}
                               value={f.isValid(formValues[id])?formValues[id]:''}
                               onChange={(e) => {
                                 if(canEdit) {
                                   const id = e.target.id
                                   const v = e.target.value
                                   setFormValues({...formValues, [id]: v})
                                 }
                               }}
                               fullWidth
                               variant='standard'
                               aria-readonly={true}
                               sx={
                                 {...dialog.textTypography, m:'1rem 0 1rem 0'}
                               }
                               InputProps={{
                                 ...params.InputProps,
                                 readOnly: !canEdit,
                                 type: 'search',
                                 disableUnderline: true,
                                 startAdornment:(
                                   <InputAdornment position='start'>
                                     {icon}
                                   </InputAdornment>
                                 ),
                                 sx: {
                                   fontSize: '0.8rem',
                                   fontfamily: RobotoCondensedRegular,
                                   backgroundColor: (canEdit===true)?'white':'transparent',
                                   ...borderStyle,
                                 }
                               }}
                               InputLabelProps={{sx: {
                                   fontSize: '1.2rem',
                                   fontfamily: RobotoCondensedRegular,
                                 }}}
                               FormHelperTextProps={{sx: {
                                   fontSize: '0.7rem',
                                   fontfamily: RobotoCondensedRegular,
                                   color:(error1!== '') ?'darkred':'black',
                                   fontWeight: (error1!== '') ?'bolder':'normal',
                                   pl:'1rem'}}}
                               helperText={(error1!== '') ? error1 : note} />
                  )}  />
  )
}
