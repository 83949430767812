import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {f} from "../../commons"
 import P01PermisoInvestigacion from 'src/features/P01Solicitud/P01PermisoInvestigacion'
import P01SolicitudAsignacionCaso from 'src/features/P01Solicitud/P01SolicitudAsignacionCaso'
import P01SolicitudVerificarCumplimiento from 'src/features/P01Solicitud/P01SolicitudVerificarCumplimiento'
import P01SolicitudInformeTecnico from 'src/features/P01Solicitud/P01SolicitudInformeTecnico'
import P01SolicitudDictamenTecnicoSENADI from "./P01SolicitudElaborarDictamenTecnicoSENADI"
import P01SolicitudInformeTecnicoMAATE from 'src/features/P01Solicitud/P01SolicitudInformeTecnicoMAATE'
import P01SolicitudDictamenTecnico from "src/features/P01Solicitud/P01SolicitudDictamenTecnico"
import P01SolicitudProyectoResolucion from "src/features/P01Solicitud/P01SolicitudProyectoResolucion"
import P01SolicitudResolucion from 'src/features/P01Solicitud/P01SolicitudResolucion'
import P01SolicitudElaborarPermiso from 'src/features/P01Solicitud/P01SolicitudElaborarPermiso'
import P02ElaborarContrato from "src/features/P01Solicitud/P02SolicitudElaborarContrato"
import P01SolicitudSuscribirPermiso from "./P01SolicitudSuscribirPermiso"
import P0405SolicitudInformeTecnicoSenescytMod from "./P0405SolicitudInformeTecnicoSenescytMod"
import P02RevisarContrato from "./P02RevisarContrato"
import P02RegistrarContrato from "./P02RegistrarContrato"
import P02RegistroInternacional from "./P02RegistroInternacional"
import P01SolicitudEmitirDictamenTecnico from 'src/features/P01Solicitud/P01SolicitudEmitirDictamenTecnicoSENADI'

/**
 * Función para renderizar un componente de widget específico en función de la clave proporcionada.
 *
 * El código que compartiste es principalmente una función llamada 'tarea_widget'. De acuerdo al comentario en la parte superior de la función, entiendo que es una función para renderizar un componente específico de widget basado en una clave proporcionada.
 * La función 'tarea_widget' toma un parámetro de tipo string llamado 'key' y luego usa una sentencia 'switch' para comparar la 'key' con diferentes casos. Si el parámetro 'key' coincide con alguno de los casos, entonces retornará un componente React específico asociado con ese caso. Si no hay coincidencias, la función retornará null por defecto.
 * Estos componentes React parecen estar asociados con ciertas actividades, como se indica en los ID que se les dan. Cada etiqueta del componente React tiene un atributo id que se establece en una cadena específica.
 * Sin un contexto más específico o las definiciones reales de los componentes de React a los que se hace referencia (como P01PermisoInvestigacion, P02RegistrarContrato, etc.), es difícil proporcionar más información detallada sobre lo que hace cada componente en específico. Sin embargo, el propósito general de la función tarea_widget es claro: renderizar diferentes componentes React de manera condicional basándose en la clave proporcionada.
 * Entre el código no relacionado, las importaciones de useNavigate y useSelector sugieren que el código utiliza react-router-dom para la navegación y react-redux para la administración del estado. Las variables sliceApp.reducer, f.isValid, as f y nombreTarea: tarea.nombreTarea se utilizan en algún contexto en la aplicación, pero es difícil entender su uso específico sin más información o viendo dónde y cómo se utilizan dentro de un contexto de código más amplio.
 *
 * @param clave {string}: la clave para determinar qué componente del widget se va a renderizar.
 * @returns {React.Component} El componente del widget correspondiente basado en la clave.
 */
const tarea_widget = (key) => {
  switch(key){
    case '55000014_Activity_ElaborarSolicitudWF0102':               return <P01PermisoInvestigacion id="55000001_Activity_ElaborarSolicitudWF01" />;
    case '55000015_Activity_AsignarCasoWF0102':                     return <P01SolicitudAsignacionCaso id="55000002_Activity_AsignarCaso"/>;
    case '55000015_Activity_VerificarCumplimientoRequisitosWF0102': return <P01SolicitudVerificarCumplimiento id="55000002_Activity_VerificarCumplimientoRequisitos" />;
    case '55000015_Activity_ElaborarInformeTecnicoSenadiWF0102':    return <P01SolicitudDictamenTecnicoSENADI id="55000002_Activity_VerificarCumplimientoRequisitos" />;
    case '55000015_Activity_EmitirDictamenTecnicoSenadiWF0102':     return <P01SolicitudEmitirDictamenTecnico id={"55000002_Activity_EmitirDictamenTecnicoSenadi"} />;
    case '55000015_Activity_ElaborarInformeTecnicoSenescytWF0102':  return <P01SolicitudInformeTecnico id={'55000002_Activity_ElaborarInformeTecnicoSenescyt'} />;
    case '55000015_Activity_AsignarCasoMaateWF0102':                return <P01SolicitudAsignacionCaso id="55000002_Activity_AsignarCasoMaate"/>;
    case '55000015_Activity_AsignarCasoSenadiWF0102':               return <P01SolicitudAsignacionCaso id="55000002_Activity_AsignarCasoSenadi"/>;
    case '55000015_Activity_ElaborarInformeTecnicoMaateWF0102':     return <P01SolicitudInformeTecnicoMAATE id={'55000002_Activity_ElaborarInformeTecnicoMaate'} />;
    case '55000015_Activity_EmitirDictamenTecnicoMaateWF0102':      return <P01SolicitudDictamenTecnico id={"55000002_Activity_EmitirDictamenTecnicoMaate"} />;
    case '55000015_Activity_ElaborarProyectoResolucionW0102':       return <P01SolicitudProyectoResolucion id={"55000002_Activity_ElaborarProyectoResolucion"} />;
    case '55000015_Activity_EmitirResolucionWF0102':                return <P01SolicitudResolucion id="55000002_Activity_EmitirResolucion" />;
    case '55000015_Activity_ElaborarPermisoWF0102':                 return <P01SolicitudElaborarPermiso id='55000002_Activity_ElaborarPermiso' />;
    case '55000015_Activity_ElaborarContratoWF0102':                return <P02ElaborarContrato id="55000015_Activity_ElaborarContratoWF0102" />;
    case '55000016_Activity_RevisarContratoWF0405':                 return <P02RevisarContrato id={'55000016_Activity_RevisarContratoWF0405'} />;
    case '55000017_Activity_RegistrarContratoWF0405':               return <P02RegistrarContrato id={'55000015_Activity_RegistrarContratoWF0102'} />;
    case '55000017_Activity_ElaborarContratoWF0405':                return <P02ElaborarContrato id="55000017_Activity_ElaborarContratoWF0405" />;
    case '55000015_Activity_SuscribirPermisoWF0102':                return <P01SolicitudSuscribirPermiso id='55000002_Activity_SuscribirPermiso' />;
    case '55000016_Activity_ElaborarSolicitudWF0405':               return <P01PermisoInvestigacion id="55000016_Activity_ElaborarSolicitudWF0405" />;
    case '55000017_Activity_AsignarCasoWF0405':                     return <P01SolicitudAsignacionCaso id="55000017_Activity_AsignarCasoWF0405"/>;
    case '55000017_Activity_VerificarCumplimientoRequisitosWF0405': return <P01SolicitudVerificarCumplimiento id="55000017_Activity_VerificarCumplimientoRequisitosWF0405" />;
    case '55000017_Activity_ElaborarInformeTecnicoSenescytWF0405':  return <P0405SolicitudInformeTecnicoSenescytMod id="55000017_Activity_ElaborarInformeTecnicoSenescytWF0405" />;
    case '55000017_Activity_AsignarCasoMaateWF0405':                return <P01SolicitudAsignacionCaso id="55000017_Activity_AsignarCasoMaateWF0405"/>;
    case '55000017_Activity_ElaborarInformeTecnicoMaateWF0405':     return <P01SolicitudInformeTecnicoMAATE id={'55000017_Activity_ElaborarInformeTecnicoMaateWF0405'} />;
    case '55000017_Activity_EmitirDictamenTecnicoMaateWF0405':      return <P01SolicitudDictamenTecnico id={"55000017_Activity_EmitirDictamenTecnicoMaateWF0405"} />;
    case '55000017_Activity_AsignarCasoSenadiWF0405':               return <P01SolicitudAsignacionCaso id="55000017_Activity_AsignarCasoSenadiWF0405"/>;
    case '55000017_Activity_ElaborarInformeTecnicoSenadiWF0405':    return <P01SolicitudDictamenTecnicoSENADI id={'55000017_Activity_ElaborarInformeTecnicoSenadiWF0405'} />;
    case '55000017_Activity_EmitirDictamenTecnicoSenadiWF0405':     return <P01SolicitudDictamenTecnico id={"55000017_Activity_EmitirDictamenTecnicoSenadiWF0405"} />;
    case '55000017_Activity_ElaborarProyectoResolucionW0102':       return <P01SolicitudProyectoResolucion id={"55000017_Activity_ElaborarProyectoResolucionW0102"} />;
    case '55000017_Activity_EmitirResolucionWF0405':                return <P01SolicitudResolucion id="55000017_Activity_EmitirResolucionWF0405" />;
    case '55000017_Activity_ElaborarPermisoWF0405':                 return <P01SolicitudElaborarPermiso id='55000017_Activity_ElaborarPermisoWF0405' />;
    case '55000017_Activity_SuscribirPermisoWF0405':                return <P01SolicitudSuscribirPermiso id='55000017_Activity_SuscribirPermisoWF0405' />;
    case '55000014_Activity_RevisarContratoWF0102':                 return <P02RevisarContrato id={'55000014_Activity_RevisarContratoWF0102'} />;
    case '55000015_Activity_RegistrarContratoWF0102':               return <P02RegistrarContrato id={'55000015_Activity_RegistrarContratoWF0102'} />;
    case '55000015_Activity_RegistrarCertificadoInternacionalWF0102': return <P02RegistroInternacional id={'55000015_Activity_RegistrarCertificadoInternacionalWF0102'} />;
    case '55000017_Activity_RegistrarCertificadoInternacionalWF0405': return <P02RegistroInternacional id={'55000017_Activity_RegistrarCertificadoInternacionalWF0405'} />;
    // Add the other cases by following the template above
    default: return null;
  }
};


/**
 * Componente de widget que representa un formulario dinámico para las diferentes tareas que conforman La solicitud de Investigación:
 *
 * El código proporcionado es un componente funcional de React construido utilizando las API de hooks de React y Redux para la gestión de estados. Veamos paso a paso:
 * El componente se llama Widget. Este componente está en un módulo que lo exporta como una exportación con nombre. El componente utiliza la sintaxis de función de flecha.
 * useNavigate es un hook personalizado utilizado para la navegación programática.
 * useSelector es un hook de la librería Redux utilizado para acceder al estado de la tienda Redux. Aquí se utiliza para acceder a taskInstance y processInstance del almacenamiento.
 * Los hooks personalizados useInstanceTaskValidity y useInstanceProcessValidity verifican si los ID de taskInstance y processInstance respectivamente son válidos.
 * isTaskInstanceValid e isProcessInstanceValid son variables que almacenan los resultados de las comprobaciones mencionadas anteriormente.
 * ERROR_MESSAGE y LOADING_MESSAGE son constantes que contienen mensajes que pueden mostrarse al usuario bajo ciertas condiciones.
 * Se usan dos hooks useEffect:
 * El primer hook useEffect navega a la ruta raíz si la instancia de tarea no es válida. Depende de taskInstance y navigate, y se activará cada vez que cambie alguno de estos.
 * El segundo hook useEffect se desplaza a la parte superior de la página cada vez que se monta el componente (gracias al array de dependencias vacío).
 * generateForm es una función que genera un formulario dependiendo del código de la instancia de la tarea. Si el formulario es válido, lo retorna, de lo contrario devuelve un div con un mensaje de error.
 * Finalmente, en la declaración de retorno, verifica si ambas instancias de tarea y proceso son válidas. Si lo son, genera el formulario. Si no lo son, se muestra un mensaje de carga al usuario.
 * Por lo tanto, el propósito principal del componente Widget parece ser generar un formulario basado en una instancia de tarea y proceso tomada de un estado gestionado por Redux. Si la instancia es válida, se muestra el formulario. Si no lo es, se muestra un mensaje de carga o un mensaje de error.
 * @returns {*|React.JSX.Element}
 * @constructor
 */
export const Widget = () => {
  const navigate = useNavigate()
  const taskInstance = useSelector(state => state.app.instanciaTarea)
  const processInstance = useSelector(state => state.app.instanciaProceso)

  const useInstanceTaskValidity = () => f.isValid(taskInstance?.id)
  const useInstanceProcessValidity = () => f.isValid(processInstance?.id)

  const isTaskInstanceValid = useInstanceTaskValidity()
  const isProcessInstanceValid = useInstanceProcessValidity()

  const ERROR_MESSAGE = `ERROR, EN FORMULARIO PARA TAREA ${taskInstance?.nombreTarea} .- (${taskInstance?.tareaCodigoTarea})`
  const LOADING_MESSAGE = `c a r g a n d o  d a t o s . . .`

  useEffect(()=> {
    if (!isTaskInstanceValid) navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskInstance, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const generateForm = () => {
    const form = tarea_widget(taskInstance?.tareaCodigoTarea)
    return (form !== null) ? form : <div>{ERROR_MESSAGE}</div>
  }

  return isTaskInstanceValid && isProcessInstanceValid ? generateForm() : <div>{LOADING_MESSAGE}</div>
}
