import {parse} from 'date-fns'
import {es} from 'date-fns/locale'

export const f = {}

f.extractValues = (obj) => Object.entries(obj).map(([k,v]) => (v))

f.defaultString = (anyThing, defaultValue) => (anyThing === null || anyThing === undefined || (typeof anyThing === 'string' && anyThing === ''))?defaultValue:anyThing;

f.isArray = (anyThing) => Object.prototype.toString.call(anyThing) === '[object Array]'

f.isString = (anyThing) => typeof anyThing === 'string'

f.isValid = (anyThing) => (anyThing !== undefined) && (anyThing !== null)

f.isValidNotEmpty = (anyThing) => (anyThing !== undefined) && (anyThing !== null) && (anyThing !== '')  && (anyThing !== [])

f.isInvalid = (anyThing) => (anyThing === undefined) || (anyThing === null)

f.isObject = (anyThing) => Object.prototype.toString.call(anyThing) === '[object Object]'

f.whatIs = (anyThing) => Object.prototype.toString.call(anyThing).split(/\W/)[2]

f.numberInRange = (anyThing, rangeMin, rangeMax) => {
  const isNumber = typeof anyThing === 'number' && isFinite(anyThing)
  if(isNumber) {
    if(anyThing > rangeMax) return rangeMax
    else if(anyThing < rangeMin) return rangeMin
    else return anyThing
  } else {
    return rangeMin
  }
}

f.isPhone = (anything) => {
  const regex = new RegExp(/^\+?[(]?[0-9]{0,3}[)]?[-\s\.]?[0-9]{0,3}[-\s\.]?[0-9]{0,6}$/im)
  return regex.test(anything)
}

f.isMail = (anything) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(anything)
}

f.isUrn = (anything) => {
  return /^urn:[a-zA-Z0-9][a-zA-Z0-9-]{0,31}:[a-zA-Z0-9()+,\-.:=@;$_!*'%/?#]+$/.test(anything)
}

f.isUrl = (anything) => {
  return /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)(\/[\w\-.,@?^=%&:\/~+#]*)?$/.test(anything)
}


f.numberToString = (anything) => (typeof anything === 'number')?`${anything}`:anything

f.StringOrEmpty = (anything) => (typeof anything === 'string')?anything:(typeof anything === 'number')?`${anything}`:''

f.string2json = (anything, alt) => {
  if((typeof anything) !== 'undefined') {
    try {
      return JSON.parse(anything)
    } catch (e) {
      return JSON.parse(alt)
    }
  } else
    return JSON.parse(alt)
}

f.delay = (time=1000) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

f.parseDate = (strDate, format='dd-MMMM-yyyy') => {
  return parse(strDate, format, new Date(), {locale: es})
}

f.newId = (arr = []) => {
  const arrIds = arr.map(it => it.id)
  if(arrIds.length === 0) {
    return 1
  } else {
    return Math.max(...arrIds) + 1
  }
}

f.extract0102ReducedPayload = (solicitud0102) => {
  return (
    {
      identificador:            solicitud0102["Resolucion"].identificador,
      "solicitudId":            solicitud0102["solicitudId"],
      "fechas":                 solicitud0102["fechas"],
      "Solicitante":            solicitud0102["Solicitante"],
      "Propuesta":              solicitud0102["Propuesta"],
      "RecursosExSitu":         solicitud0102["RecursosExSitu"],
      "RecursosInSitu":         solicitud0102["RecursosInSitu"],
      "AccesoConocimiento":     solicitud0102["AccesoConocimiento"],
      "Personal":               solicitud0102["Personal"],
      "InformeTecnicoMaate":    solicitud0102["InformeTecnicoMaate"],
      "InformeTecnicoSenescyt": solicitud0102["InformeTecnicoSenescyt"],
      "Declaracion":            solicitud0102["Declaracion"],
      "Resolucion":             {
        identificador: solicitud0102["Resolucion"].identificador,
        fecha:         solicitud0102["Resolucion"].fecha,
      }
    }
  )
}
