import React, {useEffect, useMemo, useState} from 'react'
import {
  Box,
  CssBaseline,
  Grid,
  Stack
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {accordeonBox} from 'src/styles/styles'
import API from 'src/features/P01Solicitud/API'
import {useDispatch, useSelector} from 'react-redux'
import {handleSaveSolicitud, handleClear, handCompletarTareaAndSalir} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {rulesFor} from 'src/features/P01Solicitud/CONF'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {MyTypography} from "../../components/MyTypography";

const P01PermisoInvestigacion = ({id}) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '')
  }
  const recoleccionDeRecursos = useSelector(state => state.app.recoleccionDeRecursos)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const usuarioSenescyt = useSelector(state => state.app.usuarioSenescyt)
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const sections = {
    'in-situ': API.sections.filter(it => it.code !== 'RecursosExSitu'),
    'ex-situ': API.sections.filter(it => it.code !== 'RecursosInSitu'),
  }[recoleccionDeRecursos] ?? API.sections
  const [counter, setCounter] = useState(-1)
  const [mainFormValues, setMainFormValues] = useState({...payload})
  const [formErrors, setFormErrors] = useState(API.formErrors())
  const incrementCounter = () => setCounter(counter + 1)
  const title = {
    "Contrato": 'Solicitud de Contrato con Potencial Uso Comercial',
    "Permiso" : 'Solicitud de Permiso de Investigación',
  }[instanciaTarea.tipoInstanciaProceso]

  // const minPlazo = 12
  // const maxPlazo = (instanciaProceso?.proceso?.id === 55000016)?60:36
  useEffect(() => {
    const validators = rulesFor('Solicitante')?.validators()
    if(f.isValid(mainFormValues.Solicitante)) {
      const newErrors = {
        celular:   validators['celular'](mainFormValues.Solicitante?.celular),
        telefono:  validators['telefono'](mainFormValues.Solicitante?.telefono),
        email:     validators['email'](mainFormValues.Solicitante?.email),
        direccion: validators['direccion'](mainFormValues.Solicitante?.direccion),
      }
      setFormErrors(prev => ({...prev, Solicitante:{...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues?.Solicitante])

  useEffect(() => {
    const validators = rulesFor('Propuesta').validators()
    if(f.isValid(mainFormValues.Propuesta)) {
      const newErrors = {
        "nombre":                validators['nombre'](mainFormValues.Propuesta?.nombre),
        "recoleccionDeRecursos": validators["recoleccionDeRecursos"](mainFormValues.Propuesta?.recoleccionDeRecursos),
        "patrocinador":          validators["patrocinador"](mainFormValues.Propuesta?.patrocinador),
        "nombreRepLegalPatrocidador": validators["nombreRepLegalPatrocidador"](mainFormValues.Propuesta?.nombreRepLegalPatrocidador),
        "cargoRepLegalPatrocidador": validators["cargoRepLegalPatrocidador"](mainFormValues.Propuesta?.cargoRepLegalPatrocidador),
        "repLegalPatrocidador":  validators["repLegalPatrocidador"](mainFormValues.Propuesta?.repLegalPatrocidador),
        "montFinancimiento":     validators["montFinancimiento"](mainFormValues.Propuesta?.montFinancimiento),
        "apoyo":                 validators["apoyo"](mainFormValues.Propuesta?.apoyo),
        "nombreRepLegalApoyo":  validators["nombreRepLegalApoyo"](mainFormValues.Solicitante?.Propuesta),
        "cargoRepLegalApoyo":   validators["cargoRepLegalApoyo"](mainFormValues.Propuesta?.cargoRepLegalApoyo),
        "cartaApoyo":           validators["cartaApoyo"](mainFormValues.Propuesta?.cartaApoyo),
        "convenioApoyo":        validators["convenioApoyo"](mainFormValues.Propuesta?.convenioApoyo),
        "repLegalApoyo":        validators["repLegalApoyo"](mainFormValues.Propuesta?.repLegalApoyo),
        "resumen":              validators["resumen"](mainFormValues.Propuesta?.resumen),
        "objetivo":             validators["objetivo"](mainFormValues.Propuesta?.objetivo),
        "objetivos":            validators["objetivos"](mainFormValues.Propuesta?.objetivos),
        "plazo":                validators["plazo"](mainFormValues.Propuesta?.plazo),
        "areaInvestigacion":    validators["areaInvestigacion"](mainFormValues.Propuesta?.areaInvestigacion),
        "lineaInvestigacion":   validators["lineaInvestigacion"](mainFormValues.Propuesta?.lineaInvestigacion),
        "definicionProblema":   validators["definicionProblema"](mainFormValues.Propuesta?.definicionProblema),
        "justificacion":        validators["justificacion"](mainFormValues.Propuesta?.justificacion),
        "metodologia":          (['in-situ','in-ex-situ'].includes(mainFormValues?.Propuesta?.recoleccionDeRecursos))?validators["metodologia"](mainFormValues.Propuesta?.metodologia):null,
        "metodologiaLaboratorio": validators["metodologiaLaboratorio"](mainFormValues.Propuesta?.metodologiaLaboratorio),
        "metodologiaDoc":       validators["metodologiaDoc"](mainFormValues.Propuesta?.metodologiaDoc),
        "resultadosEsperados":  validators["resultadosEsperados"](mainFormValues.Propuesta?.resultadosEsperados),
      }
      setFormErrors(prev => ({...prev, Propuesta:{...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.Propuesta])

  useEffect(() => {
    if(f.isValid(mainFormValues.AccesoConocimiento)) {
      const newErrors = {
        clpi: (mainFormValues.AccesoConocimiento.accesoConocimiento === true && mainFormValues.AccesoConocimiento.clpi === '')?'CLPI no puede quedar vacío':'',
        contratoAccesoConocimiento: (mainFormValues.AccesoConocimiento.accesoConocimiento === true && mainFormValues.AccesoConocimiento.contratoAccesoConocimiento === '')?'Contrato de conocimiento no puede quedar vacío':'',
      }
      setFormErrors(prev => ({...prev, AccesoConocimiento:{...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.AccesoConocimiento])

  useEffect(() => {
    const validators = rulesFor('RecursosInSitu').validators()
    if(f.isValid(mainFormValues.RecursosInSitu)) {
      const newErrors = {
        "recursos": validators["recursos"](mainFormValues.RecursosInSitu?.recursos),
        "muestras": API.evalMuestras(mainFormValues.RecursosInSitu?.recursos, mainFormValues.RecursosInSitu?.muestras),
        "provincias": validators["provincias"](mainFormValues.RecursosInSitu?.provincias),
        "laboratorios":validators["laboratorios"](mainFormValues.RecursosInSitu?.laboratorios),
      }
      setFormErrors(prev =>({...prev, RecursosInSitu: {...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.RecursosInSitu])

  useEffect(() => {
    const validators = rulesFor('RecursosExSitu').validators()
    if(f.isValid(mainFormValues.RecursosExSitu)) {
      const newErrors = {
        ...API.evalCentrosDocumentacion(mainFormValues.RecursosExSitu.centrosDocumentacion),
        "laboratorios":validators["laboratorios"](mainFormValues.RecursosExSitu?.laboratorios),
      }
      setFormErrors(prev =>({...prev, RecursosExSitu: {...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.RecursosExSitu])

  useEffect(() => {
    if(f.isValid(mainFormValues.AccesoConocimiento)) {
      const newErrors = {
          clpi: (mainFormValues.AccesoConocimiento.accesoConocimiento === true && mainFormValues.AccesoConocimiento.clpi === '')?'"CLPI" no puede quedar vacío':'',
          contratoAccesoConocimiento: (mainFormValues.AccesoConocimiento.accesoConocimiento === true && mainFormValues.AccesoConocimiento.contratoAccesoConocimiento === '')?'"Contrato de conocimiento" no puede quedar vacío':'',
      }
      setFormErrors(prev =>({...prev, AccesoConocimiento: {...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.AccesoConocimiento])

  useEffect(() => {
    if(f.isValid(mainFormValues.Personal)) {
      const newErrors = {
        "personal": Object.values(API.evalPersonal(mainFormValues.Personal.personal,mainFormValues.Personal.experiencia)),
        "experiencia": Object.values(API.evalExperiencias(mainFormValues.Personal.experiencia)),
      }
      setFormErrors(prev =>({...prev, Personal: {...newErrors}}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFormValues.Personal])

  // countErrorsBySection.- count errors by section. countErrors.- total errors
  const sectionSErrors = useMemo(() => {
    return  (formErrors!==null)?Object.keys(formErrors):[]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(formErrors)])
  const [countErrorsBySection, setCountErrorsBySection]= useState({})
  const [countErrors, setCountErrors]= useState(0)
  useEffect(() => {
    let errs = 0
    sectionSErrors.forEach(sctnErrs => {
      let errors = []
      const subSctns = Object.keys(formErrors[sctnErrs])
      subSctns.forEach(it2 => {
        if(f.isValidNotEmpty(formErrors[sctnErrs][it2])) {
          if(sctnErrs === 'Personal' && ["personal","experiencia"].includes(it2))
            errors = [...errors, ...formErrors[sctnErrs][it2]]
          else
            errors = [...errors, formErrors[sctnErrs][it2]]
        }
      })
      setCountErrorsBySection(prev => ({...prev,[sctnErrs]:errors.length}))
      errs += errors.length
      countErrorsBySection[sctnErrs]=errors.length
    })

    setCountErrors(errs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  if(!f.isValid(formErrors) || !f.isValid(instanciaProceso.id ) || !f.isValid(instanciaTarea.id) || !f.isValid(usuarioSenescyt)) {
    return <MyTypography>... c a r g a n d o    f o r m u l a r i o ...</MyTypography>
  }
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={title} />
      </AppBar>
      {
        sections.map((section, idx) => (
          <Accordion key={`panel${idx+1}`}
                     disableGutters
                     elevation={0}
                     expanded={expanded === `panel${idx+1}`}
                     onChange={handleChange(`panel${idx+1}`)}
                     sx={{backgroundColor:'#F4FFF4'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${idx + 1}bh-content`}
                              id={`panel${idx + 1}bh-header`}
                              sx={accordeonBox.titleBox2}>
              <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'98%'}} spacing={2}>
                <Typography sx={{...accordeonBox.titleTypography2, p:0, m:0}} align="left">
                  {`${section.sectionTitle} `}
                </Typography>
                {(f.isValid(countErrorsBySection[section.code]) && countErrorsBySection[section.code] > 0)?
                  <MyTypography sx={{...accordeonBox.titleTypography2, fontWeight:'bold', fontSize:'0.8rem', p:0, m:0, color:'darkred'}} align="left">
                    {`${countErrorsBySection[section.code]} ${countErrorsBySection[section.code] === 1?"error":"errores"}`}
                  </MyTypography>:null
                }
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{pl:'52px'}}>{section.f(usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter,id,formErrors,setFormErrors)}</AccordionDetails>
          </Accordion>
        ))
      }
      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          { (bandeja === 'entrada')?
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyButtonBacan label={'Regresar'}
                                 icon={ArrowBackIcon}
                                 onClick={() => {
                                   if(counter <= 2) {
                                     dispatch(handleClear())
                                   } else
                                     alert('Debe GUARDAR los cambios realizados')
                                 }} />
                  <MyButtonBacan label={'Guardar'}
                                 onClick={() => {
                                   const newPayload= {...payload ,...mainFormValues}
                                   dispatch(handleSaveSolicitud(
                                     instanciaProceso?.id,
                                     newPayload,
                                     () => setCounter(0))
                                   )
                                 }}
                                 disabled={counter <= 0}
                                 icon={SaveOutlinedIcon} />
                  <MySendButton disabled={counter > 0 || countErrors > 0 || mainFormValues.Declaracion?.si === false}
                                label={'Enviar'}
                                onSend={ () => {
                                  const metadata = JSON.stringify(
                                    {
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                      "nombreProyecto": mainFormValues.Propuesta?.nombre,
                                    }
                                  )
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }} />
                </Stack>
              </Grid>
            </Grid>
            :
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <MyButtonBacan label={'Regresar'}
                             icon={ArrowBackIcon}
                             onClick={() => dispatch(handleClear())} />
            </Grid>
          }
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

export default P01PermisoInvestigacion
