import Solicitante from 'src/features/P01Solicitud/subcomponents/P01Solicitud01Solicitante'
import {SolicitanteRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01SolicitanteRO'
import {PropuestaRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01PropuestaRO'
import {RecursosInSituRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01RecursosInSituRO'
import {RecursosExSituRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01RecursosExSituRO'
import Propuesta from 'src/features/P01Solicitud/subcomponents/P01Solicitud02Propuesta'
import RecursosInSitu from 'src/features/P01Solicitud/subcomponents/P01Solicitud03RecursosINSITU'
import RecursosExSitu from 'src/features/P01Solicitud/subcomponents/P01Solicitud03RecursosEXSITU'
import GLOBALS from 'src/features/App/globals'
import {f} from "../../commons";
import {add, format, parse} from "date-fns";
import {es} from "date-fns/locale";
import {rulesFor} from "./CONF";
import AccesoConocimiento from 'src/features/P01Solicitud/subcomponents/P01Solicitud03AccesoConocimiento'
import {AccesoConocimientoRO} from 'src/features/P01Solicitud/subcomponents/P01Solicitud03AccesoConocimientoRO'
import Personal from "./subcomponents/P01Solicitud04Personal";
import Declaracion from "./subcomponents/P01Solicitud06Declaracion";
import {PersonalRO} from "./subcomponents/P0102Solicitud01PersonalRO";
import {DeclaracionRO} from "./subcomponents/P0102Solicitud01DeclaracionRO";

const API = {
  sliceName: 'p1',
  processTitle: 'Solicitud de Permiso de Investigación',
  sections: [
    {code:'Solicitante', sectionTitle: 'Solicitante', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter, idTask, formErrors, setFormErrors) => (<Solicitante usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />), jsonSection:'Solicitante',},
    {code:'Propuesta', sectionTitle: 'Contexto y Alcance de la Investigación', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter, idTask, formErrors, setFormErrors) => <Propuesta usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Propuesta',},
    {code:'RecursosInSitu', sectionTitle: 'Recursos de la biodiversidad a ser investigados IN SITU', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter, idTask, formErrors, setFormErrors) => <RecursosInSitu usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Recursos',},
    {code:'RecursosExSitu', sectionTitle: 'Recursos de la biodiversidad a ser investigados EX SITU', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter, idTask, formErrors, setFormErrors) => <RecursosExSitu usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Recursos',},
    {code:'AccesoConocimiento', sectionTitle: 'Acceso a conocimiento tradicional', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter,idTask,formErrors,setFormErrors) => <AccesoConocimiento usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Recursos',},
    {code:'Personal', sectionTitle: 'Personal Científico Técnico', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter,idTask,formErrors,setFormErrors) => <Personal usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Personal',},
    {code:'Declaracion', sectionTitle: 'Declaración de veracidad de la información', f: (usuarioSenescyt,payload,mainFormValues,setMainFormValues,incrementCounter,idTask,formErrors,setFormErrors) => <Declaracion usuarioSenescyt={usuarioSenescyt} payload={payload} mainFormValues={mainFormValues} setMainFormValues={setMainFormValues} incrementCounter={incrementCounter} idTask={idTask} formErrors={formErrors} setFormErrors={setFormErrors} />, jsonSection:'Documentos',},
  ],
  sectionsRO: [
    {code:'Solicitante', sectionTitle: 'Solicitante', f: (payload) => <SolicitanteRO payload={payload}/>, jsonSection:'Solicitante',},
    {code:'Propuesta', sectionTitle: 'Contexto y Alcance de la Investigación', f: (payload) => <PropuestaRO payload={payload} />, jsonSection:'Propuesta',},
    {code:'RecursosInSitu', sectionTitle: 'Recursos de la biodiversidad a ser investigados IN SITU', f: (payload) => <RecursosInSituRO payload={payload} />, jsonSection:'RecursosInSitu',},
    {code:'RecursosExSitu', sectionTitle: 'Recursos de la biodiversidad a ser investigados EX SITU', f: (payload) => <RecursosExSituRO payload={payload} />, jsonSection:'RecursosExSitu',},
    {code:'AccesoConocimiento', sectionTitle: 'Acceso a conocimiento tradicional', f: (payload) => <AccesoConocimientoRO payload={payload} />, jsonSection:'AccesoConocimiento', },
    {code:'Personal', sectionTitle: 'Personal Científico Técnico', f: (payload) => <PersonalRO payload={payload} />, jsonSection:'Personal',},
    {code:'Declaracion', sectionTitle: 'Declaración de veracidad de la información', f: (payload) => <DeclaracionRO payload={payload} />, jsonSection:'Documentos',},
  ],
  searchEspecie: async(value, taxonRank, ranks) => {
    const field = ranks[taxonRank].field
    const option = ranks[taxonRank].option
    const body = {
      terminoBusqueda: `${value}`,
      taxonRankBusqueda: `${taxonRank}`,
      [field]: `${option}`
    }
    if(taxonRank === 'Kingdom') {
      body.kingdom = ranks['Kingdom'].option
    }

    const url    = `${GLOBALS.mainUrl}/v1/api/${ranks[taxonRank].method}`
    const res    = await fetch(url,{
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body:JSON.stringify(body)
    })
    return res.json()
  }
}

API.evalCentrosDocumentacion = (cds) => {
  let i = Math.round(Math.random()*1000)
  let errors = {}
  if(cds === null || cds.length === 0) {
    errors['centrosDocumentacion'] = 'Debe crear al menos un Centro de documentación'
  } else {
    cds.forEach(cd => {
      i++;
      errors = {
        ...errors,
        ...(cd?.centroDocumentacion === '') ? {[`err02${i}`]: 'Centro de documentacion no puede quedar vacío'} : {},
        ...(cd?.acronimo === '') ? {[`err02${i}`]: 'Acrónimo no puede quedar vacío'} : {},
        ...(cd?.acronimo?.trim()?.split(' ')?.length > 1) ? {[`err03${i}`]: 'Acrónimo debe ser una sola palabra'} : {},
        ...(cd?.adjunto === '') ? {[`err04${i}`]: 'Adjunto es un documento obligatorio'} : {},
        ...(cd?.curadorAdministrador === '') ? {[`err05${i}`]: 'Curador administrador no puede quedar vacío'} : {},
        ...(cd?.telefono === '') ? {[`err06${i}`]: 'Teléfono no puede quedar vacío'} : {},
        ...(cd?.email === '') ? {[`err07${i}`]: 'Mail no puede quedar vacío'} : {},
        ...(cd?.recursos?.length === 0)? {[`err08${i}`]: 'No se han definido especies'} : {},
        ...(cd?.recursos?.length > 0 && cd.recursos.filter(it => it.cantidadSolicitada <= 0).length > 0) ? {
          [`err09${i}`]: 'No se permite [Cantidades solicitadas] con valor 0 o negativo'
        } : {}
      }
    })
  }

  return errors
}

const filterByTaxonId = (it, muestras) => muestras.filter(sample => sample.taxonid === it.id);

const filterByTaxonIdCondition = (it, condition, muestras) => muestras.filter(sample => sample.taxonid === it.id && condition(sample));

API.evalMuestras = (recursos, muestras) => {
  let errors = []
  if (recursos.length > 0) {
    if (muestras.length === 0) {
      errors.push('Tabla de muestras no puede quedar vacía')
    } else {
      recursos.forEach(it => {
        let subErrors = [];
        let m = filterByTaxonId(it, muestras);
        if (m.length === 0) {
          subErrors.push('no tiene lista de muestras')
        } else {
          if (filterByTaxonIdCondition(it, sample => !sample.tipo, muestras).length > 0) {
            subErrors.push('tiene "Tipo de recolección" sin definir');
          }
          if (filterByTaxonIdCondition(it, sample => !sample.submuestra, muestras).length > 0) {
            subErrors.push('tiene "Submuestra" sin definir');
          }
          if (filterByTaxonIdCondition(it, sample => !sample.descripcion, muestras).length > 0) {
            subErrors.push('tiene "Descripción/detalle" sin definir');
          }
          if (filterByTaxonIdCondition(it, sample => sample.cantidadSolicitada <= 0, muestras).length > 0) {
            subErrors.push('"Cantidad solicitada" debe ser mayor a 0');
          }
        }
        if (subErrors.length > 0) {
          errors.push(`Recurso "${it.scientificname}" ${subErrors.join(', ')}`)
        }
      })
    }
  }
  return errors.join('. ');
}

API.evalPersonal = (tp, te=null) => {
  const errors = {}
  if(tp.length === 0) {
    errors['Personal'] = 'Personal, Tabla de personal no puede quedar vacía'
  } else {
    tp.forEach((it,idx) => {
      let itErr = ((it.cedula === '')?'La "Cédula o pasaporte" no pueden quedar vacía. ':'') +
        ((it.celular === '')?'la columna "Celular" no pueden quedar vacía. ':'') +
        ((it.nombre === '')?'la columna "Nombres y apellidos" no pueden quedar vacía. ':'') +
        ((it.correo === '')?'La columna "Correo" no pueden quedar vacía. ':'') +
        ((it.pais === '')?'La columna "País" no pueden quedar vacía. ':'') +
        ((it.gradoAcademigo === '')?'La columna "Grado académico" no pueden quedar vacía. ':'') +
        ((it.cargoProyecto === '')?'La columna "Cargo Proyecto" no pueden quedar vacía. ':'') +
        ((it.pasaporte === '' && !['','ECUADOR'].includes(it.pais))?'La columna "Pasaporte" no pueden quedar vacía. ':'') +
        ((it.institucion === '')?'La columna "Institución a la que pertenece" no pueden quedar vacía. ':'')
      const acts = te.filter(it2 => it2.cedula === it.cedula).length
      if(acts === 0) {
        itErr += 'No registra experiencia. '
      }
      if(itErr !== '') {
        errors[`cedula${idx}`] = `Técnico/a con cédula "${it.cedula}": ${itErr}.`
      }
    })
  }

  return errors
}

API.evalExperiencias = (te) => {
  const errors = {}
  if(te.length === 0) {
    errors['Experiencia'] = 'Tabla de experiencias no puede quedar vacía'
  } else {
    te.forEach((it,idx) => {
      let itErr = ''
      itErr = ((it.proyecto === '')?'La columna "Proyecto" no pueden quedar vacía. ':'') +
        ((it.funcion === '')?'La columna "funcion" no pueden quedar vacía. ':'') +
        ((it.inicio === '' || it.inicio === null)?'La columna "Inicio" no pueden quedar vacía. ':'') +
        ((it.fin === '' || it.fin === null)?'La columna "Fin" no pueden quedar vacía. ':'') +
        ((it.experiencia === '')?'La columna "Experiencia relacionada..." no pueden quedar vacía. ':'') +
        ((it.inicio !== '' && it.fin !== '' && it.inicio > it.fin)?'La fecha "Inicio" debe ser antes que "Fin".':'')
      if(itErr !== '') {
        errors[`exp${idx}`] = `El/la técnico/a con cédula "${it.cedula}" tienen los siguientes errores: ${itErr}`
      }
    })
  }

  return errors
}

API.mapping = (vars) => {
  const { solicitudId,payload,elaborar, autoridad,revisar,aprobar,tipo,section } = vars
  /*
    autoridad: autoridad,
    elaborar:  perfilUsuario,
    revisar:   revisar,
    aprobar:   aprobar,
  */
  console.log(':::. ',elaborar, autoridad,revisar,aprobar)
  const tipoDetalle = (!f.isValid(tipo))?'Permiso de Investigación Científica':(tipo==='permiso')?'Permiso de Investigación Científica':'Contrato de Acceso con Potencial Uso Comercial'
  const autoridadSenescyt = vars.autoridadSenescyt
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')
  let RecursosInSitu = {...payload.RecursosInSitu}
  RecursosInSitu.muestras = payload?.InformeTecnicoMaate?.muestras
  let RecursosExSitu = {...payload.RecursosExSitu}
  RecursosExSitu.centrosDocumentacion = payload?.InformeTecnicoMaate?.centrosDocumentacion
  const dFecha = format(new Date(), 'd MMMM yyyy', {locale: es})
  const dResolucion = f.isValid(payload?.Resolucion?.fecha)?
    parse(payload?.Resolucion?.fecha,'d-MMMM-yyyy', new Date(), {locale: es}):
    new Date()
  const plazo = Number(payload.Propuesta?.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  return {
    '<<sistema.identificadorInformeTecnicoMaate>>': payload?.InformeTecnicoMaate?.serial ?? '',
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<plazoProyectoAnio>>': Math.trunc(payload?.Propuesta?.plazo/12) + ' años ' + (payload?.Propuesta?.plazo%12) + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>': solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>': solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>': solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>': solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>': solicitudId,
    '<<solicitante.nombre>>': payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>': `"${payload?.Propuesta?.nombre}"`,
    '<<solicitud.plazoProyecto>>': payload?.Propuesta?.plazo,
    '<<patrocinador>>': payload?.Propuesta?.patrocinador,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>': aprobar?.nombreUsuario ?? '*',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>': aprobar?.nombrePerfil ?? '*',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>': aprobar?.descripcionPerfil ?? '*',
    '<<sistema.telefonoAutorizadorPrincipalSenescyt>>': aprobar?.telefonoUsuario ?? '*',
    '<<sistema.coreoElectronicoAutorizadorPrincipalSenescyt>>': aprobar?.correoUsuario ?? '*',
    '<<sistema.nombreAutorizadorSectorialMaate>>': aprobar?.nombreUsuario ?? '*',
    '<<sistema.rolAutorizadorSectorialMaate>>': aprobar?.nombrePerfil ?? '*',
    '<<usuario.cargoAutorizadorSectorialMaate>>': aprobar?.descripcionPerfil ?? '*',
    '<<sistema.nombreCoordinadorCasosSenescyt>>': aprobar?.nombreUsuario ?? '*',
    '<<sistema.rolCoordinadorCasosSenescyt>>': aprobar?.nombrePerfil ?? '*',
    '<<sistema.cargoCoordinadorCasosSenescyt>>': aprobar?.descripcionPerfil  ?? '*',
    '<<sistema.telefono>>': revisar?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':revisar?.correoUsuario ?? '*',
    '<<usuario.nombre>>':elaborar?.usuario?.nombreUsuario ?? '*',
    '<<usuario.rol>>':elaborar?.perfil?.nombrePerfil ?? '*',
    '<<usuario.cargo>>':elaborar?.perfil?.descripcionPerfil ?? '*',
    '<<usuario.telefono>>':elaborar?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':elaborar?.usuario?.correoUsuario ?? '*',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '*' ,
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '*',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '*',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '*',
    '<<solicitud.objetivoProyecto>>':payload?.Propuesta?.objetivo,
    '<<solicitante.cedula>>':payload?.Solicitante?.cedula,
    '<<objetivos>>':payload?.Propuesta?.objetivos?.join('{:}'),
    '<<solicitud.metodologiaCampo>>': payload?.Propuesta?.metodologia,
    '<<solicitud.metodologiaLaboratorio>>': payload?.Propuesta?.metodologiaLaboratorio,
    '<<recursos.tablaRecursosInSitu>>': JSON.stringify(RecursosInSitu),
    '<<recursos.tablaRecursosExSitu>>': JSON.stringify(RecursosExSitu),
    '<<solicitud.resultadosEsperados>>': payload?.Propuesta?.resultadosEsperados?.join('{:}'),
    '<<solicitud.tablaEquipoTecnico>>': JSON.stringify(payload?.Personal?.personal),
    '<<solicitud.accesoConocimientoTradicional>>': JSON.stringify(payload?.AccesoConocimiento),
    '<<solicitud.listaReporteResultados>>': payload.fechas,
    '<<solicitud.institucionPatrocinadora>>': payload?.Propuesta?.patrocinador,
    '<<solicitud.representanteLegalPatrocinadora>>': payload?.Propuesta?.nombreRepLegalPatrocidador,
    '<<solicitud.cargoRepresentanteLegalPatrocinadora>>': payload?.Propuesta?.cargoRepLegalPatrocidador,
    '<<solicitud.fecha>>': payload?.Resolucion?.fecha,
    '<<solicitud.institucionNacionalApoyo>>': payload?.Propuesta?.apoyo,
    '<<solicitud.legitimosPoseedores>>': (f.isValid(payload?.AccesoConocimiento?.legitimosPoseedores) && (payload?.AccesoConocimiento?.legitimosPoseedores !== ''))?payload?.AccesoConocimiento?.legitimosPoseedores:'',
    '<<autorizacion.fechaHasta>>':format(dPlazo, 'dd MMMM yyyy',{locale: es}),
    '<<positivo>>':(payload?.InformeTecnicoMaate?.seAutoriza)?'+':'-',
    '<<negativo>>':(!payload?.InformeTecnicoMaate?.seAutoriza)?'+':'-',
    '<<baremo>>':(f.isValid(payload.InformeTecnicoMaate)?JSON.stringify(payload.InformeTecnicoMaate):'{}'),
    '<<informeTecnicoMaate.observaciones>>':(f.isValid(payload.InformeTecnicoMaate?.observaciones))?payload.InformeTecnicoMaate?.observaciones?.join('{:}'):'',
    '<<solicitud.tipoDePermiso>>': tipoDetalle.toUpperCase(),
    '<<serial>>':(f.isValid(section) && section.serial)?section.serial:'serial: ????',
    '<<elaboranPermiso>>': JSON.stringify(
      {
        elabora: {nombre:elaborar?.usuario?.nombreUsuario ?? '', cargo:elaborar?.perfil?.nombrePerfil},
        revisa:{nombre:revisar?.nombreUsuario ?? '', cargo:revisar?.nombrePerfil ?? ''},
        autoriza:{nombre:aprobar?.nombreUsuario ?? '', cargo:aprobar?.nombrePerfil ?? ''},
        fecha:format(new Date(), 'd MMMM yyyy', {locale: es})}),
    '<<fecha>>': dFecha
  }
}

const getRulesForKeys = (keys) => {
  return keys.reduce((obj, key) => {
    obj[key] = rulesFor(key)?.emptyPayload();
    return obj;
  }, {});
};

const fv = getRulesForKeys([
  'Solicitante',
  'Propuesta',
  'RecursosInSitu',
  'RecursosExSitu',
  'AccesoConocimiento',
  'Personal',
  'Declaracion'
]);

API.formErrors = () => {
  const defaultErrorValue = '';
  const personalKeys = ['personal', 'experiencia'];
  return Object.keys(fv).reduce((errors, key) => {
    const subErrors = Object.keys(fv[key]).map(subKey => {
      const isPersonalKey = key === 'Personal' && personalKeys.includes(subKey);
      return { [subKey]: isPersonalKey ? [] : defaultErrorValue };
    });
    return { ...errors, [key]: Object.assign({}, ...subErrors ) };
  }, {});
}

export default API
