import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import GLOBALS, {ENV} from "../App/globals";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {PageOrientation} from "docx";
import {WF03Expediente} from "./subcomponents/P03Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03RevisarFactivilidad = ({id, instanciaTarea, instanciaProceso, solicitud, perfilUsuario}) => {
  const inputRef = useRef()
  const section = 'WF03-003-Revisarfactivilidad'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    resolucionDeInicio : '',
    criterioTecnicoInabio: false,
    informeCompletoInabio: false,
    ampliacionInformacionInabio: false,
    serial:        '',
    identificador: solicitud.numeroSolicitud,
    docxGenerado:  false,
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }

  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `factibilidad-${nombreOrganizacion}`
  const filename = `factibilidad-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload.INFO.docs = {
        ...payload.INFO.docs,
        'd03Factivilidad': [
          {
            'idx':payload.INFO?.docs?Object.keys(payload.INFO.docs).length:0,
            'archivo':(formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
            'solicitudId': payload.INFO.solicitudId,
            'desc':'Revisión de factibilidad',
            'subFolder': subfolder
          }
        ]
      }
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Revisar factibilidad'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud"  value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Acto de simple administración'} />
            </Grid>
            <Grid item xs={4} >
              <Box sx={{width:'50%'}} >
                <MyDatePicker id={'fecha'}
                              canEdit={true}
                              label={'Fecha'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
              </Box>
            </Grid>
            {/*<Grid item xs={8}>*/}
            {/*  <MySwitch id={'criterioTecnicoInabio'}*/}
            {/*            label={'¿Criterio Técnico Inabio?'}*/}
            {/*            formValues={formValues}*/}
            {/*            setFormValues={setFormValues}*/}
            {/*            fullWidth={false}*/}
            {/*            canEdit={canEdit}*/}
            {/*            handleChange={handleChange} />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={4} ></Grid>*/}
            <Grid item xs={8}>
              <MySwitch id={'informeCompletoInabio'}
                        label={'¿Informe completo Inabio?'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        fullWidth={false}
                        canEdit={canEdit}
                        handleChange={handleChange} />
            </Grid>
            {/*<Grid item xs={4} ></Grid>*/}
            {/*<Grid item xs={8}>*/}
            {/*  <MySwitch id={'ampliacionInformacionInabio'}*/}
            {/*            label={'¿Ampliación Información Inabio?'}*/}
            {/*            formValues={formValues}*/}
            {/*            setFormValues={setFormValues}*/}
            {/*            fullWidth={false}*/}
            {/*            canEdit={canEdit}*/}
            {/*            handleChange={handleChange} />*/}
            {/*</Grid>*/}

            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                {
                  (formValues.serial === '') ?
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      const tipo = 'ITINAB'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave(setCounter, {...formValues, serial})()
                                          setFormValues({...formValues, serial})
                                          setCounter(0)
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup> : false
                }
                {
                  (formValues.docx !== '') ?
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx:     '',
                                        pdf:      '',
                                        docxLded: '',
                                        pdfLded:  '',

                                        // firma -- \\
                                        firmaTareaId: 0,
                                        pdfSigned:    '',
                                        firmando:     false,
                                        firmado:      false,
                                        // firma -- //
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup> : null
                }
                {
                  (formValues.docx === '' && formValues.serial !== '') ?
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Informe'}
                                    width={'11rem'}
                                    onClick={() => {
                                      // API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                        const doc = MyTagged2Docx(
                                          plantilla(formValues),
                                          nombreOrganizacion,
                                          P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            section:payload[section]
                                          }),
                                          PageOrientation.PORTRAIT,
                                          instanciaProceso?.solicitud?.nombreProyecto)
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      // })
                                    }}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup> : false
                }
                {
                  (formValues.docx !== '') ?
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => {
                                      API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })
                                    }}
                                    toolTip={'Descargar informe técnico en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descargar informe técnico en formato pdf'}
                                    width={'5rem'} />
                  </ButtonGroup> : false
                }
                {
                  (formValues.docx !== '') ?
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir docx actualizado del informe técnico '}
                                        width={'5rem'} />
                  </ButtonGroup> : false
                }
                {
                  (formValues.docx !== '') ?
                  <>
                    <MyFileUploadButton label={'PDF (FIRMADO)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado en formato pdf'}
                                        width={'9rem'}
                                        disabled={formValues.firmando || formValues.firmado}/>
                    <MyButtonBacan3 label={'FIRMAR'}
                                    icon={FileDownloadIcon}
                                    onClick={()=> {
                                      API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                        if(res?.length > 0) {
                                          const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                          const firmaTareaId = res[0].firmaTareaId
                                          setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                        }
                                      })
                                    }}
                                    disabled={formValues.firmado}
                                    toolTip={'firmar electronicamente el documento'}
                                    width={'9rem'} />
                    {
                      (formValues.pdfSigned !== '') ?
                        <MyButtonBacan3 label={''}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadPDF({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: filenamePDFsigned,
                                        })}
                                        toolTip={'Descarga informe técnico firmado'}
                                        width={'3rem'} /> : false
                    }
                  </> : false
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                getOnSave(setCounter)()
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "criterioTecnicoInabio": false,
                                    "ampliacionInformacionInabio": false,
                                    "informeCompletoInabio": formValues.informeCompletoInabio,
                                  }
                                )
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.pdfSigned === '' || !formValues.firmado}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = (formValues) => {
  const si = (formValues.ampliacionInformacionInabio && formValues.criterioTecnicoInabio && formValues.informeCompletoInabio)
  const no = (!formValues.ampliacionInformacionInabio || !formValues.criterioTecnicoInabio || !formValues.informeCompletoInabio)
  return "${TITULO1}INFORME TÉCNICO" +
    "${SALTO}${CENTRAR}No. <<serial>>" +
    "${SALTO}${CENTRAR}<<fecha>>" +
    "${SALTO}${SALTO}${TITULO2}1. ANTECEDENTES" +
    "${SALTO}${SALTO}${TITULO2}1.1 DATOS GENERALES" +
    "${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>" +
    "${SALTO}${VAR}Solicitante:${:}<<solicitante.nombre>>" +
    "${SALTO}${VAR}Nombre proyecto:${:}<<solicitud.nombreProyecto>>" +
    "${SALTO}${SALTO}${TITULO2}1.2 INFORMACIÓN LEGAL" +
    "${SALTO}${TITULO2}Código Orgánico del Ambiente:" +
    "${SALTO}${PARRAFO}Art. 74. - Limitaciones en el acceso a los recursos genéticos y sus derivados. La Autoridad Ambiental Nacional podrá limitar el acceso a los recursos genéticos, sus componentes y derivados, de manera total o parcial en los siguientes casos:" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}1. {:}Cuando exista endemismo, rareza, amenaza de extinción de las especies, subespecies, variedades o razas" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}2. {:}Cuando existan condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas que pudieran agravarse por actividades de acceso; " +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}3. {:}Cuando el acceso a dichos recursos cause efectos adversos sobre la salud humana o sobre elementos esenciales de la identidad cultural de las comunas, comunidades, pueblos y nacionalidades;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}4. {:}Cuando existan impactos ambientales difícilmente controlables de las actividades de acceso sobre las especies y los ecosistemas;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}5. {:}Cuando exista peligro de erosión genética ocasionado por actividades de acceso;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}6. {:}Cuando existan regulaciones sobre bioseguridad y biotecnología que así lo determinen;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}7. {:}Cuando existan recursos genéticos, sus productos derivados y sintetizados, que haya sido priorizada; y," +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}8. {:}Otras limitaciones a criterio de la Autoridad Ambiental Nacional" +
    "${SALTO}${TITULO2}Reglamento al Código Orgánico del Ambiente."+
    "${SALTO}${PARRAFO}Art. 7. Biodiversidad como recurso estratégico-..La Autoridad Ambiental Nacional ejercerá la rectoría y gestión del sector estratégico de la biodiversidad, desarrollando el modelo de gestión intersectorial conforme las competencias, facultades y atribuciones establecidas en la normativa vigente."+
    "${SALTO}${PARRAFO}Art. 215. Autoridad Ambiental Nacional.- La Autoridad Ambiental Nacional, en materia de recursos genéticos regulará y normará los procedimientos, plazos y requisitos para la conservación y uso sostenible de la biodiversidad y el patrimonio genético del país." +
    "${SALTO}${PARRAFO}Art. 216. Lineamientos para la conservación y uso sostenible de los recursos genéticos. La Autoridad Ambiental Nacional, en el marco de sus competencias, emitirá los lineamientos técnicos para la conservación y uso sostenible de los recursos genéticos y biológicos, mediante norma técnica." +
    "${SALTO}${PARRAFO}Art. 220. Criterios técnicos y protocolos para la conservación de la biodiversidad.- La Autoridad Ambiental Nacional, emitirá los criterios técnicos y protocolos para la conservación y uso sostenible de la biodiversidad, a ser considerados previo al acceso a recursos genéticos mediante norma secundaria; dicho instrumento regulará las condiciones, alcance y formatos a aplicarse, en concordancia con el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación." +
    "${SALTO}${PARRAFO}La autoridad competente para conceder el acceso al recurso genético, solicitará el pronunciamiento de la Autoridad Ambiental Nacional sobre la pertinencia del acceso solicitado. Dicho pronunciamiento deberá considerar al menos los siguientes lineamientos:" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}a. {:}El estado y formas de conservación in situ y ex situ de las especies, subespecies, morphos, variedades o razas respectivamente;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}b. {:}Número de especies (endémicas, amenazadas o en peligro de extinción) y patrones de distribución;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}c. {:}Condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}d. {:}Diversidad genética de las especies y áreas geográficas calificadas como estratégicas – vulnerables;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}e. {:}Reducción/erosión irreversible de la diversidad genética, causada por actividades de acceso;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}f. {:}Acciones que alteren la capacidad de los ecosistemas para proporcionar bienes y servicios ambientales;" +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}g. {:}Análisis de riesgo ambiental para la liberación de organismos genéticamente modificados a medios naturales con fines de investigación o comercio; y," +
    "${SALTO}${ITEM.SIMPLE.NUMERADO}h. {:}En los casos de investigaciones a realizarse en áreas del Sistema Nacional de Áreas Protegidas, se evaluará la pertinencia de la investigación propuesta en función de la zonificación y actividades previstas en el plan de manejo del área protegida" +
    "${SALTO}${PARRAFO}Para la aplicación de lo establecido en el artículo 74 del Código Orgánico del Ambiente, la Autoridad Ambiental Nacional emitirá su pronunciamiento por escrito, evaluando caso por caso, mediante informe técnico de evaluación, que tendrá carácter vinculante." +
    "${SALTO}${PARRAFO}Art. 221. Acceso a recursos genéticos o biológicos con fines de investigación dentro de áreas protegidas.- Cuando el acceso a recursos genéticos en general, o el acceso a recursos biológicos con fines de investigación, implique actividades que deban realizarse dentro de zonas que forman parte del Sistema Nacional de Áreas Protegidas, el responsable de área informará al investigador o a la persona que actúa en representación de quien solicitó el acceso, sobre los procedimientos a seguirse, de forma previa el ingreso al área" +
    "${SALTO}${PARRAFO}Art. 224. Datos técnicos y científicos.- Para la formulación de política pública y toma de decisiones, la Autoridad Ambiental Nacional recopilará y compilará datos técnicos y científicos sobre la biodiversidad y su patrimonio genético. Para el efecto, establecerá lineamientos, estándares y protocolos que permitan la interrelación e intercambio dinámico de datos biológicos, entre los generadores de información biológica" +
    "${SALTO}${SALTO}${TITULO2}2. DESARROLLO" +
    "${SALTO}${TITULO3}2.1. OBJETIVO" +
    "${SALTO}${PARRAFO}Emitir el criterio técnico para la pertinencia del acceso y/o uso de la biodiversidad y/o recursos genéticos para el proyecto/propuesta: [incluir el nombre del proyecto]." +
    "${SALTO}${TITULO3}2.2. ANÁLISIS" +
    "${SALTO}${PARRAFO}Se realizó la revisión técnica a la propuesta de contrato de acceso con fines comerciales, en donde se identifican las siguientes observaciones: Información de la evaluación." +
    "${SALTO}${TITULO3}2.3. RESULTADOS" +
    (si?"${SALTO}${PARRAFO}Caso aprobado: La propuesta no se contrapone a los lineamientos y criterios de evaluación del acceso a la biodiversidad y sus recursos genéticos.":"") +
    (no?"${SALTO}${PARRAFO}Caso observado: <<observaciones>>":"") +
    "${SALTO}${SALTO}${TITULO2}3. CONCLUSIONES Y RECOMENDACIONES" +
    (si?
      ("${SALTO}${PARRAFO}Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto de investigación titulado “<<solicitud.nombreProyecto>>”, es pertinente, viable y factible conforme el criterio de este Ministerio y, que la misma promueve la conservación y uso sostenible de la biodiversidad." +
        "${SALTO}${PARRAFO}Por lo expuesto, se recomienda la CONTINUAR CON EL TRÁIMITE DE SUSCRIPCIÓN DE CONTRATO DE ACCESO CON FINES COMERCIALES del proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, siempre y cuando no estén en contradicción con la normativa legal vigente.")
      : "") +
    (no?
      ("${SALTO}${PARRAFO}Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, no cumple con los criterios de conservación y uso sostenible de la biodiversidad." +
        "${SALTO}${PARRAFO}Por lo expuesto, se recomienda, NEGAR EL TRÁMITE DE SUSCRIPCIÓN DE CONTRATO DE ACCESO CON FINES COMERCIALES solicitada y solventar la información entregada en una nueva solicitud para el desarrollo del proyecto <<solicitud.nombreProyecto>> en cumplimiento de la normativa legal vigente.")
      : "") +
    "${SALTO}${SALTO}${TITULO2}4. ANEXOS" +
    '${SALTO3}' +
    '${SALTO}${CENTRARBOLD}Revisado y aprobado por: ' +
    '${SALTO3}' +
    '${SALTO}${CENTRARBOLD}Elaborado por: '
}



// const plantilla = (formValues) => {
//   const si = (formValues.ampliacionInformacionInabio && formValues.criterioTecnicoInabio && formValues.informeCompletoInabio)
//   const no = (!formValues.ampliacionInformacionInabio || !formValues.criterioTecnicoInabio || !formValues.informeCompletoInabio)
//   console.log(si, no)
//   return "${TITULO1}INFORME TÉCNICO" +
//     "${SALTO}${CENTRAR}No. <<serial>>" +
//     "${SALTO}${CENTRAR}<<fecha>>" +
//     "${SALTO}${SALTO}${TITULO2}1. ANTECEDENTES" +
//     "${SALTO}${SALTO}${TITULO2}1.1 DATOS GENERALES" +
//     "${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>" +
//     "${SALTO}${VAR}Solicitante:${:}<<solicitante.nombre>>" +
//     "${SALTO}${VAR}Nombre proyecto:${:}<<solicitud.nombreProyecto>>" +
//     "${SALTO}${SALTO}${TITULO2}1.2 INFORMACIÓN LEGAL" +
//     "${SALTO}${TITULO2}Código Orgánico del Ambiente:" +
//     "${SALTO}${PARRAFO}Art. 74. - Limitaciones en el acceso a los recursos genéticos y sus derivados. La Autoridad Ambiental Nacional podrá limitar el acceso a los recursos genéticos, sus componentes y derivados, de manera total o parcial en los siguientes casos:" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}1. {:}Cuando exista endemismo, rareza, amenaza de extinción de las especies, subespecies, variedades o razas" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}2. {:}Cuando existan condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas que pudieran agravarse por actividades de acceso; " +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}3. {:}Cuando el acceso a dichos recursos cause efectos adversos sobre la salud humana o sobre elementos esenciales de la identidad cultural de las comunas, comunidades, pueblos y nacionalidades;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}4. {:}Cuando existan impactos ambientales difícilmente controlables de las actividades de acceso sobre las especies y los ecosistemas;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}5. {:}Cuando exista peligro de erosión genética ocasionado por actividades de acceso;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}6. {:}Cuando existan regulaciones sobre bioseguridad y biotecnología que así lo determinen;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}7. {:}Cuando existan recursos genéticos, sus productos derivados y sintetizados, que haya sido priorizada; y," +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}8. {:}Otras limitaciones a criterio de la Autoridad Ambiental Nacional" +
//     "${SALTO}${TITULO2}Reglamento al Código Orgánico del Ambiente."+
//     "${SALTO}${PARRAFO}Art. 7. Biodiversidad como recurso estratégico-..La Autoridad Ambiental Nacional ejercerá la rectoría y gestión del sector estratégico de la biodiversidad, desarrollando el modelo de gestión intersectorial conforme las competencias, facultades y atribuciones establecidas en la normativa vigente."+
//     "${SALTO}${PARRAFO}Art. 215. Autoridad Ambiental Nacional.- La Autoridad Ambiental Nacional, en materia de recursos genéticos regulará y normará los procedimientos, plazos y requisitos para la conservación y uso sostenible de la biodiversidad y el patrimonio genético del país." +
//     "${SALTO}${PARRAFO}Art. 216. Lineamientos para la conservación y uso sostenible de los recursos genéticos. La Autoridad Ambiental Nacional, en el marco de sus competencias, emitirá los lineamientos técnicos para la conservación y uso sostenible de los recursos genéticos y biológicos, mediante norma técnica." +
//     "${SALTO}${PARRAFO}Art. 220. Criterios técnicos y protocolos para la conservación de la biodiversidad.- La Autoridad Ambiental Nacional, emitirá los criterios técnicos y protocolos para la conservación y uso sostenible de la biodiversidad, a ser considerados previo al acceso a recursos genéticos mediante norma secundaria; dicho instrumento regulará las condiciones, alcance y formatos a aplicarse, en concordancia con el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación." +
//     "${SALTO}${PARRAFO}La autoridad competente para conceder el acceso al recurso genético, solicitará el pronunciamiento de la Autoridad Ambiental Nacional sobre la pertinencia del acceso solicitado. Dicho pronunciamiento deberá considerar al menos los siguientes lineamientos:" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}a. {:}El estado y formas de conservación in situ y ex situ de las especies, subespecies, morphos, variedades o razas respectivamente;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}b. {:}Número de especies (endémicas, amenazadas o en peligro de extinción) y patrones de distribución;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}c. {:}Condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}d. {:}Diversidad genética de las especies y áreas geográficas calificadas como estratégicas – vulnerables;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}e. {:}Reducción/erosión irreversible de la diversidad genética, causada por actividades de acceso;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}f. {:}Acciones que alteren la capacidad de los ecosistemas para proporcionar bienes y servicios ambientales;" +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}g. {:}Análisis de riesgo ambiental para la liberación de organismos genéticamente modificados a medios naturales con fines de investigación o comercio; y," +
//     "${SALTO}${ITEM.SIMPLE.NUMERADO}h. {:}En los casos de investigaciones a realizarse en áreas del Sistema Nacional de Áreas Protegidas, se evaluará la pertinencia de la investigación propuesta en función de la zonificación y actividades previstas en el plan de manejo del área protegida" +
//     "${SALTO}${PARRAFO}Para la aplicación de lo establecido en el artículo 74 del Código Orgánico del Ambiente, la Autoridad Ambiental Nacional emitirá su pronunciamiento por escrito, evaluando caso por caso, mediante informe técnico de evaluación, que tendrá carácter vinculante." +
//     "${SALTO}${PARRAFO}Art. 221. Acceso a recursos genéticos o biológicos con fines de investigación dentro de áreas protegidas.- Cuando el acceso a recursos genéticos en general, o el acceso a recursos biológicos con fines de investigación, implique actividades que deban realizarse dentro de zonas que forman parte del Sistema Nacional de Áreas Protegidas, el responsable de área informará al investigador o a la persona que actúa en representación de quien solicitó el acceso, sobre los procedimientos a seguirse, de forma previa el ingreso al área" +
//     "${SALTO}${PARRAFO}Art. 224. Datos técnicos y científicos.- Para la formulación de política pública y toma de decisiones, la Autoridad Ambiental Nacional recopilará y compilará datos técnicos y científicos sobre la biodiversidad y su patrimonio genético. Para el efecto, establecerá lineamientos, estándares y protocolos que permitan la interrelación e intercambio dinámico de datos biológicos, entre los generadores de información biológica" +
//     "${SALTO}${SALTO}${TITULO2}2. DESARROLLO" +
//     "${SALTO}${TITULO3}2.1. OBJETIVO" +
//     "${SALTO}${PARRAFO}Emitir el criterio técnico para la pertinencia del acceso y/o uso de la biodiversidad y/o recursos genéticos para el proyecto/propuesta: [incluir el nombre del proyecto]." +
//     "${SALTO}${TITULO3}2.2. ANÁLISIS" +
//     "${SALTO}${PARRAFO}Se realizó la revisión técnica a la propuesta de contrato de acceso con fines comerciales, en donde se identifican las siguientes observaciones: Información de la evaluación." +
//     "${SALTO}${TITULO3}2.3. RESULTADOS" +
//     (si?"${SALTO}${PARRAFO}Caso aprobado: La propuesta no se contrapone a los lineamientos y criterios de evaluación del acceso a la biodiversidad y sus recursos genéticos.":"") +
//     (no?"${SALTO}${PARRAFO}Caso observado: <<observaciones>>":"") +
//     "${SALTO}${SALTO}${TITULO2}3. CONCLUSIONES Y RECOMENDACIONES" +
//     (si?
//       ("${SALTO}${PARRAFO}Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto de investigación titulado “<<solicitud.nombreProyecto>>”, es pertinente, viable y factible conforme el criterio de este Ministerio y, que la misma promueve la conservación y uso sostenible de la biodiversidad." +
//         "${SALTO}${PARRAFO}Por lo expuesto, se recomienda la CONTINUAR CON EL TRÁIMITE DE SUSCRIPCIÓN DE CONTRATO DE ACCESO CON FINES COMERCIALES del proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, siempre y cuando no estén en contradicción con la normativa legal vigente.")
//       : "") +
//     (no?
//       ("${SALTO}${PARRAFO}Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, no cumple con los criterios de conservación y uso sostenible de la biodiversidad." +
//         "${SALTO}${PARRAFO}Por lo expuesto, se recomienda, NEGAR EL TRÁMITE DE SUSCRIPCIÓN DE CONTRATO DE ACCESO CON FINES COMERCIALES solicitada y solventar la información entregada en una nueva solicitud para el desarrollo del proyecto <<solicitud.nombreProyecto>> en cumplimiento de la normativa legal vigente.")
//       : "") +
//     "${SALTO}${SALTO}${TITULO2}4. ANEXOS" +
//     '${SALTO3}' +
//     '${SALTO}${CENTRARBOLD}Revisado y aprobado por: ' +
//     // '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>' +
//     // '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>' +
//     // '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>' // +
//     '${SALTO3}' +
//     '${SALTO}${CENTRARBOLD}Elaborado por: ' // +
//     // '${SALTO}${CENTRAR}<<usuario.nombre>>' +
//     // '${SALTO}${CENTRAR}<<usuario.rol>>' +
//     // '${SALTO}${CENTRAR}<<usuario.cargo>>'
// }
